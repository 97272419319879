import { Usuario } from './usuario';
import { DocumentoCobro } from './documentoCobro';

export class PlanillaLiquidacion {
    constructor(
        public id: number,
        public observaciones: string,
        public fecha: string,
        public totalPrimaNeta: number,
        public totalComision: number,
        public diferenciaAceptable: number,
        public diferenciaPorcentajeAceptable: number,
        public liquidada: boolean,
        public UsuarioId: number,
        public EmpresaId: number,
    ){}

    fechaFormato: string;
    fechaDate: Date;
    fechaHasta: string;
    fechaHastaDate: Date;
    formaPagoNombre: string;
    nombreAseguradora: string;
    nombreMoneda: string;
    usuario: Usuario;
    meta: any = {};
    documentos: DocumentoCobro[];
    idsAseguradoras: number[];
    idsDocumentos: number[];
    registrosAuxiliar: any[];
    comisionesCalculadas: any[];
}