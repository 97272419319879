import { Component, Inject, OnInit,  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconSearch from '@iconify/icons-fa-solid/search';
import { PolizasService } from '../polizas.service';
import { SolicitudPoliza } from 'src/app/models/solicitudPoliza';
import { EnvioSolicitud } from 'src/app/models/envioSolicitud';
import { Observable } from 'rxjs';

export interface DialogData {
    registro: SolicitudPoliza,
    contactos: any[]
}

@Component({
    selector: 'vex-dialog-enviar-solicitud',
    templateUrl: './dialog-enviar-solicitud.component.html',
    styleUrls: ['./dialog-enviar-solicitud.component.scss']
})
export class DialogEnviarSolicitudComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconSearch = iconSearch;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconEdit = iconEdit;
    archivoCargado:boolean = false;
    cargando: boolean = false;
    titulo:string = 'Enviar solicitud';
    envio: EnvioSolicitud;
    destinatariosArray: any[] = [];
    filteredOptions: Observable<any[]>[] = [];

    constructor(
        public dialogRef: MatDialogRef<DialogEnviarSolicitudComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private service: PolizasService,
        private libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.envio = new EnvioSolicitud(null, null, this.data.registro.asunto, this.data.registro.de, null, null, this.data.registro.id, this.auth.idUsuarioActual(), this.auth.idEmpresaActual());
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    async enviarSolicitud() {
        this.cargando = true;

        // Enviar el registro para guardar
        this.envio.destinatarios = this.destinatariosArray.map(x => x.correo).join(',');
        let res = await this.service.enviarSolicitudPoliza(this.envio);

        if (!res.error) {
            this.libraryService.crearNotificacionGrande(res.data.mensaje, 'success');
            this.auth.buscadorReload.next();
            this.cerrar();
            this.cargando = false;
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            this.cargando = false;
        }

        this.cargando = false;
    }

    eliminarDestinatario(index) {
        this.destinatariosArray.splice(index, 1);
        this.filteredOptions.splice(index, 1);
    }

    agregarDestinatario(correo: string) {
        if(!this.destinatariosArray) this.destinatariosArray = [];
        const destinatario = { correo: null };
        this.destinatariosArray.push(destinatario);
        this.filteredOptions.push(new Observable((observer) => {
            observer.next(this.filterContactos(''));
            destinatario['observer'] = observer;
        }));
    }

    validForm() {
        if(!this.envio) return false;

        if(!this.destinatariosArray || this.destinatariosArray.length == 0) return false;
        for (let el of this.destinatariosArray) {
            let valid = this.libraryService.validateEmail(el.correo);
            if(!valid) return false;
        }

        return true;
    }

    onInputChanged(value: string, index: number) {
        this.filteredOptions[index] = new Observable((observer) => {
            observer.next(this.filterContactos(value));
            this.destinatariosArray[index]['observer'] = observer;
        });
    }

    private filterContactos(value: string): any[] {
        const filterValue = value ? value.toLowerCase() : '';
        return this.data.contactos.filter(option =>
            (option.correo1 && option.correo1.toLowerCase().includes(filterValue)) ||
            (option.nombre && option.nombre.toLowerCase().includes(filterValue))
        );
    }
}
