import { ContactoAseguradora } from './contactoAseguradora';
import { Ramo } from './ramo';

export class Aseguradora {
    constructor(
        public id: number,
        public nombre: string,
        public codigo: string,
        public razonSocial: string,
        public direccion: string,
        public telefono: string,
        public correo: string,
        public sitioWeb: string,
        public telefonoHoraHabil: string,
        public telefonoHoraInhabil: string,
        public codigoAgente: string,
        public edadLimiteDependientesAsegurados: string,
        public diaCorteReporteAltasBajasColectivosPersonas: number,
        public diaCorteEfectivoAltasBajasColectivosPersonas: number,
        public valorComisionCobro: number,
        public porcentajeComisionCobro: number,
        public baseValorComisionCobro: number,
        public basePorcentajeComisionCobro: number,
        public limiteComisionCobro: number,
        public imagen: string,
        public portada: string,
        public redHospitalariaLink: string,
        public activo: boolean,
        public EmpresaId: number,
    ){}
    
    contactos: ContactoAseguradora[];
    idsRamos: number[]
    ramos: Ramo[];
}