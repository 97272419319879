import { Injectable } from '@angular/core';
import { APIUrls } from '../api/apiUrls';
import { ConnectionService } from '../api/connection.service';
import { LibraryService } from '../library/library.service';
import { JSONConverters } from '../models/JSONConverters';
import { Ramo } from '../models/ramo';
import { Cobertura } from '../models/cobertura';
import { Inciso } from '../models/inciso';
import { Deducible } from '../models/deducible';
import { Producto } from '../models/producto';
import { TipoCertificado } from '../models/tipoCertificado';
import { RazonRamo } from '../models/razonRamo';
import { TipoSiniestro } from '../models/tipoSiniestro';
import * as Papa from 'papaparse';
import { AreaRamo } from '../models/areaRamo';
import { ImpuestoEmpresa } from '../models/impuestoEmpresa';
import { GastoProducto } from '../models/gastoProducto';
import { AuthService } from '../auth/auth.service';
import { PlantillaRamo } from '../models/plantillaRamo';
import { TipoReclamo } from '../models/tipoReclamo';
import { ComisionAseguradoraProducto } from '../models/comisionAseguradoraProducto';
import * as FileSaver from 'file-saver';

@Injectable()
export class RamosService { 
    apiUrls: APIUrls = new  APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();
    constructor(
        private connection: ConnectionService,
        private libraryService: LibraryService,
        private authService: AuthService,
    ) {
        //Papa Promise
        Papa.parsePromise = function(file) {
            return new Promise(function(complete, error) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete,
                    error
                });
            });
        };
    }

    async obtenerRamoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.ramoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearRamo(registro: Ramo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarRamo(registro: Ramo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarRamo(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL + '/id/' + RamoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Ramo eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Desactivar
    public async desactivarRamo(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL + '/desactivar';
            const res = await this.connection.postRequest(url, { RamoId: RamoId });
            return { error: null, data: { mensaje: 'Ramo desactivado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Activar
    public async activarRamo(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL + '/activar';
            const res = await this.connection.postRequest(url, { RamoId: RamoId });
            return { error: null, data: { mensaje: 'Ramo activado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Obtener datos * * * * *
    async obtenerTodasAreasRamos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.areasRamosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.areaRamoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las áreas.');
        }
    }

    async obtenerTodosRamos() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.ramoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los ramos.');
        }
    }

    async obtenerTodosRazonesRamo(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesRamosURL + '/ramo/' + RamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.razonRamoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de reclamo.');
        }
    }

    async obtenerTodosRazones() {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesRamosURL + '/';
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.razonRamoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de reclamo.');
        }
    }

    async obtenerTodosTiposSiniestros(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposSiniestrosURL + '/ramo/' + RamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.tipoSiniestroDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de reclamo.');
        }
    }

    // * * * * * Archivos * * * * *
    async guardarArchivoEnServidorRamos(archivo: File){
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL + '/uploadArchivo';
            const res = await this.connection.uploadFile(url, archivo, true);
            if(!res.error) {
                return { error: null, data: { url: res.url } };
            }
            else throw new Error();
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al cargar el archivo.');
        }
    }

    // * * * * * Coberturas * * * * *
    async obtenerCoberturaPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.coberturasURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.coberturaDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearCobertura(registro: Cobertura) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.coberturasURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarCobertura(registro: Cobertura) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.coberturasURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarCobertura(CoberturaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.coberturasURL + '/id/' + CoberturaId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Cobertura eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Inicisos * * * * *
    async obtenerIncisoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.incisosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.incisoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearInciso(registro: Inciso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.incisosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarInciso(registro: Inciso) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.incisosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarInciso(IncisoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.incisosURL + '/id/' + IncisoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Inciso eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTodasCoberturasRamo(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.coberturasURL + '/ramo/' + RamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.coberturaDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las coberturas.');
        }
    }

    async obtenerTodosIncisosRamo(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.incisosURL + '/ramo/' + RamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.incisoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las coberturas.');
        }
    }

    async obtenerTodosProductosRamo(RamoId: number, AseguradoraId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/ramo/' + RamoId;
            url += '?&AseguradoraId=' + AseguradoraId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.productoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las coberturas.');
        }
    }

    async obtenerTodosProductos(EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/empresa/' + EmpresaId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                registros.push(this.jsonConverters.productoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las coberturas.');
        }
    }

    // * * * * * Razones * * * * *
    async obtenerRazonRamoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesRamosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.razonRamoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearRazonRamo(registro: RazonRamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesRamosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarRazonRamo(registro: RazonRamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesRamosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarRazonRamo(RazonRamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.razonesRamosURL + '/id/' + RazonRamoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Razón de reclamo eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Tipos de siniestro * * * * *
    async obtenerTipoSiniestroPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposSiniestrosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.tipoSiniestroDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearTipoSiniestro(registro: TipoSiniestro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposSiniestrosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarTipoSiniestro(registro: TipoSiniestro) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposSiniestrosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarTipoSiniestro(TipoSiniestroId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposSiniestrosURL + '/id/' + TipoSiniestroId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Tipo de siniestro eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Deducibles * * * * *
    async obtenerDeduciblePorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.deduciblesURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.deducibleDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearDeducible(registro: Deducible) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.deduciblesURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarDeducible(registro: Deducible) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.deduciblesURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarDeducible(DeducibleId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.deduciblesURL + '/id/' + DeducibleId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Deducible eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Productos * * * * *
    async obtenerProductoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.productoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerIncisosCompletosProducto(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/incisos/' + id;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.length; i++) {
                const element = json[i];
                var inciso = this.jsonConverters.incisoDeJSON(element.Inciso);
                registros.push(inciso);
            }

            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearProducto(registro: Producto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarProducto(registro: Producto) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarProducto(ProductoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/id/' + ProductoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Producto eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // * * * * * Plantillas * * * * *
    async obtenerPlantillaRamoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasRamosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.plantillaRamoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async duplicarPlantillaRamo(PlantillaRamoId) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasRamosURL + '/duplicar';
            var data = { PlantillaRamoId: PlantillaRamoId };
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Registro duplicado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Crear
    public async crearPlantillaRamo(registro: PlantillaRamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasRamosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarPlantillaRamo(registro: PlantillaRamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasRamosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarPlantillaRamo(PlantillaRamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasRamosURL + '/id/' + PlantillaRamoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Plantilla de ramo eliminada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTodasPlantillasDeRamo(RamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasRamosURL + '/ramo/' + RamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.registros.length; i++) {
                const element = json.registros[i];
                registros.push(this.jsonConverters.plantillaRamoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los deducibles de la plantilla.');
        }
    }

    public async aplicarPlantillaRamoCertificados(idsCertificados: number[], PlantillaRamoId: number, PolizaId: number, fechaAlta: string, fechaBaja: string, fechaReporteAlta: string, fechaReporteBaja: string) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.certificadosURL + '/aplicarPlantillaCertificados';
            var data = {
                idsCertificados: idsCertificados,
                PlantillaRamoId: PlantillaRamoId,
                PolizaId: PolizaId,
                fechaAlta: fechaAlta,
                fechaBaja: fechaBaja,
                fechaReporteAlta: fechaReporteAlta,
                fechaReporteBaja: fechaReporteBaja,
            }
            const res = await this.connection.postRequest(url, data);
            return { error: null, data: { mensaje: 'Plantilla aplicada con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async obtenerTodosDeduciblesPlantillaRamo(PlantillaRamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasRamosURL + '/deducibles/' + PlantillaRamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.registros.length; i++) {
                const element = json.registros[i];
                registros.push(this.jsonConverters.deducibleIncisoPlantillaRamoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener los deducibles de la plantilla.');
        }
    }

    async obtenerTodosCoberturasIncisoPlantillaRamo(PlantillaRamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.plantillasRamosURL + '/coberturas/' + PlantillaRamoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.registros.length; i++) {
                const element = json.registros[i];
                registros.push(this.jsonConverters.coberturaIncisoPlantillaRamoDeJSON(element));
            }
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las coberturas de la plantilla.');
        }
    }

    // * * * * * Tipos de reclamos * * * * *
    async obtenerTipoReclamoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposReclamosURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.tipoReclamoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearTipoReclamo(registro: TipoReclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposReclamosURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarTipoReclamo(registro: TipoReclamo) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposReclamosURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarTipoReclamo(TipoReclamoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposReclamosURL + '/id/' + TipoReclamoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Tipo de reclamo eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTodosTiposCamposReclamo() {
        try {
            var registros = this.apiUrls.tiposCamposCertificados;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las tipos de campos.');
        }
    }

    // * * * * * Tipos de certificado * * * * *
    async obtenerTipoCertificadoPorId(id: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposCertificadoURL + '/id/' + id;
            var json = await this.connection.getRequest(url);
            var registro = this.jsonConverters.tipoCertificadoDeJSON(json);
            return { error: null, data: { registro: registro } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    // Crear
    public async crearTipoCertificado(registro: TipoCertificado) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposCertificadoURL;
            const res = await this.connection.postRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro creado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
    
    // Actualizar
    public async actualizarTipoCertificado(registro: TipoCertificado) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposCertificadoURL;
            const res = await this.connection.putRequest(url, registro);
            return { error: null, data: { mensaje: 'Registro actualizado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // Eliminar
    public async eliminarTipoCertificado(TipoCertificadoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.tiposCertificadoURL + '/id/' + TipoCertificadoId;
            const res = await this.connection.deleteRequest(url);
            return { error: null, data: { mensaje: 'Tipo de certificado eliminado con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, null);
        }
    }

    async obtenerTodosTiposCamposCertificado() {
        try {
            var registros = this.apiUrls.tiposCamposCertificados;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las tipos de campos.');
        }
    }

    async obtenerTodosTiposBeneficiario() {
        try {
            var registros = this.apiUrls.tiposBeneficiarios;
            return { error: null, data: { registros: registros } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las tipos de campos.');
        }
    }

    // * * * * * Importación de ramos * * * * *
    async lecturaImportacionRamos(archivo, areasRamos: AreaRamo[], EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            
            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = ['ramo', 'area', 'id'];
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            
            // Preparar resultados según modelo
            var resultadosFinales = [];
            resultados.forEach(element => {
                var nombre = element['ramo'];
                var codigoAnterior = element['id'];
                if(nombre) nombre = nombre.trim();
                var elementoFinal = new Ramo(
                    null, nombre, null, this.apiUrls.tiposBeneficiarios[0].id, false,
                    false, true, false, false, false, false, codigoAnterior,  null, EmpresaId
                );

                // Area
                if(areasRamos && element['area']) {
                    var indexArea = this.libraryService.indexOf(areasRamos, 'nombre', element['area']);
                    if(indexArea != -1) elementoFinal.AreaRamoId = areasRamos[indexArea].id;
                }

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionRamos(registros: Ramo[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL + '/analisisImportacionRamos';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionRamos(registros: Ramo[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.ramosURL + '/subirImportacionRamos';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    // * * * * * Importación de coberturas * * * * *
    async lecturaImportacionCoberturas(archivo, ramos: Ramo[], EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            
            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = ['id ramo', 'id cobertura', 'codigo', 'cobertura', 'condicion especial'];
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            
            // Preparar resultados según modelo
            var resultadosFinales = [];
            resultados.forEach(element => {
                var nombre = element['cobertura'];
                var codigoAnterior = element['id cobertura'];
                if(nombre) nombre = nombre.trim();
                var condicionEspecial = element['condicion especial'];
                if(condicionEspecial) condicionEspecial = condicionEspecial.trim();
                var elementoFinal = new Cobertura(
                    null, element['codigo'], nombre, codigoAnterior,
                    condicionEspecial == 'S', true, null, EmpresaId
                );

                // Ramo
                if(ramos && element['id ramo']) {
                    var indexRamo = this.libraryService.indexOf(ramos, 'codigoAnterior', element['id ramo']);
                    if(indexRamo != -1) elementoFinal.RamoId = ramos[indexRamo].id;
                }

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionCoberturas(registros: Cobertura[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.coberturasURL + '/analisisImportacionCoberturas';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionCoberturas(registros: Cobertura[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.coberturasURL + '/subirImportacionCoberturas';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    esPersonas(ramo: Ramo) {
        if(ramo && ramo.area) {
            return ramo.area.nombre == 'Personas';
        }
        else return false;
    }

    // * * * * * Importación de productos * * * * *
    async lecturaImportacionProductos(archivo, ramos: Ramo[], impuestos: ImpuestoEmpresa[], EmpresaId: number) {
        try {
            // Obtener resultados del CSV
            var resArchivo = await Papa.parsePromise(archivo);
            if(!resArchivo || !resArchivo.data) throw new Error('Ha ocurrido un error al leer los datos del archivo');
            
            // Sanitizar objetos en nombres de columnas
            var columnasUtilizadas = ['id ramo', 'id producto', 'producto', 'valor emision', 'tipo valor emision', 'valor impuesto', 'tipo valor impuesto', 'valor emision', 'tipo valor emision', 'valor gastos', 'tipo valor gastos'];
            var resNormalizacion = this.libraryService.limpiarColumnasResultadosExcel(resArchivo.data, columnasUtilizadas);
            if(!resNormalizacion) throw new Error('Ha ocurrido un error al preparar los datos del archivo Excel');
            var errores = resNormalizacion.errores;
            var resultados = resNormalizacion.resultados;
            
            // Preparar resultados según modelo
            var resultadosFinales = [];
            resultados.forEach(element => {
                var nombre = element['producto'];
                var codigoAnterior = element['id producto'];
                if(nombre) nombre = nombre.trim();
                var elementoFinal = new Producto(
                    null, nombre, codigoAnterior, null, null, null,
                    null, null, null, null, null, null, false,
                    true, false, null, EmpresaId
                );
                elementoFinal.gastos = [];
                elementoFinal.impuestos = [];

                // Gastos de emisión
                var tipoGasto = element['tipo valor emision'] == 'P' ? 'porcentaje' : 'valor';
                var porcentaje = 0;
                var valor = 0;
                if(tipoGasto == 'porcentaje') porcentaje = parseFloat(element['valor emision']);
                else if(tipoGasto == 'valor') valor = parseFloat(element['valor emision']);
                var gastosEmision = new GastoProducto(null, 'Gastos de emisión', tipoGasto, porcentaje, valor, this.authService.idMonedaPorDefecto(), null);
                elementoFinal.gastos.push(gastosEmision);
                
                // Otros gastos
                var tipoOtroGasto = element['tipo valor gastos'] == 'P' ? 'porcentaje' : 'valor';
                var porcentajeGasto = 0;
                var valorGasto = 0;
                if(tipoOtroGasto == 'porcentaje') porcentajeGasto = parseFloat(element['valor gastos']);
                else if(tipoOtroGasto == 'valor') valorGasto = parseFloat(element['valor gastos']);
                var otrosGastos = new GastoProducto(null, 'Otros gastos', tipoOtroGasto, porcentajeGasto, valorGasto, this.authService.idMonedaPorDefecto(), null);
                elementoFinal.gastos.push(otrosGastos);
                
                // Impuestos
                var tipoImpuesto = element['tipo valor impuesto'] == 'P' ? 'porcentaje' : 'valor';
                var porcentajeImpuesto = 0;
                var valorImpuesto = 0;
                if(tipoImpuesto == 'porcentaje') porcentajeImpuesto = parseFloat(element['valor impuesto']);
                else if(tipoImpuesto == 'valor') valorImpuesto = parseFloat(element['valor impuesto']);
                // Buscar impuesto con ese valor
                for (let i = 0; i < impuestos.length; i++) {
                    var impuesto = impuestos[i];
                    if(impuesto.tipo == tipoImpuesto && impuesto.porcentaje == porcentajeImpuesto && impuesto.montoFijo == valorImpuesto) {
                        elementoFinal.impuestos = [ impuesto ];
                        elementoFinal.idsImpuestos = [ impuesto.id ];
                        break;
                    }
                }

                // Ramo
                if(ramos && element['id ramo']) {
                    var indexRamo = this.libraryService.indexOf(ramos, 'codigoAnterior', element['id ramo']);
                    if(indexRamo != -1) elementoFinal.RamoId = ramos[indexRamo].id;
                }

                resultadosFinales.push(elementoFinal);
            });

            return {
                error: false,
                errores: errores,
                resultados: resultadosFinales,
                mensaje: 'Lectura de archivo realizada con éxito.',
            };
        } catch(error) {
            return { error: true, mensaje: error.message };
        }
    }

    async analizarImportacionProductos(registros: Producto[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/analisisImportacionProductos';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);
            var registrosNuevos = res.registrosNuevos;
            var registrosEditados = res.registrosEditados;
            var errores = res.errores;

            return { error: null, data: {
                mensaje: 'Importación analizada con éxito',
                registrosNuevos: registrosNuevos,
                registrosEditados: registrosEditados,
                errores: errores,
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async subirImportacionProductos(registros: Producto[], EmpresaId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/subirImportacionProductos';
            var data = {
                registros: registros,
                EmpresaId: EmpresaId,
            }
            const res = await this.connection.postRequest(url, data);

            return { error: null, data: {
                mensaje: 'Importación subida con éxito',
                result: res,
            } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    async obtenerComisionesIncisoProducto(IncisoId: number, ProductoId: number) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/comisiones?&ProductoId=' + ProductoId + '&IncisoId=' + IncisoId;
            var json = await this.connection.getRequest(url);

            var registros = [];
            for (let i = 0; i < json.comisiones.length; i++) {
                const element = json.comisiones[i];
                registros.push(this.jsonConverters.comisionAseguradoraProductoDeJSON(element));
            }

            let bitacoras = [];
            for (let i = 0; i < json.bitacoras.length; i++) {
                const element = json.bitacoras[i];
                bitacoras.push(this.jsonConverters.bitacoraComisionIncisoDeJSON(element));
            }

            return { error: null, data: { registros: registros, bitacoras: bitacoras } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, 'Ha ocurrido un error al obtener las razones de reclamo.');
        }
    }

    public async actualizarComisionesIncisoProducto(comisiones: ComisionAseguradoraProducto[], ProductoId, IncisoId) {
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.productosURL + '/comisionesInciso';
            const res = await this.connection.postRequest(url, { comisiones: comisiones, ProductoId: ProductoId, IncisoId: IncisoId });
            return { error: null, data: { mensaje: 'Comisiones actualizadas con éxito', result: res } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }

    public async descargarExcelComisiones() {
        // Obtener string HTML
        try {
            var url = this.apiUrls.baseURL + this.apiUrls.comisionesAseguradorasProductosURL + '/comisionesExcel';
            
            const res = await this.connection.postRequestDownload(url, {});
            
            // Descargar archivo
            var filename = `Comisiones.xlsx`
            var mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([res], { type: mediaType });
            FileSaver.saveAs(blob, filename);

            return { error: null, data: { mensaje: 'Comisiones descargadas con éxito' } };
        } catch (error) {
            return this.connection.obtenerMensajeError(error.status, error.error.message);
        }
    }
}