import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconList from '@iconify/icons-fa-solid/list';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconClear from '@iconify/icons-fa-solid/times';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconTarjeta from '@iconify/icons-fa-solid/credit-card';
import { CobrosService } from '../cobros.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LibraryService } from 'src/app/library/library.service';
import { DialogAplicarPago } from '../dialog-aplicar-pago/dialog-aplicar-pago.component';
import { MatDialog } from '@angular/material';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { DocumentoCobro } from 'src/app/models/documentoCobro';
import { DialogGenerarEstadoCuenta } from '../dialog-generar-estado-cuenta/dialog-generar-estado-cuenta.component';
import { DialogPasarCobrado } from '../dialog-pasar-cobrado/dialog-pasar-cobrado.component';
import { GenericDialogConfirmacionComentarios } from 'src/app/library/generic-dialog-confirmacion-comentarios/generic-dialog-confirmacion-comentarios.component';
import { DialogCargaMasivaCobrosComponent } from '../dialog-carga-masiva-cobros/dialog-carga-masiva-cobros.component';
import { DialogReportePorCobrar } from '../dialog-reporte-por-cobrar/dialog-reporte-por-cobrar.component';
import { Poliza } from 'src/app/models/poliza';
import { Usuario } from 'src/app/models/usuario';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { DialogCargaMasivaLiquidadosComponent } from '../dialog-carga-masiva-liquidados/dialog-carga-masiva-liquidados.component';
import { DialogGenerarReporteComisiones } from '../dialog-generar-reporte-comisiones/dialog-generar-reporte-comisiones.component';
import { DialogReporteComisionesProyectadas } from '../dialog-reporte-comisiones-proyectadas/dialog-reporte-comisiones-proyectadas.component';

@Component({
    selector: 'app-cobros',
    templateUrl: './cobros.component.html',
    styleUrls: ['./cobros.component.scss']
})
export class CobrosComponent implements OnInit {
    // Templates de celdas
    @ViewChild('cellBotones', { static: true }) cellBotones: TemplateRef<any>;
    @ViewChild('cellPlanilla', { static: true }) cellPlanilla: TemplateRef<any>;
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellRojo', { static: true }) cellRojo: TemplateRef<any>;
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;
    @ViewChild('cellImagen', { static: true }) cellImagen: TemplateRef<any>;

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();
    urlAutocompletePolizas = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/search';
    polizasSeleccionadas: Poliza[] = [];
    pagadoresPolizas: Usuario[] = [];
    idsPagadores: number[] = [];

    // Columnas de tabla
    columnas = [];
    columnasCobro = [
        { name: '', sortable: false, checkboxable: true, headerCheckboxable: true, cellTemplate: null },
        { prop: 'pagador.nombreCompleto', name: 'Pagador', sortable: true, cellTemplate: null },
        { prop: 'poliza.numero', name: 'No. póliza', sortable: true, cellTemplate: null },
        { prop: 'poliza.nombreAseguradora', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'fechaEmisionFormato', name: 'Vencimiento', sortable: true, cellTemplate: null },
        { prop: 'numeroRequisito', name: 'No. req.', sortable: true, cellTemplate: null },
        { prop: 'primaNeta', name: 'Prima neta', sortable: true, cellTemplate: null },
        { prop: 'totalEmision', name: 'Gastos de emisión', sortable: true, cellTemplate: null },
        { prop: 'totalGastos', name: 'Otros gastos', sortable: true, cellTemplate: null },
        { prop: 'totalFraccionamiento', name: 'Gastos de fraccionamiento', sortable: true, cellTemplate: null },
        { prop: 'totalImpuestos', name: 'Impuestos', sortable: true, cellTemplate: null },
        { prop: 'primaTotal', name: 'Prima total', sortable: true, cellTemplate: null },
        { prop: 'numeroPagoNombre', name: 'No. pago', sortable: true, cellTemplate: null },
        { prop: 'diasAtraso', name: 'Días de atraso', sortable: true, cellTemplate: null },
        { prop: 'numeroEndoso', name: 'Endoso', sortable: true, cellTemplate: null },
        { prop: 'nombreProducto', name: 'Producto', sortable: true, cellTemplate: null },
        { prop: 'fechaIngresoFormato', name: 'Ingreso', sortable: true, cellTemplate: null },
        { prop: 'nombreAgrupador', name: 'Agrupador', sortable: true, cellTemplate: null },
        { prop: 'nombreCliente', name: 'Asegurado', sortable: true, cellTemplate: null },
    ];
    columnasCobrados = [
        { name: '', sortable: false, checkboxable: true, headerCheckboxable: true, cellTemplate: null },
        { prop: 'pagador.nombreCompleto', name: 'Pagador', sortable: true, cellTemplate: null },
        { prop: 'poliza.numero', name: 'No. póliza', sortable: true, cellTemplate: null },
        { prop: 'poliza.nombreAseguradora', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'fechaEmisionFormato', name: 'Vencimiento', sortable: true, cellTemplate: null },
        { prop: 'numeroRequisito', name: 'No. req.', sortable: true, cellTemplate: null },
        { prop: 'nombreEtapa', name: 'Etapa', sortable: true, cellTemplate: null },
        { prop: 'primaNeta', name: 'Prima neta', sortable: true, cellTemplate: null },
        { prop: 'totalEmision', name: 'Gastos de emisión', sortable: true, cellTemplate: null },
        { prop: 'totalGastos', name: 'Otros gastos', sortable: true, cellTemplate: null },
        { prop: 'totalFraccionamiento', name: 'Gastos de fraccionamiento', sortable: true, cellTemplate: null },
        { prop: 'totalImpuestos', name: 'Impuestos', sortable: true, cellTemplate: null },
        { prop: 'primaTotal', name: 'Prima total', sortable: true, cellTemplate: null },
        { prop: 'fechaPagoFormato', name: 'Pagado', sortable: true, cellTemplate: null },
        { prop: 'numeroPagoNombre', name: 'No. pago', sortable: true, cellTemplate: null },
        // { prop: 'diasAtraso', name: 'Días de atraso', sortable: true, cellTemplate: null },
        { prop: 'numeroEndoso', name: 'Endoso', sortable: true, cellTemplate: null },
        { prop: 'nombreProducto', name: 'Producto', sortable: true, cellTemplate: null },
        { prop: 'fechaIngresoFormato', name: 'Ingreso', sortable: true, cellTemplate: null },
        { prop: 'nombreAgrupador', name: 'Agrupador', sortable: true, cellTemplate: null },
        { prop: 'nombreCliente', name: 'Asegurado', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
        { nombre: 'Número de requerimiento', campo: 'numeroRequisito', tipo: 'texto', categorias: null },
        { nombre: 'Número de cheque', campo: 'numeroCheque', tipo: 'texto', categorias: null },
        { nombre: 'Número de factura', campo: 'numeroFactura', tipo: 'texto', categorias: null },
        { nombre: 'Fecha de ingreso', campo: 'fechaIngreso', tipo: 'rango-fechas', categorias: null },
        { nombre: 'Fecha de requerimiento / vencimiento', campo: 'fechaEmision', tipo: 'rango-fechas', categorias: null },
        { nombre: 'Fecha de pagado', campo: 'fechaPago', tipo: 'rango-fechas', categorias: null },
    ];

    accionesCobro = [
        { id: 'cobrado', nombre: 'Pasar a cobrados' },
        { id: 'anular', nombre: 'Anular ' },
        { id: 'eliminar', nombre: 'Eliminar requerimientos' },
    ]
    accionesCobrado = [
        // { id: 'liquidar', nombre: 'Liquidar requerimientos' },
        //{ id: 'generar-planilla', nombre: 'Generar planilla' },
        { id: 'regresarCobro', nombre: 'Revertir cobrados a por cobrar' },
        // { id: 'anular', nombre: 'Anular ' },
        { id: 'eliminar', nombre: 'Eliminar requerimientos' },
    ]
    accionesLiquidado = [
        { id: 'regresarCobrado', nombre: 'Revertir liquidados a cobrados' },
        { id: 'anular', nombre: 'Anular ' },
        { id: 'eliminar', nombre: 'Eliminar requerimientos' },
    ]
    accionesAnulado = [
        { id: 'regresarCobro', nombre: 'Pasar a por cobrar' },
        // { id: 'regresarCobrado', nombre: 'Pasar a cobrados' },
        { id: 'eliminar', nombre: 'Eliminar requerimientos' },
    ]
    acciones: any[] = [];

    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.documentosCobrosURL + '/search';
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    urlAutocompleteAgrupadores = this.apiURLs.baseURL + this.apiURLs.agrupadoresURL + '/search';
    urlAutocompleteAseguradoras = this.apiURLs.baseURL + this.apiURLs.aseguradorasURL + '/search';

    // Filtros
    filtros = [
        // // No. de póliza
        // { nombre: 'No. póliza', campo: 'numeroPoliza', tipo: 'texto', valor: null, mostrar: true, categorias: null},
        // Cliente
        {
            nombre: 'Pagador', campo: 'pagador', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteClientes, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombreCompleto', fieldSearch: 'general',
            parser: this.jsonConverters.usuarioDeJSON, parserService: this.jsonConverters,
        },
        {
            nombre: 'Asegurado', campo: 'ClienteId', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteClientes, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombreCompleto', fieldSearch: 'general',
            parser: this.jsonConverters.usuarioDeJSON, parserService: this.jsonConverters,
        },
        // Agrupador
        { nombre: 'Agrupador', campo: 'nombreAgrupador', tipo: 'texto', valor: null, mostrar: false, categorias: null},
        /*
        {
            nombre: 'Agrupador', campo: 'agrupador', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAgrupadores, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.agrupadorDeJSON, parserService: this.jsonConverters,
        },
        */
        // Aseguradora
        //{ nombre: 'Aseguradora', campo: 'nombreAseguradora', tipo: 'texto', valor: null, mostrar: false, categorias: null},
        {
            nombre: 'Aseguradora', campo: 'aseguradora', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAseguradoras, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.aseguradoraDeJSON, parserService: this.jsonConverters,
        },
        // Dias de atraso
        {
          nombre: 'Días de atraso', campo: 'fechasEmisionRangos', tipo: 'select-multiple', mostrar: false, categorias: this.apiURLs.rangoFechasCobros
        },
    ];

    crumbs = [
        { nombre: 'Cobros', link: '/cobros/' },
    ];

    titulo: string = 'Cobros';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;
    iconList = iconList;
    iconPlus = iconPlus;
    iconClear = iconClear;
    iconUpload = iconUpload;
    iconDownload = iconDownload;
    iconTarjeta = iconTarjeta;
    etapa: string = this.apiURLs.etapasCobros[0].id;
    cargando: boolean = false;
    idsSeleccionados: number[];
    paramsUsados: string;

    constructor(
        public service: CobrosService,
        public polizasService: PolizasService,
        public auth: AuthService,
        public libraryService: LibraryService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });

        this.auth.updateParams.subscribe(event => {
            this.paramsUsados = event;
        })
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.agregarFiltros();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
            this.etapa = ruta;
            switch(this.etapa) {
                case 'cobro': {
                    this.titulo = 'Requerimientos por cobrar';
                    this.columnas = this.columnasCobro;
                    this.acciones = this.accionesCobro;
                    break;
                }
                case 'cobrado': {
                    this.titulo = 'Requerimientos cobrados';
                    this.columnas = this.columnasCobrados;
                    this.acciones = this.accionesCobrado;
                    break;
                }
                case 'liquidado': {
                    this.titulo = 'Requerimientos liquidados';
                    this.columnas = this.columnasCobrados;
                    this.acciones = this.accionesLiquidado;
                    break;
                }
                case 'anulado': {
                    this.titulo = 'Requerimientos anulados';
                    this.columnas = this.columnasCobrados;
                    this.acciones = this.accionesAnulado;
                    break;
                }
            }
            this.crumbs[0].nombre = this.titulo;
            this.crumbs[0].link = '/cobros/' + this.etapa;
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        if(this.etapa == 'cobro') {
            this.columnas[6].cellTemplate = this.cellMoneda;
            this.columnas[7].cellTemplate = this.cellMoneda;
            this.columnas[8].cellTemplate = this.cellMoneda;
            this.columnas[9].cellTemplate = this.cellMoneda;
            this.columnas[10].cellTemplate = this.cellMoneda;
            this.columnas[11].cellTemplate = this.cellMoneda;
            this.columnas[13].cellTemplate = this.cellRojo;
        }
        else if(this.etapa == 'cobrado' || this.etapa == 'liquidado' || this.etapa == 'anulado') {
            this.columnas[6].cellTemplate = this.cellNormal;
            this.columnas[7].cellTemplate = this.cellMoneda;
            this.columnas[8].cellTemplate = this.cellMoneda;
            this.columnas[9].cellTemplate = this.cellMoneda;
            this.columnas[10].cellTemplate = this.cellMoneda;
            this.columnas[11].cellTemplate = this.cellMoneda;
            this.columnas[12].cellTemplate = this.cellMoneda;
            // this.columnas[15].cellTemplate = this.cellRojo;
        }
    }

    async agregarFiltros() {

    }

    agregarPolizasSeleccionadas($event) {
        this.idsPagadores = [];
        this.polizasSeleccionadas = $event;
        this.obtenerPagadoresPolizas();
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';

        if(this.etapa != 'cobrado') { 
            paramsEspeciales += '&etapa=' + this.etapa;
        } else {
            paramsEspeciales += '&etapas=cobrado,liquidado';
        }

        if(this.auth.esAccesoACliente()) {
            paramsEspeciales += '&accesoACliente=1';
        }

        if(this.etapa == 'cobrado') {
            paramsEspeciales += '&orderBy=fechaPagoFormato';
        }

        if(this.polizasSeleccionadas && this.polizasSeleccionadas.length > 0) {
            paramsEspeciales += '&idsPolizas=' + this.polizasSeleccionadas.map(p => p.id).join(',');
        }

        if(this.idsPagadores && this.idsPagadores.length > 0) {
            paramsEspeciales += '&idsPagadores=' + this.idsPagadores.toString();
        }

        return paramsEspeciales;
    }

    async obtenerPagadoresPolizas() {
        this.cargando = true;
        if(this.polizasSeleccionadas && this.polizasSeleccionadas.length > 0) {
            var res = await this.polizasService.obtenerTodosPagadoresPolizas(this.polizasSeleccionadas.map(p => p.id));
            if(!res.error) {
                this.pagadoresPolizas = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        else {
            this.pagadoresPolizas = [];
            this.idsPagadores = [];
        }
        this.cargando = false;
    }

    // * * * Acciones * * *
    aplicarAccion($event) {
        if($event && !this.cargando) {
            var accion = $event.accion;
            var seleccionados = $event.seleccionados;

            // Validación de misma aseguradora
            let AseguradoraId = null;
            for (const el of seleccionados) {
                if(el.poliza && AseguradoraId != el.poliza.AseguradoraId && AseguradoraId != null) {
                    this.libraryService.crearNotificacion('Sólo pueden aplicarse acciones a documentos de la misma aseguradora.', 'warn');
                    return;
                }
                AseguradoraId = el.poliza.AseguradoraId;
            }

            var ids = $event.ids;
            switch(accion.id) {
                case 'generar-planilla': {
                    this.abrirGenerarPlanilla(ids);
                    break;
                }
                case 'anular': {
                    this.abrirEnviarAAnulados(ids);
                    break;
                }
                case 'eliminar': {
                    this.abrirEnviarAEliminados(ids);
                    break;
                }
                case 'liquidar': {
                    this.abrirEnviarALiquidados(ids);
                    break;
                }
                case 'regresarCobro': {
                    this.abrirRegresarACobro(ids);
                    break;
                }
                case 'regresarCobrado': {
                    this.abrirRegresarACobrado(ids);
                    break;
                }
                case 'cobrado': {
                    this.abrirEnviarACobrados(ids, seleccionados);
                    break;
                }
            }
        }
    }

    // * Planilla *
    abrirGenerarPlanilla(ids: number[]) {
        this.idsSeleccionados = ids;
        if(ids.length > 0) {
            const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
                data: {
                    titulo: 'Generar planilla',
                    mensaje: '¿Está seguro de que desea crear una planilla con estos requerimientos?',
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if(result == 'confirmar') {
                    this.generarPlanillaRuta(ids);
                }
            });
        }
    }

    generarPlanillaRuta(ids: number[]) {
        this.router.navigate(['/cobros/nuevo-planilla-cobro'], { queryParams: { idsDocumentos: ids.toString() } })
    }

    abrirEnviarACobrados(ids: number[], documentos: DocumentoCobro[]) {
        this.idsSeleccionados = ids;
        const dialogRef = this.dialog.open(DialogPasarCobrado, {
            data: {
                ids: ids,
                documentos: documentos,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.estado == 'confirmar') {
                this.enviarACobrados(result.fechaPago, result.banco, result.numeroCheque, result.formaCobro, result.multiplesFormasPago, result.usaFormaPagoMultiple, result.PlanillaCobroId);
            }
        });
    }

    async enviarACobrados(fechaPago: string, banco: string, numeroCheque: string, formaCobro: string, multiplesFormasPago: string, usaFormaPagoMultiple: boolean, PlanillaCobroId: number) {
        this.cargando = true;
        if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.pasarACobrados(this.idsSeleccionados, this.auth.idUsuarioActual(), fechaPago, banco, numeroCheque, formaCobro, multiplesFormasPago, usaFormaPagoMultiple, PlanillaCobroId, null);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    // * Liquidado *
    abrirEnviarALiquidados(ids: number[]) {
        this.idsSeleccionados = ids;
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como liquidados',
                mensaje: '¿Está seguro de que desea liquidar estos requerimientos?',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.enviarALiquidados();
            }
        });
    }

    async enviarALiquidados() {
        this.cargando = true;
        if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.pasarALiquidados(this.idsSeleccionados, null, null, this.auth.idUsuarioActual());

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    abrirRegresarACobrado(ids: number[]) {
        this.idsSeleccionados = ids;
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como cobrado',
                mensaje: '¿Está seguro de que desea regresar estos requerimientos a cobrados?',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.regresarACobrados();
            }
        });
    }

    async regresarACobrados() {
        this.cargando = true;
        if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.regresarACobrados(this.idsSeleccionados, this.auth.idUsuarioActual());

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    abrirRegresarACobro(ids: number[]) {
        this.idsSeleccionados = ids;
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como por cobrar',
                mensaje: '¿Está seguro de que desea regresar estos requerimientos a por cobrar?',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.regresarACobro();
            }
        });
    }

    async regresarACobro() {
        this.cargando = true;
        if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.regresarACobro(this.idsSeleccionados, this.auth.idUsuarioActual());

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    // * Anular *
    abrirEnviarAAnulados(ids: number[]) {
        this.idsSeleccionados = ids;
        const dialogRef = this.dialog.open(GenericDialogConfirmacionComentarios, {
            data: {
                titulo: 'Marcar como anulados',
                mensaje: '¿Está seguro de que desea anular estos requerimientos?',
                nombreComentarios: 'Razón de anulación',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.enviarAAnulados(result.comentarios);
            }
        });
    }

    async enviarAAnulados(razonAnulacion: string) {
        this.cargando = true;
        if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.anularDocumentosCobros(this.idsSeleccionados, this.auth.idUsuarioActual(), razonAnulacion);
            
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    // * Eliminar *
    abrirEnviarAEliminados(ids: number[]) {
        this.idsSeleccionados = ids;
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar requerimientos',
                mensaje: '¿Está seguro de que desea eliminar estos requerimientos? Esta acción no se puede deshacer.',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.enviarAEliminados();
            }
        });
    }

    async enviarAEliminados() {
        this.cargando = true;
        if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.eliminarDocumentosCobros(this.idsSeleccionados, this.auth.idUsuarioActual());

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    // * * * Estado de cuenta * * *
    abrirGenerarEstadoCuenta() {
        const dialogRef = this.dialog.open(DialogGenerarEstadoCuenta, {
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {}
        });
    }

    abrirGenerarReporteComisiones(etapa: string) {
        const dialogRef = this.dialog.open(DialogGenerarReporteComisiones, {
            data: {
                etapa: etapa,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {}
        });
    }

    abrirGenerarReporteCobros(etapa: string) {
        const dialogRef = this.dialog.open(DialogReportePorCobrar, {
            data: {
                etapa: etapa,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {}
        });
    }

    abrirGenerarReporteComisionesProyectadas() {
        const dialogRef = this.dialog.open(DialogReporteComisionesProyectadas, {
            data: {
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {}
        });
    }

    // * * * Carga masiva pagos * * *
    abrirCargaMasivaPagos() {
        const dialogRef = this.dialog.open(DialogCargaMasivaCobrosComponent, {
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {}
        });
    }

    abrirCargaMasivaLiquidar() {
        const dialogRef = this.dialog.open(DialogCargaMasivaLiquidadosComponent, {
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {}
        });
    }

    async descargarExcelCobros(){
        this.auth.buscadorReload.next();
        setTimeout(async () => {
            this.cargando = true;
            // Enviar el registro para guardar
            var res = await this.service.descargarExcelCobros('Cobros', this.paramsUsados);
    
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        }, 500);
    }

    usaTarjeta(cobro: DocumentoCobro) {
        if((cobro.poliza && cobro.poliza.formaCobro == 'tarjeta-credito') || cobro.formaCobro == 'tarjeta-credito') return true;
        return false;
    }

    getColorEtapaFila(etapa: string) {
        switch(etapa) {
            case 'cobrado': return '#278200';
            case 'liquidado': return '#1531ad';
            default: return 'black';
        }
    }
}
