
export class ConfiguracionEmpresa {
    constructor(
        public id: number,
        public smtp: string,
        public pop: string,
        public puertoSMTP: string,
        public puertoPOP: string,
        public requiereAutenticacion: boolean,
        public diasVencimientoAcceso: number,
        public minimoCaracteresContrasena: number,
        public aplicaVencimientoContrasena: boolean,
        public incisosAplicaGastosEmision: string,
        public incisosNoProrrateo: string,
        public EmpresaId: number,
    ){}

    incisosAplicaGastosEmisionArray: any[] = [];
    incisosNoProrrateoArray: any[] = [];
}