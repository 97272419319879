import { Usuario } from './usuario';
import { Poliza } from './poliza';
import { Empresa } from './empresa';
import { MapaProceso } from './mapaProceso';
import { CertificadoSolicitud } from './certificadoSolicitud';
import { PlantillaSolicitud } from './plantillaSolicitud';
import { Certificado } from './certificado';
import { Reclamo } from './reclamo';

export class SolicitudPoliza {
    constructor(
        public id: number,
        public fecha: string,
        public encabezados: string,
        public contenido: string,
        public de: string,
        public para: string,
        public asunto: string,
        public notasAdicionales: string,
        public nombreArchivo: string,
        public nombreTipo: string,
        public puntuacion: number,
        public estado: string,
        public enviada: boolean,
        public fechaEnvio: string,
        public esconderMontosTotales: boolean,
        public filtrarAclaraciones: boolean,
        public idsAclaraciones: string,

        // Relaciones
        public UsuarioId: number,
        public PolizaId: number,
        public ReclamoId: number,
        public DocumentoCobroId: number,
        public ClienteId: number,
        public PlantillaSolicitudId: number,
        public EmpresaId: number,
        public MapaProcesoId: number,
    ) {}

    idExterno: string;
    numero: number;
    urlVieja: string;
    solicitudServicioId: number;
    solicitudIdAnterior: number;
    usuario: Usuario;
    poliza: Poliza;
    cliente: Usuario;
    reclamo: Reclamo;
    empresa: Empresa;
    plantilla: PlantillaSolicitud;
    mapa: MapaProceso;
    relacionesCertificado: CertificadoSolicitud[];
    certificados: Certificado[];
    idsCertificados: number[];
    idsAclaracionesArray: number[] = [];
    PlantillaMapaProcesoId: number;
    nombreEstado: string;
    fechaEnvioFormato: string;

    // Datos
    nombrePlantilla: string;

    // Fechas
    fechaFormato: string;
    fechaDate: Date;
}
