import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check-circle';
import iconUser from '@iconify/icons-fa-solid/user';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconRight from '@iconify/icons-fa-solid/arrow-right';
import iconObservaciones from '@iconify/icons-fa-solid/edit';
import iconDuplicar from '@iconify/icons-fa-solid/copy';
import iconNoIniciado from '@iconify/icons-fa-solid/pause-circle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { RamosService } from 'src/app/ramos/ramos.service';
import { MapasProcesosService } from '../mapas-procesos.service';
import { MapaProceso } from 'src/app/models/mapaProceso';
import { PasoMapaProceso } from 'src/app/models/pasoMapaProceso';
import { DialogAsignarPasoMapaProceso } from '../dialog-asignar-paso-mapa/dialog-asignar-paso-mapa.component';
import { DialogObservacionesPasoMapaProceso } from '../dialog-observaciones-paso-mapa/dialog-observaciones-paso-mapa.component';
import { GenericDialogSeleccionarOpcion } from 'src/app/library/generic-dialog-seleccionar-opcion/generic-dialog-seleccionar-opcion.component';
import { Aseguradora } from 'src/app/models/aseguradora';
import { AreaRamo } from 'src/app/models/areaRamo';
import { Usuario } from 'src/app/models/usuario';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-reporte-mapas',
    templateUrl: './reporte-mapas.component.html',
    styleUrls: ['./reporte-mapas.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class ReporteMapasComponent implements OnInit {
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconRight = iconRight;
    iconUser = iconUser;
    iconObservaciones = iconObservaciones;
    iconDuplicar = iconDuplicar;
    iconNoIniciado = iconNoIniciado;
    
    // Banderas y generales
    fechaFinDate: Date;
    fechaFin: string;
    fechaInicioDate: Date;
    fechaInicio: string;
    cargando: boolean = false;
    filtrarFecha: boolean = true;
    titulo: string = 'Reporte de solicitudes';
    apiURLs: APIUrls = new APIUrls();

    crumbs = [
        { nombre: 'Reporte de solicitudes', link: '/mapas-procesos/reporte-solicitudes' },
    ];

    estadoSolicitud: string = 'activo'
    numeroEmision: string;
    numero: string;
    AseguradoraId: number;
    AreaRamoId: number;
    EjecutivoId: number;
    aseguradoras: Aseguradora[];
    areasRamos: AreaRamo[];
    ejecutivos: Usuario[];

    constructor(
        public dialog: MatDialog,
        public service: MapasProcesosService,
        public aseguradorasService: AseguradorasService,
        public ramosService: RamosService,
        public usuariosService: UsuariosService,
        public libraryService: LibraryService,
        public auth: AuthService,
        public location: Location,
    ) {}

    ngOnInit(){
        this.fechaFinDate = moment().endOf('month').toDate();
        this.actualizarFechaFin({ value: { _d: this.fechaFinDate } });
        this.fechaInicioDate = moment().startOf('year').toDate();
        this.actualizarFechaInicio({ value: { _d: this.fechaInicioDate } });

        this.obtenerAseguradoras();
        this.obtenerAreasRamos();
        this.obtenerEjecutivos();
    }

    // * * * Descargar * * *
    async descargarExcel() {
        this.cargando = true;

        let params = '';
        if(this.fechaInicio && this.filtrarFecha) params += `&fechaInicio=${this.fechaInicio}`;
        if(this.fechaFin && this.filtrarFecha) params += `&fechaFin=${this.fechaFin}`;
        if(this.AseguradoraId) params += `&AseguradoraId=${this.AseguradoraId}`;
        if(this.numero) params += `&numero=${this.numero}`;
        if(this.numeroEmision) params += `&numeroEmision=${this.numeroEmision}`;
        if(this.estadoSolicitud) params += `&estadoSolicitud=${this.estadoSolicitud}`;
        if(this.AreaRamoId) params += `&AreaRamoId=${this.AreaRamoId}`;
        if(this.EjecutivoId) params += `&EjecutivoId=${this.EjecutivoId}`;

        var res = await this.service.descargarExcelReporteMapas(params);
        
        if(!res.error) {
            //this.confirmar();
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        
        this.cargando = false;
    }

    actualizarFechaFin($event){
        if($event && $event.value) {
            this.fechaFin = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaInicio($event){
        if($event && $event.value) {
            this.fechaInicio = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAreasRamos() {
        this.cargando = true;
        var res = await this.ramosService.obtenerTodasAreasRamos();
        if(!res.error) {
            this.areasRamos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerEjecutivos() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosEjecutivos();
        if(!res.error) {
            this.ejecutivos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
}
