import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibraryModule } from '../library/library.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatOptionModule, MatIconModule, MatSelectModule, MatButtonModule, MatProgressBarModule, MatCheckboxModule, MatDatepickerModule, MatExpansionModule, MatTooltipModule, MatTabsModule, MatCardModule, MatPaginatorModule, MatRadioModule, MatDialogRef, MAT_DIALOG_DATA, MatChipsModule } from '@angular/material';
import { IconModule } from '@visurel/iconify-angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxCurrencyModule } from "ngx-currency";
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { CobrosRoutingModule } from './cobros-routing.module';
import { CobrosComponent } from './cobros/cobros.component';
import { DetalleCobroComponent } from './detalle-cobro/detalle-cobro.component';
import { DialogAplicarPago } from './dialog-aplicar-pago/dialog-aplicar-pago.component';
import { DetallePlanillaComponent } from './detalle-planilla/detalle-planilla.component';
import { PlanillasCobrosComponent } from './planillas/planillas.component';
import { NuevoDocumentoCreditoComponent } from './nuevo-documento-credito/nuevo-documento-credito.component';
import { CreditosComponent } from './creditos/creditos.component';
import { DialogAplicarPagoCredito } from './dialog-aplicar-pago-credito/dialog-aplicar-pago-credito.component';
import { DetalleCreditoComponent } from './detalle-credito/detalle-credito.component';
import { NuevoDocumentoCobroComponent } from './nuevo-documento-cobro/nuevo-documento-cobro.component';
import { DialogNuevoAplicacionCredito } from './dialog-nuevo-aplicacion-credito/dialog-nuevo-aplicacion-credito.component';
import { DialogNuevoAplicacionCreditoCheque } from './dialog-nuevo-aplicacion-credito-cheque/dialog-nuevo-aplicacion-credito-cheque.component';
import { DialogGenerarEstadoCuenta } from './dialog-generar-estado-cuenta/dialog-generar-estado-cuenta.component';
import { CobradoresComponent } from './cobradores/cobradores.component';
import { NuevoCobradorComponent } from './nuevo-cobrador/nuevo-cobrador.component';
import { NuevoPlanillaCobroComponent } from './nuevo-planilla-cobro/nuevo-planilla-cobro.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PageLayoutModule } from '../../@vex/components/page-layout/page-layout.module';
import { ImportacionCobrosComponent } from './importacion-cobros/importacion-cobros.component';
import { DialogPasarCobrado } from './dialog-pasar-cobrado/dialog-pasar-cobrado.component';
import { DialogNuevoSeguimientoCobro } from './dialog-nuevo-seguimiento-cobro/dialog-nuevo-seguimiento-cobro.component';
import { DialogNuevoSeguimientoCobroOld } from './dialog-nuevo-seguimiento/dialog-nuevo-seguimiento.component';
import { AnalyticsComisionesComponent } from './analytics-comisiones/analytics-comisiones.component';
import { ChartModule } from 'angular-highcharts';
import { DialogAsignarAPlanillaComponent } from './dialog-asignar-planilla/dialog-asignar-planilla.component';
import { DialogCargaMasivaCobrosComponent } from './dialog-carga-masiva-cobros/dialog-carga-masiva-cobros.component';
import { ContenedorAnalyticsCobrosComponent } from './analytics-cobros/contenedor-analytics-cobros.component';
import { AnalyticsCobrosComponent } from './analytics-cobros/analytics-cobros.component';
import { PlantillasGestionesCobrosComponent } from './plantillas-gestiones-cobros/plantillas-gestiones-cobros.component';
import { JoditAngularModule } from 'jodit-angular'
import { NuevoPlantillaGestionCobroComponent } from './nuevo-plantilla-gestion-cobro/nuevo-plantilla-gestion-cobro.component';
import { GestionesCobrosComponent } from './gestiones-cobros/gestiones-cobros.component';
import { NuevoGestionCobroComponent } from './nuevo-gestion-cobro/nuevo-gestion-cobro.component';
import { DialogSeleccionarContactosClienteComponent } from './dialog-seleccionar-contactos/dialog-seleccionar-contactos.component';
import { DialogReportePorCobrar } from './dialog-reporte-por-cobrar/dialog-reporte-por-cobrar.component';
import { DialogCargaMasivaLiquidadosComponent } from './dialog-carga-masiva-liquidados/dialog-carga-masiva-liquidados.component';
import { DialogGenerarReporteComisiones } from './dialog-generar-reporte-comisiones/dialog-generar-reporte-comisiones.component';
import { PlanillasLiquidacionComponent } from './planillas-liquidacion/planillas-liquidacion.component';
import { NuevoPlanillaLiquidacionComponent } from './nuevo-planilla-liquidacion/nuevo-planilla-liquidacion.component';
import { BancosComponent } from './bancos/bancos.component';
import { NuevoBancoComponent } from './nuevo-banco/nuevo-banco.component';
import { DialogReporteComisionesProyectadas } from './dialog-reporte-comisiones-proyectadas/dialog-reporte-comisiones-proyectadas.component';
import { AnalyticsComisionesv2Component } from './analytics-comisiones-v2/analytics-comisiones-v2.component';
import { ContenedorAnalyticsComisionesComponent } from './analytics-comisiones-v2/contenedor-analytics-cobros-v2.component';
import { ReporteComisionesProyectadasComponent } from './reporte-comisiones-proyectadas/reporte-comisiones-proyectadas.component';

@NgModule({
    imports: [
        CommonModule,
        CobrosRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        LibraryModule,
        NgxDatatableModule,
        NgxCurrencyModule,
        BreadcrumbsModule,
        SecondaryToolbarModule,
        // Material
        MatInputModule,
        MatOptionModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        MatProgressBarModule,
        MatDatepickerModule,
        IconModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatCardModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatRadioModule,
        MatTabsModule,
        FlexLayoutModule,
        PageLayoutModule,
        ChartModule,
        JoditAngularModule,
        MatChipsModule,
    ],
    declarations: [
        CobrosComponent,
        DetalleCobroComponent,
        DetallePlanillaComponent,
        PlanillasCobrosComponent,
        PlanillasLiquidacionComponent,
        NuevoPlanillaLiquidacionComponent,
        NuevoDocumentoCreditoComponent,
        CreditosComponent,
        DetalleCreditoComponent,
        NuevoDocumentoCobroComponent,
        CobradoresComponent,
        NuevoCobradorComponent,
        NuevoPlanillaCobroComponent,
        ImportacionCobrosComponent,
        AnalyticsComisionesComponent,
        AnalyticsComisionesv2Component,
        DialogNuevoSeguimientoCobro,
        DialogNuevoSeguimientoCobroOld,
        DialogAplicarPago,
        DialogAplicarPagoCredito,
        DialogNuevoAplicacionCredito,
        DialogNuevoAplicacionCreditoCheque,
        DialogGenerarEstadoCuenta,
        DialogGenerarReporteComisiones,
        DialogPasarCobrado,
        DialogAsignarAPlanillaComponent,
        DialogCargaMasivaCobrosComponent,
        DialogCargaMasivaLiquidadosComponent,
        ContenedorAnalyticsCobrosComponent,
        ContenedorAnalyticsComisionesComponent,
        AnalyticsCobrosComponent,
        PlantillasGestionesCobrosComponent,
        NuevoPlantillaGestionCobroComponent,
        GestionesCobrosComponent,
        NuevoGestionCobroComponent,
        DialogSeleccionarContactosClienteComponent,
        DialogReportePorCobrar,
        BancosComponent,
        NuevoBancoComponent,
        DialogReporteComisionesProyectadas,
        ReporteComisionesProyectadasComponent,
    ],
    entryComponents: [
        DialogAplicarPago,
        DialogAplicarPagoCredito,
        DialogNuevoAplicacionCredito,
        DialogNuevoAplicacionCreditoCheque,
        DialogGenerarEstadoCuenta,
        DialogGenerarReporteComisiones,
        DialogNuevoSeguimientoCobro,
        DialogNuevoSeguimientoCobroOld,
        DialogPasarCobrado,
        DialogAsignarAPlanillaComponent,
        DialogCargaMasivaCobrosComponent,
        DialogSeleccionarContactosClienteComponent,
        DialogReportePorCobrar,
        DialogCargaMasivaLiquidadosComponent,
        DialogReporteComisionesProyectadas,
    ],
    exports: [
        DialogGenerarEstadoCuenta,
        DialogGenerarReporteComisiones,
    ],
    providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
    ]
})
export class CobrosModule {
}
