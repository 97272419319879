import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconNext from '@iconify/icons-ic/twotone-double-arrow';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconGenerate from '@iconify/icons-fa-solid/money-bill';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconAdd from '@iconify/icons-fa-solid/plus';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconInfo from '@iconify/icons-fa-solid/info-circle';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import iconAnular from '@iconify/icons-fa-solid/times';
import iconList from '@iconify/icons-fa-solid/list';
import iconUser from '@iconify/icons-fa-solid/user';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { CobrosService } from 'src/app/cobros/cobros.service';
import { DocumentoCobro } from 'src/app/models/documentoCobro';
import { SeguimientoCobro } from 'src/app/models/seguimientoCobro';
import { AplicacionDocumentoCredito } from 'src/app/models/aplicacionDocumentoCredito';
import { DocumentoCredito } from 'src/app/models/documentoCredito';
import { DialogNuevoAplicacionCredito } from '../dialog-nuevo-aplicacion-credito/dialog-nuevo-aplicacion-credito.component';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { Poliza } from 'src/app/models/poliza';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { DialogPasarCobrado } from '../dialog-pasar-cobrado/dialog-pasar-cobrado.component';
import { DialogNuevoSeguimientoCobro } from '../dialog-nuevo-seguimiento-cobro/dialog-nuevo-seguimiento-cobro.component';
import { GenericDialogConfirmacionComentarios } from 'src/app/library/generic-dialog-confirmacion-comentarios/generic-dialog-confirmacion-comentarios.component';
import { DialogGenerarEstadoCuenta } from '../dialog-generar-estado-cuenta/dialog-generar-estado-cuenta.component';
import { DialogAsignarAPlanillaComponent } from '../dialog-asignar-planilla/dialog-asignar-planilla.component';
import { Usuario } from 'src/app/models/usuario';
import { PolizasService } from 'src/app/polizas/polizas.service';
import { ComisionPolizaInciso } from 'src/app/models/comisionPolizaInciso';
import { SolicitudPoliza } from 'src/app/models/solicitudPoliza';
import { DialogNuevaSolicitud } from 'src/app/polizas/dialog-nueva-solicitud/dialog-nueva-solicitud.component';
import { GenericDialogConfirmacionNumeroPorcentaje } from 'src/app/library/generic-dialog-confirmacion-numero-porcentaje/generic-dialog-confirmacion-numero-porcentaje.component';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-detalle-cobro',
    templateUrl: './detalle-cobro.component.html',
    styleUrls: ['./detalle-cobro.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DetalleCobroComponent implements OnInit {
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellNormalCobros', { static: true }) cellNormalCobros: TemplateRef<any>;
    @ViewChild('cellMonto', { static: true }) cellMonto: TemplateRef<any>;
    @ViewChild('cellAcciones', { static: true }) cellAcciones: TemplateRef<any>;
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;
    @ViewChild('cellEstado', { static: true }) cellEstado: TemplateRef<any>;
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    apiURLs: APIUrls = new APIUrls();
    jsonConverters: JSONConverters = new JSONConverters();

    // Documentos de cobros
    urlAutocompleteAseguradoras = this.apiURLs.baseURL + this.apiURLs.aseguradorasURL + '/search';
    urlBusquedaDocumentosCobros = this.apiURLs.baseURL + this.apiURLs.documentosCobrosURL + '/search';
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    columnasDocumentosCobros = [
        { prop: 'pagador.nombreCompleto', name: 'Pagador', sortable: true, cellTemplate: null },
        { prop: 'poliza.numero', name: 'No. póliza', sortable: true, cellTemplate: null },
        { prop: 'poliza.nombreAseguradora', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'fechaEmisionFormato', name: 'Requerimiento', sortable: true, cellTemplate: null },
        { prop: 'fechaPagoFormato', name: 'Pagado', sortable: true, cellTemplate: null },
        { prop: 'numeroRequisito', name: 'No. req.', sortable: true, cellTemplate: null },
        { prop: 'numeroEndoso', name: 'No. endoso', sortable: true, cellTemplate: null },
        { prop: 'meta', name: 'Monto', sortable: true, cellTemplate: null },
        { prop: 'nombreEtapa', name: 'Etapa', sortable: true, cellTemplate: null },
        { prop: 'numeroPagoNombre', name: 'No. pago', sortable: true, cellTemplate: null },
    ];

    camposDocumentosCobros = [
        { nombre: 'Número de requerimiento', campo: 'numeroRequisito', tipo: 'texto', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'Fecha de requerimiento / vencimiento', campo: 'fechaEmision', tipo: 'rango-fechas', categorias: null },
    ];

    filtrosDocumentosCobros = [
        // Pagador
        { nombre: 'Pagador', campo: 'pagador', tipo: 'select-unico', valor: null, mostrar: true, categorias: [], campoNombre: 'nombreCompleto'},
        // Aseguradora
        {
            nombre: 'Aseguradora', campo: 'aseguradora', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAseguradoras, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.aseguradoraDeJSON, parserService: this.jsonConverters,
        },
        // Dias de atraso
        {
            nombre: 'Etapa', campo: 'etapas', tipo: 'select-multiple', mostrar: false, categorias: this.apiURLs.etapasCobros
        },
    ];

    columnasClientes = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'nombreAgrupador', name: 'Agrupador', sortable: true, cellTemplate: null },
        { prop: 'telefono1', name: 'Teléfono', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo electrónico', sortable: true, cellTemplate: null },
    ];
    camposClientes = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];

    crumbs = [
        { nombre: 'Cobros', link: '/cobros' },
        { nombre: 'Requerimiento de cobro', link: '/cobros/detalle/' },
    ];

    // Solicitudes
    urlBusquedaSolicitudes = this.apiURLs.baseURL + this.apiURLs.solicitudesPolizasURL + '/search';
    columnasSolicitudes = [
        { prop: 'id', name: 'No. solicitud', sortable: true, cellTemplate: null },
        { prop: 'nombrePlantilla', name: 'Plantilla', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'de', name: 'De', sortable: true, cellTemplate: null },
        { prop: 'para', name: 'Para', sortable: true, cellTemplate: null },
        { prop: 'usuario.nombreCompleto', name: 'Creado por', sortable: true, cellTemplate: null },
        { prop: 'MapaProcesoId', name: 'Gestión', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    camposSolicitudes = [
        { nombre: 'No. solicitud', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'De', campo: 'de', tipo: 'texto', categorias: null },
        { nombre: 'Para', campo: 'para', tipo: 'texto', categorias: null },
        { nombre: 'Notas adicionales', campo: 'notasAdicionales', tipo: 'texto', categorias: null },
        { nombre: 'Asunto', campo: 'asunto', tipo: 'texto', categorias: null },
    ];

    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconUser = iconUser;
    iconNext = iconNext;
    iconCheck = iconCheck;
    iconEdit = iconEdit;
    iconDownload = iconDownload;
    iconGenerate = iconGenerate;
    iconPlus = iconPlus;
    iconDelete = iconDelete;
    iconInfo = iconInfo;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    iconAnular = iconAnular;
    iconList = iconList;
    iconAdd = iconAdd;
    
    // Banderas y generales
    cargando: boolean = true;
    cargandoSeguimiento: boolean = false;
    cargandoCreditos: boolean = false;
    titulo: string;
    
    // Datos
    id: number;
    registro: DocumentoCobro;
    creditosDisponibles: DocumentoCredito[];
    mostrarCreditos = false;
    innerWidth: any;
    totalOtrosDocumentos: number;
    comisionesPoliza: ComisionPolizaInciso[];

    pagadores: Usuario[] = [];
    pagadoresPoliza: Usuario[] = [];
    idsPagadores: number[] = [];

    totalAplicadosAplicado: number;
    totalDisponiblesPorAplicar: number;

    // Aplicaciones créditos
    columnasAplicaciones = [
        { prop: 'id', name: 'ID', sortable: true, cellTemplate: null },
        { prop: 'fechaAplicacionFormato', name: 'Aplicación', sortable: true, cellTemplate: null },
        { prop: 'tipoNombre', name: 'Tipo', sortable: true, cellTemplate: null },
        { prop: 'banco', name: 'Banco', sortable: true, cellTemplate: null },
        { prop: 'numeroCheque', name: 'No. cheque', sortable: true, cellTemplate: null },
        { prop: 'meta', name: 'Monto', sortable: true, cellTemplate: null },
        { prop: 'DocumentoCreditoId', name: 'ID Nota de crédito', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuario', name: 'Usuario', sortable: true, cellTemplate: null },
        { prop: 'observaciones', name: 'Observaciones', sortable: true, cellTemplate: null },
        { prop: 'meta', name: 'Acciones', sortable: true, cellTemplate: null },
    ];
    urlBusquedaAplicaciones = this.apiURLs.baseURL + this.apiURLs.documentosCreditosURL + '/searchAplicaciones';
    camposAplicaciones = [
        { nombre: 'Fecha de aplicación', campo: 'fechaAplicacion', tipo: 'rango-fechas', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'ID Documento Crédito', campo: 'DocumentoCreditoId', tipo: 'texto', categorias: null },
    ];
    filtrosAplicaciones = [];

    columnasNotasDevolucion = [
        { prop: 'pagador.nombreCompleto', name: 'Pagador', sortable: true, cellTemplate: null },
        { prop: 'poliza.numero', name: 'No. póliza', sortable: true, cellTemplate: null },
        { prop: 'poliza.nombreAseguradora', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'fechaEmisionFormato', name: 'Emisión', sortable: true, cellTemplate: null },
        { prop: 'numeroRequisito', name: 'No. doc.', sortable: true, cellTemplate: null },
        { prop: 'metaCreditoTotal', name: 'Crédito total', sortable: true, cellTemplate: null },
        { prop: 'metaCreditoRestante', name: 'Crédito restante', sortable: true, cellTemplate: null },
        { prop: 'razonCredito', name: 'Razón de crédito', sortable: true, cellTemplate: null },
        { prop: 'nombreEstado', name: 'Estado', sortable: true, cellTemplate: null },
    ];

    // * Seguimientos * 
    columnasSeguimientos = [
        { prop: 'observacionesResumen', name: 'Observaciones', sortable: false, cellTemplate: null },
        { prop: 'nombreUsuario', name: 'Usuario', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
    ];
    camposSeguimientos = [
        { nombre: 'Observaciones', campo: 'observaciones', tipo: 'texto', categorias: null },
        { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },
    ];
    filtrosSeguimientos = [];
    urlBusquedaSeguimientos = this.apiURLs.baseURL + this.apiURLs.documentosCobrosURL + '/searchSeguimientos';

    constructor(
        public polizasService: PolizasService,
        public service: CobrosService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        this.agregarTemplatesCreditos();
        this.agregarTemplatesAplicaciones();
        this.agregarTemplatesDocumentos();
        await this.obtenerRegistro();
        await this.obtenerPagadoresPoliza();
        // this.obtenerComisionesPoliza();
        this.obtenerCreditosDisponibles();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        if(params['id']){
            this.id = parseInt(params['id']);
        }
        if(params['id']){
            this.id = parseInt(params['id']);
            this.crumbs[1].link = '/cobros/detalle/' + this.id;
        }
    }

    agregarTemplatesDocumentos() {
        // Cobros
        this.columnasDocumentosCobros.forEach(columna => {
            columna.cellTemplate = this.cellNormalCobros;
        });
        this.columnasDocumentosCobros[7].cellTemplate = this.cellMonto;
    }

    agregarTemplatesCreditos() {
        this.columnasNotasDevolucion.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        this.columnasNotasDevolucion[5].cellTemplate = this.cellMoneda;
        this.columnasNotasDevolucion[6].cellTemplate = this.cellMoneda;
        this.columnasNotasDevolucion[8].cellTemplate = this.cellEstado;
    }

    agregarTemplatesAplicaciones() {
        this.columnasAplicaciones.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        this.columnasAplicaciones[5].cellTemplate = this.cellMonto;
        this.columnasAplicaciones[9].cellTemplate = this.cellAcciones;
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        this.titulo = 'Requerimiento de cobro';
        // Obtener registro
        var res = await this.service.obtenerDocumentoCobroPorId(this.id);
        if(!res.error) {
            this.registro = res.data.registro;
            this.comisionesPoliza = this.registro.distribucionComisionArray;
            this.crumbs[0].link = '/cobros/' + this.registro.etapa;
            // Asignar título
            this.titleService.setTitle('Requerimiento de cobro #' + this.registro.id + this.apiURLs.nombreSitioTitle);
        }
        else {
            this.router.navigate(['/404']);
        }
        this.cargando = false;
    }

    // * * * Seguimientos * * *
    abrirNuevoSeguimiento() {
        var seguimiento = new SeguimientoCobro(null, null, null, null, this.id, this.auth.idUsuarioActual(), this.auth.idEmpresaActual())

        const dialogRef = this.dialog.open(DialogNuevoSeguimientoCobro, {
            data: {
                seguimiento: seguimiento,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.guardarSeguimientoCobro(result);
            }
        });
    }

    async guardarSeguimientoCobro(seguimiento: SeguimientoCobro) {
        this.cargandoSeguimiento = true;
        if(seguimiento) {
            // Enviar el registro para guardar
            var res = null;
            if(seguimiento.id == null || seguimiento.id < 0) res = await this.service.crearSeguimientoCobro(seguimiento);
            else res = await this.service.actualizarSeguimientoCobro(seguimiento);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargandoSeguimiento = false;
    }

    async eliminarSeguimientoCobro(SeguimientoCobroId: number) {
        this.cargandoSeguimiento = true;
        if(SeguimientoCobroId) {
            // Enviar el registro para guardar
            var res = await this.service.eliminarSeguimientoCobro(SeguimientoCobroId);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargandoSeguimiento = false;
    }

    abrirSeguimientoExistente(seguimiento: SeguimientoCobro) {
        if(seguimiento.UsuarioId == this.auth.idUsuarioActual()){
            const dialogRef = this.dialog.open(DialogNuevoSeguimientoCobro, {
                data: {
                    seguimiento: seguimiento,
                }
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if(result != null) {
                    this.guardarSeguimientoCobro(result);
                }
            });
        }
    }

    paramsEspecialesSeguimientos() {
        var params = '';
        if(this.registro.id) params += '&DocumentoCobroId=' + this.registro.id;
        return params;
    }

    // * * * Aplicaciones de crédito * * *
    abrirNuevoAplicacionCredito() {
        var aplicacionCredito = new AplicacionDocumentoCredito(null, 'credito', null, null, null, null, null, this.id, null, this.auth.idUsuarioActual(), this.registro.MonedaId, this.auth.idEmpresaActual());
        aplicacionCredito.moneda  = this.registro.moneda;
        const dialogRef = this.dialog.open(DialogNuevoAplicacionCredito, {
            data: {
                aplicacionCredito: aplicacionCredito,
                DocumentoCobroId: this.id,
                cobro: this.registro,
                creditosDisponibles: this.creditosDisponibles,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.guardarAplicacionCredito(result);
            }
        });
    }

    async guardarAplicacionCredito(nuevoAplicacionCredito: AplicacionDocumentoCredito) {
        this.cargandoCreditos = true;
        if(nuevoAplicacionCredito) {
            // Enviar el registro para guardar
            var res = await this.service.crearAplicacionCredito(nuevoAplicacionCredito);
            
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargandoCreditos = false;
    }

    async eliminarAplicacionCredito(AplicacionDocumentoCreditoId: number) {
        this.cargandoCreditos = true;
        if(AplicacionDocumentoCreditoId) {
            // Enviar el registro para guardar
            var res = await this.service.eliminarAplicacionCredito(AplicacionDocumentoCreditoId);
            
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargandoCreditos = false;
    }

    async obtenerCreditosDisponibles() {
        this.cargandoCreditos = true;
        // Obtener registro
        var res = await this.service.obtenerDocumentosCreditosDisponiblesCobros(this.id);
        if(!res.error) {
            this.creditosDisponibles = res.data.documentosCreditos;
            this.mostrarCreditos = true;
        }
        this.cargandoCreditos = false;
    }

    abrirEliminarAplicacion(AplicacionDocumentoCreditoId: number) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar aplicación de crédito',
                mensaje: '¿Está seguro de que desea eliminar esta aplicación de crédito?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminarAplicacionCredito(AplicacionDocumentoCreditoId);
            }
        });
    }

    onActivateAplicacion(aplicacion: AplicacionDocumentoCredito) {
        if(aplicacion.DocumentoCreditoId) this.router.navigate([ '/cobros/detalle-credito/' + aplicacion.DocumentoCreditoId ]);
    }

    getRowClass(row) {
        return {
            'hoverRow': true
        };
    }

    modoResponsive() {
        if(!this.innerWidth) return false;
        return this.innerWidth <= 768;
    }

    clickPoliza(poliza: Poliza) {
        let PolizaId = poliza.id; 
        if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
            this.router.navigate(['/polizas/editar/' + PolizaId], { queryParams: { tab: 'cobros' } });
        }
        else if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
            this.router.navigate(['/polizas/detalle/' + PolizaId], { queryParams: { tab: 'cobros' } });
        }
    }

    // * * * Acciones * * *
    aplicarAccion(accion) {
        if(accion && !this.cargando) {
            switch(accion) {
                case 'asignarPlanilla': {
                    this.abrirAsignarAPlanilla();
                    break;
                }
                case 'planilla': {
                    this.abrirEnviarACobrados();
                    break;
                }
                case 'liquidar': {
                    this.abrirEnviarALiquidados();
                    break;
                }
                case 'regresarCobro': {
                    this.abrirRegresarACobro();
                    break;
                }
                case 'regresarCobrado': {
                    this.abrirRegresarACobrado();
                    break;
                }
                case 'anular': {
                    this.abrirAnular();
                    break;
                }
            }
        }
    }

    // * Planilla * 
    abrirAsignarAPlanilla() {
        const dialogRef = this.dialog.open(DialogAsignarAPlanillaComponent, {
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.estado == 'confirmar') {
                this.asignarAPlanilla(result.PlanillaCobroId);
            }
        });
    }

    async asignarAPlanilla(PlanillaCobroId: number) {
        this.cargando = true;
        
        // Enviar el registro para guardar
        var res = await this.service.asignarAPlanilla([this.id], PlanillaCobroId);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        
        this.cargando = false;
    }

    abrirEnviarACobrados() {
        var aplicacionCredito = new AplicacionDocumentoCredito(null, 'credito', null, null, null, null, null, this.id, null, this.auth.idUsuarioActual(), this.registro.MonedaId, this.auth.idEmpresaActual());
        aplicacionCredito.moneda  = this.registro.moneda;

        const dialogRef = this.dialog.open(DialogPasarCobrado, {
            data: {
                documentos: [this.registro],
                creditosDisponibles: this.creditosDisponibles,
                aplicacionCredito: aplicacionCredito,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.estado == 'confirmar') {
                this.enviarACobrados(result.fechaPago, result.banco, result.numeroCheque, result.formaCobro, result.multiplesFormasPago, result.usaFormaPagoMultiple, result.PlanillaCobroId, result.aplicacionCredito);
            }
        });
    }

    async enviarACobrados(fechaPago: string, banco: string, numeroCheque: string, formaCobro: string, multiplesFormasPago: string, usaFormaPagoMultiple: boolean, PlanillaCobroId: number, aplicacionCredito: AplicacionDocumentoCredito) {
        this.cargando = true;
        
        // Enviar el registro para guardar
        var res = await this.service.pasarACobrados([this.id], this.auth.idUsuarioActual(), fechaPago, banco, numeroCheque, formaCobro, multiplesFormasPago, usaFormaPagoMultiple, PlanillaCobroId, aplicacionCredito);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
            this.auth.buscadorReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        
        this.cargando = false;
    }

    // * Liquidado * 
    abrirEnviarALiquidados() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacionNumeroPorcentaje, {
            data: {
                titulo: 'Marcar como liquidado',
                mensaje: '¿Está seguro de que desea liquidar este requerimiento?',
                nombreMontoAseguradora: 'Comisión de aseguradora',
                nombreMonto: 'Diferencia aceptable',
                nombrePorcentaje: 'Porcentaje aceptable',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.accion == 'confirmar') {
                this.enviarALiquidados(result.monto, result.porcentaje, result.montoAseguradora);
            }
        });
    }

    async enviarALiquidados(diferenciaAceptable: number, porcentajeAceptable: number, montoAseguradora: number) {
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.pasarAPlanillaLiquidacion([this.id], diferenciaAceptable, porcentajeAceptable, montoAseguradora, this.auth.idUsuarioActual());
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/cobros/detalle-planilla-liquidacion/' + res.data.result.PlanillaLiquidacionId]);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    abrirRegresarACobrado() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como cobrado',
                mensaje: '¿Está seguro de que desea regresar estos requerimientos a cobrados?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.regresarACobrados();
            }
        });
    }

    async regresarACobrados() {
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.regresarACobrados([this.id], this.auth.idUsuarioActual());
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    abrirAnular() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacionComentarios, {
            data: {
                titulo: 'Marcar como anulado',
                mensaje: '¿Está seguro de que desea anular este documento?',
                nombreComentarios: 'Razón de anulación',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.anularDocumento(result.comentarios);
            }
        });
    }

    async anularDocumento(razonAnulacion: string) {
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.anularDocumentosCobros([this.id], this.auth.idUsuarioActual(), razonAnulacion);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    abrirRegresarACobro() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como por cobrar',
                mensaje: '¿Está seguro de que desea regresar este requerimiento a por cobrar?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.regresarACobro();
            }
        });
    }

    async regresarACobro() {
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.regresarACobro([this.id], this.auth.idUsuarioActual());
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    getColorEstado(){
        let etapas = {
            'cobro': 'green',
            'cobrado' : 'blue',
            'liquidado' : 'blue',
            'anulado': 'red',
        }
        return etapas[this.registro.etapa] ? etapas[this.registro.etapa] : '#fcdf03';
    }

    calcularParamsEspecialesAplicaciones(): string {
        var paramsEspeciales = '';
        paramsEspeciales += '&DocumentoCobroId=' + this.id;
        return paramsEspeciales;
    }

    // * * * Estado de cuenta * * *
    abrirGenerarEstadoCuenta() {
        const dialogRef = this.dialog.open(DialogGenerarEstadoCuenta, {
            data: {
                polizaDefault: this.registro.poliza,
                pagadorDefault: this.registro.pagador,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {

            }
        });
    }

    async obtenerPagadoresPoliza() {
        this.cargando = true;
        if(this.registro.PolizaId) {
            var res = await this.polizasService.obtenerTodosPagadoresPoliza(this.registro.PolizaId);
            if(!res.error) {
                this.pagadoresPoliza = res.data.registros;
                this.filtrosDocumentosCobros[0].categorias = this.pagadoresPoliza;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    calcularParamsEspecialesCobros(): string {
        var paramsEspeciales = '';

        if(this.registro) paramsEspeciales += '&PolizaId=' + this.registro.PolizaId;
        if(this.idsPagadores && this.idsPagadores.length > 0) paramsEspeciales += '&idsPagadores=' + this.idsPagadores.toString();

        return paramsEspeciales;
    }

    actualizarPagadores(items: Usuario[]) {
        this.idsPagadores = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            this.idsPagadores.push(item.id);
        }
    }

    totalMultiplesFormas() {
        if(!this.registro) return 0;
        let total = 0;
        if(this.registro.multiplesFormasPagoArray) {
            for (const el of this.registro.multiplesFormasPagoArray) total += el.monto;
        }
        return total;
    }

    actualizarDataCobros($event) {
        if($event) {
            this.totalOtrosDocumentos = $event.totalCobros;
        }
    }

    // * * * * * Comisiones de póliza * * * * *
    // async obtenerComisionesPoliza() {
    //     this.cargando = true;
    //     var res = await this.service.obtenerComisionesPoliza(this.registro.PolizaId);
    //     if(!res.error) {
    //         this.comisionesPoliza = res.data.registros;
    //     }
    //     else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
    //     this.cargando = false;
    // }

    calcularTotalesComisiones(comisiones: ComisionPolizaInciso[]) {
        let primaNeta = 0, comision = 0, comisionPorPago = 0;
        if(comisiones) {
            for (const el of comisiones) {
                primaNeta += el.primaNeta;
                comision += el.comision;
                comisionPorPago += el.comisionPorPago;
            }
        }

        return { primaNeta, comision, comisionPorPago };
    }

    actualizarDataCreditos($event, aplicados: boolean) {
        if($event) {
            if(aplicados) {
                this.totalAplicadosAplicado = $event.totalCreditoUtilizado;
            }
            else {
                this.totalDisponiblesPorAplicar = $event.totalCreditoRestante;
            }
        }
    }

    totalDisponibles() {
        let total = 0;
        if(this.creditosDisponibles) {
            for (const el of this.creditosDisponibles) {
                total += el.creditoRestante;
            }
        }
        return total;
    }

    calcularParamsEspecialesMemos(): string {
        var paramsEspeciales = '';
        if(this.id) paramsEspeciales += '&DocumentoCobroId=' + this.id;
        paramsEspeciales += '&tipoUso=memo'
        return paramsEspeciales;
    }

    abrirDialogNuevaSolicitud(tipoUso: string) {
        var solicitud = new SolicitudPoliza(-1, null, null, null, null, null, null, null, null, null, null, null, false, null, null, false, null, this.auth.idUsuarioActual(), null, null, this.id, null, null, this.auth.idEmpresaActual(), null);    

        const dialogRef = this.dialog.open(DialogNuevaSolicitud, {
            data: {
                solicitud: solicitud,
                tipoUso: tipoUso,
                modulo: 'reclamos',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.guardarDescargarSolicitud(result);
            }
        });
    }

    abrirDialogEditarSolicitud(solicitud: SolicitudPoliza) {
        const dialogRef = this.dialog.open(DialogNuevaSolicitud, {
            data: {
                solicitud: solicitud,
                modulo: 'cobros',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.guardarDescargarSolicitud(result);
            }
        });
    }

    async guardarDescargarSolicitud(solicitud: SolicitudPoliza) {
        this.cargando = true;
        if (solicitud) {
            // Enviar el registro para guardar
            var res = await this.polizasService.crearSolicitudPoliza(this.id, solicitud);

            if (!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
                solicitud.id = res.data.result.solicitud.id;
                this.cargando = false;
                this.descargarPdfSolicitud(solicitud);
            } else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
                this.cargando = false;
            }
        }
        else this.cargando = false;
    }

    async descargarPdfSolicitud(solicitud: SolicitudPoliza) {
        this.cargando = true;
        if (solicitud) {
            const nombreArchivo = `Solicitud #${solicitud.id} - ${solicitud.plantilla.nombre}`;
            var resArchivo = await this.polizasService.descargarPdfSolicitud(solicitud.id, nombreArchivo);
            if (resArchivo.error) {
                this.libraryService.crearNotificacion(resArchivo.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    regresar() {
        this.location.back();
    }
}
