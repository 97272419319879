import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { ContactoUsuario } from 'src/app/models/contactoUsuario';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { LibraryService } from 'src/app/library/library.service';

export interface DialogData {
    idsPolizas: number[],
    idsClientes: number[],
}

@Component({
    selector: 'dialog-seleccionar-contactos',
    templateUrl: './dialog-seleccionar-contactos.component.html',
})
export class DialogSeleccionarContactosClienteComponent {
    apiURLs = new APIUrls();
    contactos: ContactoUsuario[];
    
    constructor(
        public dialogRef: MatDialogRef<DialogSeleccionarContactosClienteComponent>,
        public auth: AuthService,
        public libraryService: LibraryService,
        public clientesService: ClientesService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        let contactosSeleccionados = this.contactos.filter(c => c.seleccionado);
        this.dialogRef.close(contactosSeleccionados);
    }

    ngOnInit() {
        this.obtenerContactos();
    }

    async obtenerContactos() {
        var res = await this.clientesService.obtenerTodosContactosFiltros(this.data.idsPolizas, this.data.idsClientes);
        if(!res.error) {
            this.contactos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
    }
}