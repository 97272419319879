import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconEdit from '@iconify/icons-fa-solid/edit';
import { PolizasService } from '../polizas.service';
import { Poliza } from 'src/app/models/poliza';
import { Certificado } from 'src/app/models/certificado';
import { APIUrls } from 'src/app/api/apiUrls';
import { Usuario } from 'src/app/models/usuario';
import { PageEvent } from '@angular/material';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { RamosService } from 'src/app/ramos/ramos.service';

export interface DialogData {
    modo: string,
    polizaDefault: Poliza,
}

@Component({
    selector: 'vex-dialog-buscador-v2-global',
    templateUrl: './dialog-buscador-v2-global.component.html',
    styleUrls: ['./dialog-buscador-v2-global.component.scss']
})
export class DialogBuscadorV2GlobalComponent implements OnInit {
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconEdit = iconEdit;
    cargando: boolean = false;
    apiURLs = new APIUrls();
    titulo: string;
    jsonConverters = new JSONConverters();
    certificadosPolizaDefault: Certificado[];
    tipoBusqueda: string = 'certificado';
    tipoPolizas: string = 'danios';
    estadosPolizasCertificados: string[] = [ 'nueva', 'traslado', 'renovada' ];

    @ViewChild('cellNormalCertificado', { static: true }) cellNormalCertificado: TemplateRef<any>;
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;

    mostrarTabla: boolean = false;
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    urlAutocompleteAgrupadores = this.apiURLs.baseURL + this.apiURLs.agrupadoresURL + '/search';
    urlAutocompleteAseguradoras = this.apiURLs.baseURL + this.apiURLs.aseguradorasURL + '/search';
    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/search';
    campos = [
        { nombre: 'Número de póliza', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'Certificado', campo: 'general', tipo: 'texto', categorias: null },
    ];
    columnas = [
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'cliente.nombreCompleto', name: 'Cliente', sortable: true, cellTemplate: null },
        { prop: 'aseguradora.nombre', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'ramo.nombre', name: 'Ramo', sortable: true, cellTemplate: null },
        { prop: 'producto.nombre', name: 'Producto', sortable: true, cellTemplate: null },
        { prop: 'fechaVigenciaFinFormato', name: 'Fecha de fin de vigencia', sortable: true, cellTemplate: null },
    ];
    filtros = [
        // Estado
        {
            nombre: 'Estado', campo: 'estado', tipo: 'select-multiple',
            mostrar: true,
            categorias: this.apiURLs.estadosPolizas, valor: this.apiURLs.estadosPolizas.filter(e => e.mostrarPorDefecto).map(e => e.id),
            ignorarLimpieza: true,
        },
        {
            nombre: 'Ramo', campo: 'idsRamos', tipo: 'select-multiple',
            categorias: null, valor: null,
        },
        // Cliente
        { nombre: 'Cliente', campo: 'nombreCliente', tipo: 'texto', valor: null, mostrar: true, categorias: null},
        // Certificado
        { nombre: 'Certificado', campo: 'nombreCertificado', tipo: 'texto', valor: null, mostrar: false, categorias: null},
        // Agrupador
        { nombre: 'Agrupador', campo: 'nombreAgrupador', tipo: 'texto', valor: null, mostrar: false, categorias: null},
        // Dependientes
        { nombre: 'Dependientes', campo: 'nombreDependiente', tipo: 'texto', valor: null, mostrar: false, categorias: null},
        // Aseguradora
        {
            nombre: 'Aseguradora', campo: 'aseguradora', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAseguradoras, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.aseguradoraDeJSON, parserService: this.jsonConverters,
        },
    ];
    posiblesEstados = [
        { nombre: 'Emisión', color: '#0b60b5' },
        { nombre: 'Nueva', color: '#52b50b' },
        { nombre: 'Traslado', color: '#853b07' },
        { nombre: 'Renovada', color: '#000000' },
        { nombre: 'Histórica', color: '#878787' },
        { nombre: 'Cancelada', color: '#d40000' },
    ];
    posiblesEstadosCertificados = [
        { nombre: 'Activo', color: 'green' },
        { nombre: 'Inactivo', color: 'black' },
        { nombre: 'Incluido', color: 'blue' },
        { nombre: 'Excluido', color: 'red' },
    ];

    columnasCertificados = [
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'nombre', name: 'Descripción', sortable: true, cellTemplate: null },
        { prop: 'nombresPagadores', name: 'Pagadores', sortable: true, cellTemplate: null },
        { prop: 'clausulasGarantia', name: 'Cláusula de garantía', sortable: true, cellTemplate: null },
        { prop: 'sumaAsegurada', name: 'Suma asegurada', sortable: true, cellTemplate: null },
        { prop: 'primaFacturada', name: 'Prima facturada', sortable: true, cellTemplate: null },
        { prop: 'nombrePlantillaRamo', name: 'Plantilla', sortable: true, cellTemplate: null },
        { prop: 'numeroPoliza', name: 'Póliza', sortable: true, cellTemplate: null },
    ];
    camposCertificados = [
        { nombre: 'Descripción', campo: 'nombre', tipo: 'texto', categorias: null },
        { nombre: 'Número', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'Pagador', campo: 'pagador', tipo: 'texto', categorias: null },
        { nombre: 'Beneficiarios y dependientes', campo: 'beneficiarios-dependientes', tipo: 'texto', categorias: null },
    ];
    filtrosCertificados = [
        // Estado
        {
            nombre: 'Estado', campo: 'idsEstados', tipo: 'select-multiple',
            mostrar: false,
            categorias: this.apiURLs.estadosCertificados
        },
    ];
    urlBusquedaCertificados = this.apiURLs.baseURL + this.apiURLs.certificadosURL + '/search';

    constructor(
        public dialogRef: MatDialogRef<DialogBuscadorV2GlobalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public service: PolizasService,
        public ramosService: RamosService,
        private libraryService: LibraryService,
        private router: Router,
        public dialog: MatDialog,
        public auth: AuthService
    ) { }

    ngOnInit(): void {
        // Arreglar estilo
        var dialog = document.getElementById("dialogInscripcionTorneo");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        switch(this.data.modo) {
            case 'buscador-global': this.titulo = 'Buscador global'; break;
            case 'poliza-certificado': this.titulo = 'Buscador de póliza y certificado'; break;
        }

        this.agregarTemplates();
        if(this.data.polizaDefault) {
            this.obtenerCertificadosPolizaDefault();
        }

        this.obtenerRamos();
        this.mostrarTabla = true;
    }

    agregarTemplates() {
        this.columnas.forEach(columna  => {
            columna.cellTemplate = this.cellNormal;
        });
        this.columnasCertificados.forEach(columna  => {
            columna.cellTemplate = this.cellNormalCertificado;
        });
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    async obtenerCertificadosPolizaDefault() {
        this.cargando = true;
        if(this.data.polizaDefault) {
            var res = await this.service.obtenerTodosCertificadosPoliza(this.data.polizaDefault.id);
            if(!res.error) {
                this.certificadosPolizaDefault = res.data.registros;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    clickPoliza(poliza: Poliza) {
        let PolizaId = poliza.id;
        if(this.data.modo == 'buscador-global') {
            if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                this.router.navigate(['/polizas/editar/' + PolizaId]);
            }
            else if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
                this.router.navigate(['/polizas/detalle/' + PolizaId]);
            }
            this.cerrar();
        }
        else if(this.data.modo == 'poliza-certificado') {
            this.dialogRef.close({
                poliza: poliza,
            });
        }
    }

    clickCliente(cliente: Usuario) {
        let UsuarioId = cliente.id;
        if(this.data.modo == 'buscador-global') {
            if(this.auth.tienePermisoPara(this.apiURLs.modulos.CLIENTES.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                this.router.navigate(['/clientes/editar/' + UsuarioId]);
            }
            else if(this.auth.tienePermisoPara(this.apiURLs.modulos.CLIENTES.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
                this.router.navigate(['/clientes/detalle/' + UsuarioId]);
            }
            this.cerrar();
        }
        else if(this.data.modo == 'poliza-certificado') {
            this.dialogRef.close({
                cliente: cliente,
            });
        }
    }
    
    clickCertificado(certificado: Certificado) {
        let CertificadoId = certificado.id;
        if(this.data.modo == 'buscador-global') {
            if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                this.router.navigate(['/polizas/editar-certificado/' + CertificadoId]);
            }
            else if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
                this.router.navigate(['/polizas/detalle-certificado/' + CertificadoId]);
            }
            this.cerrar();
        }
        else if(this.data.modo == 'poliza-certificado') {
            this.dialogRef.close({
                certificado: certificado,
                poliza: certificado.poliza,
            });
        }
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';

        if(this.auth.esAccesoACliente()) {
            paramsEspeciales += '&accesoACliente=1';
        }

        if(this.auth.idsAgentesCartera()) {
            paramsEspeciales += '&soloCarterasAgentes=' + this.auth.idsAgentesCartera().toString();
        }

        if(this.tipoBusqueda == 'certificado') {
            paramsEspeciales += '&tipoPolizas=' + this.tipoPolizas;
            if(this.estadosPolizasCertificados && this.estadosPolizasCertificados.length > 0) paramsEspeciales += '&estadosPoliza=' + this.estadosPolizasCertificados.join(',');
            else {
                paramsEspeciales += '&estadosPoliza=' + this.apiURLs.estadosPolizas.map(e => e.id).join(',');
            }
        }

        return paramsEspeciales;
    }

    async obtenerRamos() {
        var res = await this.ramosService.obtenerTodosRamos();
        if(!res.error) {
            this.filtros[1].categorias = res.data.registros;
        }
    }
}
