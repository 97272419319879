import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import { Usuario } from '../../models/usuario';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconRevision from '@iconify/icons-fa-solid/check-double';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconInfo from '@iconify/icons-fa-solid/info-circle';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconAdd from '@iconify/icons-fa-solid/plus';
import iconGenerate from '@iconify/icons-fa-solid/money-bill';
import iconError from '@iconify/icons-fa-solid/exclamation-circle';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import iconRenovar from '@iconify/icons-fa-solid/sync';
import iconCliente from '@iconify/icons-fa-solid/user';
import iconWarning from '@iconify/icons-fa-solid/exclamation-triangle';
import iconCancelar from '@iconify/icons-fa-solid/times';
import iconCopy from '@iconify/icons-fa-solid/copy';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, MatBottomSheetRef, MatBottomSheet } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { Ramo } from 'src/app/models/ramo';
import { RamosService } from 'src/app/ramos/ramos.service';
import { PolizasService } from '../polizas.service';
import { Poliza } from 'src/app/models/poliza';
import { Moneda } from 'src/app/models/moneda';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { Producto } from 'src/app/models/producto';
import { Aseguradora } from 'src/app/models/aseguradora';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { DocumentoPoliza } from 'src/app/models/documentoPoliza';
import { PorcentajeCoaseguros } from 'src/app/models/porcentajeCoaseguros';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { InformacionCliente } from 'src/app/models/informacionCliente';
import { DialogNuevoCliente } from '../dialog-nuevo-cliente/dialog-nuevo-cliente.component';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { PagadorPoliza } from 'src/app/models/pagadorPoliza';
import { RevisionPoliza } from 'src/app/models/revisionPoliza';
import { CorreccionControlCalidad } from 'src/app/models/correccionControlCalidad';
import { DialogNuevoMapaProceso } from 'src/app/mapas-procesos/dialog-nuevo-mapa-proceso/dialog-nuevo-mapa-proceso.component';
import { SolicitudPoliza } from 'src/app/models/solicitudPoliza';
import { DialogNuevaSolicitud } from '../dialog-nueva-solicitud/dialog-nueva-solicitud.component';
import { DialogAgregarDocumentoPolizaComponent } from '../dialog-agregar-documento-poliza/dialog-agregar-documento-poliza.component';
import { DialogAplicarPlantillaCertificados } from '../dialog-aplicar-plantilla-certificados/dialog-aplicar-plantilla-certificados.component';
import { DialogDescargarPlantillaCertificados } from '../dialog-descargar-plantilla-certificado/dialog-descargar-plantilla-certificado.component';
import { DialogDescargaFacturacionesComponent } from '../dialog-descarga-facturaciones/dialog-descarga-facturaciones.component';
import { Empresa } from 'src/app/models/empresa';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
import { ProspectosService } from 'src/app/prospectos/prospectos.service';
import { RazonPerdida } from 'src/app/models/razonPerdida';
import { DialogCertificadosPagador } from '../dialog-certificados-pagador/dialog-certificados-pagador.component';
import { PagadorCertificado } from 'src/app/models/pagadorCertificado';
import { DialogCancelarPolizaComponent } from '../dialog-cancelar-poliza/dialog-cancelar-poliza.component';
import { BeneficiarioDependienteCertificado } from 'src/app/models/beneficiarioDependienteCertificado';
import { Certificado } from 'src/app/models/certificado';
import { Declaracion } from 'src/app/models/declaracion';
import { AclaracionPoliza } from 'src/app/models/aclaracionPoliza';
import { DialogNuevoAclaracionPoliza } from '../dialog-nuevo-aclaracion-poliza/dialog-nuevo-aclaracion-poliza.component';
import { GenericDialogConfirmacionCampos } from 'src/app/library/generic-dialog-campos/generic-dialog-campos.component';
import { GenericDialogConfirmacionOpciones } from 'src/app/library/generic-dialog-confirmacion-opciones/generic-dialog-confirmacion-opciones.component';
import { DialogAgregarMultiplesDocumentosPolizaComponent } from '../dialog-agregar-multiples-documentos/dialog-agregar-multiples-documentos.component';
import { ClausulaGarantiaPoliza } from 'src/app/models/clausulaGarantiaPoliza';
import { DialogNuevoClausulaGarantiaPoliza } from '../dialog-nuevo-clausula-garantia-poliza/dialog-nuevo-clausula-garantia-poliza.component';
import { Cobrador } from 'src/app/models/cobrador';
import { GenericDialogDatepicker } from 'src/app/library/generic-dialog-datepicker/generic-dialog-datepicker.component';
import { DireccionCertificado } from 'src/app/models/direccionCertificado';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-poliza',
    templateUrl: './nuevo-poliza.component.html',
    styleUrls: ['./nuevo-poliza.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoPolizaComponent implements OnInit {
    crumbs = [
        { nombre: 'Pólizas', link: '/polizas' },
        { nombre: 'Editar póliza', link: '/polizas/editar/' },
    ];
    // Íconos
    iconSave = iconSave;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconInfo = iconInfo;
    iconAdd = iconAdd;
    iconEdit = iconEdit;
    iconCopy = iconCopy;
    iconGenerate = iconGenerate;
    iconRevision = iconRevision;
    iconError = iconError;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    iconRenovar = iconRenovar;
    iconCliente = iconCliente;
    iconWarning = iconWarning;
    iconCancelar = iconCancelar;

    // Banderas y generales
    cargando: boolean = false;
    cargandoAclaracion: boolean = false;
    cargandoClausulaGarantia: boolean = false;
    cargandoPagador: boolean = false;
    modoEdicion: boolean;
    modoDetalle: boolean;
    modoCreacion: boolean;
    deshabilitado: boolean = false;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    jsonConverters = new JSONConverters();

    // Tabs
    selectedIndex: number = 0;
    tabId: string = null;

    // Datos
    id: number;
    registro: Poliza;
    ramos: Ramo[];
    empresasRegionales: Empresa[];
    aseguradoras: Aseguradora[];
    monedas: Moneda[];
    productos: Producto[];
    tipos: any[];
    caracteres: any[];
    frecuenciasPagos: any[];
    formasDeCobro: any[];
    mensajeError: string;
    pagadores: Usuario[];
    totalPrimaTotal: number;
    totalPrimaNeta: number;
    totalEmision: number;
    totalGastos: number;
    totalImpuestos: number;
    totalFraccionamiento: number;
    historicas: Poliza[];

    rutaArchivoUbicaciones: string;
    archivoUbicaciones: File;
    rutaArchivoBeneficiarios: string;
    archivoBeneficiarios: File;
    rutaArchivoDeclaraciones: string;
    archivoDeclaraciones: File;
    rutaArchivoExclusionMasiva: string;
    archivoExclusionMasiva: File;

    // Usuarios
    urlAutocompleteAgentes = this.apiURLs.baseURL + this.apiURLs.usuariosURL + '/autocompleteAgentes';
    agentes: Usuario[] = [];
    vendedores: Usuario[] = [];
    urlAutocompleteEjecutivos = this.apiURLs.baseURL + this.apiURLs.cobradoresURL + '/autocompleteEjecutivos';
    ejecutivos: Usuario[] = [];
    urlAutocompleteClientes = this.apiURLs.baseURL + this.apiURLs.informacionClientesURL + '/search';
    clientes: Usuario[] = [];
    cobradores: Cobrador[] = [];
    razonesPerdidas: RazonPerdida[] = [];
    usuarios: any[] = [];

    columnasEjecutivos = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo', sortable: true, cellTemplate: null },
    ];
    camposEjecutivos = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];

    columnasClientes = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'nombreAgrupador', name: 'Agrupador', sortable: true, cellTemplate: null },
        { prop: 'nitCliente', name: 'NIT', sortable: true, cellTemplate: null },
        { prop: 'telefono1', name: 'Teléfono', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo electrónico', sortable: true, cellTemplate: null },
    ];
    camposClientes = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];

    // Documentos
    cargandoDocumento: boolean = false;
    rutaArchivoDocumento: string;
    archivoDocumento: File;
    modoSubirDocumento: boolean = true;

    // Revisión
    modoRevision: boolean = false;
    modoVerRevision: boolean = false;
    revision: RevisionPoliza;
    interval;
    mostrarTabla = false;

    // Certificados
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;
    @ViewChild('cellNormalCertificado', { static: true }) cellNormalCertificado: TemplateRef<any>;
    @ViewChild('cellNormalSolicitud', { static: true }) cellNormalSolicitud: TemplateRef<any>;
    @ViewChild('cellNormalCobro', { static: true }) cellNormalCobro: TemplateRef<any>;
    @ViewChild('cellNormalCredito', { static: true }) cellNormalCredito: TemplateRef<any>;
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;
    @ViewChild('cellPorcentaje', { static: true }) cellPorcentaje: TemplateRef<any>;
    @ViewChild('cellMonto', { static: true }) cellMonto: TemplateRef<any>;
    @ViewChild('cellMontoCobro', { static: true }) cellMontoCobro: TemplateRef<any>;
    @ViewChild('cellMontoCredito', { static: true }) cellMontoCredito: TemplateRef<any>;
    @ViewChild('cellDescargarDocumento', { static: true }) cellDescargarDocumento: TemplateRef<any>;
    @ViewChild('cellCheckNotNull', { static: true }) cellCheckNotNull: TemplateRef<any>;
    @ViewChild('cellAcciones', { static: true }) cellAcciones: TemplateRef<any>;
    @ViewChild('cellEstadosClausulas', { static: true }) cellEstadosClausulas: TemplateRef<any>;

    // Columnas de tabla
    columnasCertificados = [
        { name: '', sortable: false, checkboxable: true, headerCheckboxable: true, cellTemplate: null, width: 50},
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null, width: 65 },
        { prop: 'nombre', name: 'Descripción', sortable: true, cellTemplate: null, width: 350 },
        { prop: 'nombresPagadores', name: 'Pagadores', sortable: true, cellTemplate: null },
        { prop: 'clausulasGarantia', name: 'Cláusula de garantía', sortable: true, cellTemplate: null },
        { prop: 'sumaAsegurada', name: 'Suma asegurada', sortable: true, cellTemplate: null },
        { prop: 'primaFacturada', name: 'Prima facturada', sortable: true, cellTemplate: null },
        { prop: 'nombrePlantillaRamo', name: 'Plantilla', sortable: true, cellTemplate: null },
    ];
    accionesCertificados = [
        { id: 'duplicar', nombre: 'Duplicar certificados', maximo: 3 },
        { id: 'eliminar', nombre: 'Eliminar certificados' },
    ]

    posiblesEstadosCertificados = [
        { nombre: 'Activo', color: 'green' },
        { nombre: 'Inactivo', color: 'black' },
        { nombre: 'Incluido', color: 'blue' },
        { nombre: 'Excluido', color: 'red' },
    ]

    posiblesEstadosCobros =  this.apiURLs.etapasCobros;

    posiblesEstadosSolicitudes = [
        { nombre: 'Activo', color: '#138708' },
        { nombre: 'Finalizado', color: 'blue' },
        { nombre: 'Cancelado', color: 'red' },
        { nombre: 'En espera', color: 'black' },
    ]

    // Campos para buscador
    camposCertificados = [
        { nombre: 'Descripción', campo: 'nombre', tipo: 'texto', categorias: null },
        { nombre: 'Número', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'Pagador', campo: 'pagador', tipo: 'texto', categorias: null },
        { nombre: 'Beneficiarios y dependientes', campo: 'beneficiarios-dependientes', tipo: 'texto', categorias: null },
    ];

    columnasPolizas = [
        { prop: 'numero', name: 'Número', sortable: true, cellTemplate: null },
        { prop: 'aseguradora.nombre', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'ramo.nombre', name: 'Ramo', sortable: true, cellTemplate: null },
        { prop: 'nombreEstado', name: 'Estado', sortable: false, cellTemplate: null },
        { prop: 'fechaVigenciaInicioFormato', name: 'Fecha de inicio de vigencia', sortable: true, cellTemplate: null },
        { prop: 'fechaVigenciaFinFormato', name: 'Fecha de fin de vigencia', sortable: true, cellTemplate: null },
        { prop: 'nombreEstado', name: 'Estado', sortable: false, cellTemplate: null },
        { prop: 'nombreUsuarioRenovador', name: 'Renovado por', sortable: false, cellTemplate: null },
        { prop: 'fechaRenovacionFormato', name: 'Fecha renovación', sortable: false, cellTemplate: null },
    ];

    camposPolizas = [
        { nombre: 'Número de póliza', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
    ];

    // Filtros
    filtrosCertificados = [
        // Estado
        {
            nombre: 'Estado', campo: 'idsEstados', tipo: 'select-multiple',
            mostrar: false,
            categorias: this.apiURLs.estadosCertificados
        },
    ];
    filtrosPolizas = [];

    // * Aclaraciones pólizas *
    columnasAclaraciones = [
        { prop: 'observacionesResumen', name: 'Observaciones', sortable: false, cellTemplate: null },
        { prop: 'nombreUsuarioIngreso', name: 'Usuario', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
    ];
    camposAclaraciones = [
        { nombre: 'Observaciones', campo: 'observaciones', tipo: 'texto', categorias: null },
        { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },
    ];
    filtrosAclaraciones = [];
    urlBusquedaAclaraciones = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/searchAclaraciones';

    // * Cláusulas de garantía pólizas *
    columnasClausulasGarantia = [
        { name: '', sortable: false, checkboxable: true, headerCheckboxable: true, cellTemplate: null },
        // Beneficiario
        { prop: 'beneficiario', name: 'Beneficiario', sortable: true, cellTemplate: null },
        // Descripción
        { prop: 'descripcionResumen', name: 'Descripción', sortable: false, cellTemplate: null },
        // Propietario
        { prop: 'propietario', name: 'Propietario', sortable: true, cellTemplate: null },
        // Valor
        { prop: 'valorClausula', name: 'Valor', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuarioIngreso', name: 'Usuario', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'estado', name: 'Estado', sortable: true, cellTemplate: null },
        { prop: 'fechaAltaFormato', name: 'Fecha de Alta', sortable: true, cellTemplate: null },
        { prop: 'fechaBajaFormato', name: 'Fecha de Baja', sortable: true, cellTemplate: null },
    ];
    camposClausulasGarantia = [
        { nombre: 'Descripción', campo: 'descripcion', tipo: 'texto', categorias: null },
        { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },
        { nombre: 'Beneficiario', campo: 'beneficiario', tipo: 'texto', categorias: null },
        { nombre: 'Propietario', campo: 'propietario', tipo: 'texto', categorias: null },

    ];
    filtrosClausulasGarantia = [];
    urlBusquedaClausulasGarantia = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/searchClausulasGarantia';

    // * Cambios de estado * 
    urlBusquedaCambiosEstado = this.apiURLs.baseURL + this.apiURLs.cambiosEstadosPolizasURL + '/search';
    columnasCambiosEstados = [
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuario', name: 'Usuario', sortable: true, cellTemplate: null },
        { prop: 'nombreEstadoAnterior', name: 'Anterior', sortable: false, cellTemplate: null },
        { prop: 'nombreEstadoSiguiente', name: 'Siguiente', sortable: false, cellTemplate: null },
    ];
    camposCambiosEstados = [
        { nombre: 'Fecha', campo: 'fecha', tipo: 'rango-fechas', categorias: null },
    ];
    filtrosCambiosEstados = [];

    urlBusquedaCertificados = this.apiURLs.baseURL + this.apiURLs.certificadosURL + '/search';
    urlBusquedaPolizas = this.apiURLs.baseURL + this.apiURLs.polizasURL + '/search';
    urlAutocompleteAseguradoras = this.apiURLs.baseURL + this.apiURLs.aseguradorasURL + '/search';
    urlBusquedaDocumentosCobros = this.apiURLs.baseURL + this.apiURLs.documentosCobrosURL + '/search';
    urlBusquedaDocumentosCreditos = this.apiURLs.baseURL + this.apiURLs.documentosCreditosURL + '/search';
    urlBusquedaMapas = this.apiURLs.baseURL + this.apiURLs.mapasProcesosURL + '/search';
    urlBusquedaSolicitudes = this.apiURLs.baseURL + this.apiURLs.solicitudesPolizasURL + '/search';
    urlBusquedaMensajerias = this.apiURLs.baseURL + this.apiURLs.mensajeriaURL + '/search';

    // Documentos de cobros
    columnasDocumentosCobros = [
        { prop: 'pagador.nombreCompleto', name: 'Pagador', sortable: true, cellTemplate: null },
        { prop: 'poliza.numero', name: 'No. póliza', sortable: true, cellTemplate: null },
        { prop: 'poliza.nombreAseguradora', name: 'Aseguradora', sortable: true, cellTemplate: null },
        { prop: 'fechaEmisionFormato', name: 'Vencimiento', sortable: true, cellTemplate: null },
        { prop: 'fechaPagoFormato', name: 'Pagado', sortable: true, cellTemplate: null },
        { prop: 'numeroRequisito', name: 'No. req.', sortable: true, cellTemplate: null },
        { prop: 'numeroEndoso', name: 'No. endoso', sortable: true, cellTemplate: null },
        { prop: 'meta', name: 'Monto', sortable: true, cellTemplate: null },
        // { prop: 'nombreEtapa', name: 'Etapa', sortable: true, cellTemplate: null },
        { prop: 'numeroPagoNombre', name: 'No. pago', sortable: true, cellTemplate: null },
    ];

    camposDocumentosCobros = [
        { nombre: 'Número de requerimiento', campo: 'numeroRequisito', tipo: 'texto', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'Fecha de requerimiento / vencimiento', campo: 'fechaEmision', tipo: 'rango-fechas', categorias: null },
    ];

    filtrosDocumentosCobros: any[] = [
        /*// Pagador
        {
            nombre: 'Pagador', campo: 'pagador', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteClientes, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombreCompleto', fieldSearch: 'general',
            parser: this.jsonConverters.usuarioDeJSON, parserService: this.jsonConverters,
        },*/
        { nombre: 'Pagador', campo: 'pagador', tipo: 'select-unico', valor: null, mostrar: false, categorias: [], campoNombre: 'nombreCompleto'},
        // Aseguradora
        {
            nombre: 'Aseguradora', campo: 'aseguradora', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteAseguradoras, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombre', fieldSearch: 'nombre',
            parser: this.jsonConverters.aseguradoraDeJSON, parserService: this.jsonConverters,
        },
        {
            nombre: 'Estado', campo: 'etapa', tipo: 'select-unico', valor: null, mostrar: false, categorias: this.apiURLs.etapasCobros, campoNombre: 'nombre'
        }
    ];

    // Documentos de créditos
    columnasDocumentosCreditos = [
        { prop: 'id', name: 'ID', sortable: true, cellTemplate: null },
        { prop: 'pagador.nombreCompleto', name: 'Pagador', sortable: true, cellTemplate: null },
        { prop: 'numeroRequisito', name: 'No. doc.', sortable: true, cellTemplate: null },
        { prop: 'metaCreditoTotal', name: 'Crédito total', sortable: true, cellTemplate: null },
        { prop: 'metaCreditoUtilizado', name: 'Crédito utilizado', sortable: true, cellTemplate: null },
        { prop: 'metaCreditoRestante', name: 'Crédito restante', sortable: true, cellTemplate: null },
    ];

    camposDocumentosCreditos = [
        { nombre: 'Número de requerimiento', campo: 'numeroRequisito', tipo: 'texto', categorias: null },
        { nombre: 'ID interno', campo: 'id', tipo: 'texto', categorias: null },
    ];

    filtrosDocumentosCreditos = [
        // Pagador
        {
            nombre: 'Pagador', campo: 'pagador', tipo: 'autocomplete-unico',
            urlAutocomplete: this.urlAutocompleteClientes, seleccionados: [], mostrar: false,
            idProp: 'id', titleProp: 'nombreCompleto', fieldSearch: 'general',
            parser: this.jsonConverters.usuarioDeJSON, parserService: this.jsonConverters,
        },
    ];

    // Mapas
    columnasMapas = [
        { prop: 'id', name: 'ID', sortable: true, cellTemplate: null },
        { prop: 'nombrePlantilla', name: 'Tipo de proceso', sortable: true, cellTemplate: null },
        { prop: 'duracionDias', name: 'Días', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuario', name: 'Creado por', sortable: true, cellTemplate: null },
        { prop: 'fechaActualizacionFormato', name: 'Actualizado', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    camposMapas = [
        { nombre: 'Tipo de mapa', campo: 'nombre-plantilla', tipo: 'texto', categorias: null },
    ];

    // Solicitudes
    columnasSolicitudes = [
        { prop: 'id', name: 'No. Prosis', sortable: true, cellTemplate: null },
        { prop: 'nombrePlantilla', name: 'Plantilla', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'de', name: 'De', sortable: true, cellTemplate: null },
        { prop: 'para', name: 'Para', sortable: true, cellTemplate: null },
        { prop: 'usuario.nombreCompleto', name: 'Creado por', sortable: true, cellTemplate: null },
        { prop: 'solicitudIdAnterior', name: 'Solicitud migrada', sortable: true, cellTemplate: null },
        { prop: 'MapaProcesoId', name: 'No. mapa', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    camposSolicitudes = [
        { nombre: 'No. solicitud', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'Solicitud migrada', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'No. mapa', campo: 'MapaProcesoId', tipo: 'texto', categorias: null },
        { nombre: 'De', campo: 'de', tipo: 'texto', categorias: null },
        { nombre: 'Para', campo: 'para', tipo: 'texto', categorias: null },
        { nombre: 'Notas adicionales', campo: 'notasAdicionales', tipo: 'texto', categorias: null },
        { nombre: 'Asunto', campo: 'asunto', tipo: 'texto', categorias: null },
    ];

    // Columnas de tabla
    columnasMensajerias = [
        { prop: 'id', name: 'ID', sortable: true, cellTemplate: null },
        { prop: 'nombreTipo', name: 'Tipo de mensaje', sortable: true, cellTemplate: null },
        { prop: 'meta.origenDestino', name: 'Origen o destino', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuarioEjecutivo', name: 'Ejecutivo', sortable: true, cellTemplate: null },
        { prop: 'nombreUsuarioMensajeria', name: 'Recepción', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    camposMensajerias = [
        { nombre: 'Enviado por', campo: 'emisor', tipo: 'texto', categorias: null },
        { nombre: 'ID', campo: 'id', tipo: 'texto', categorias: null },
        { nombre: 'Descripción', campo: 'emisor', tipo: 'texto', categorias: null },
    ];

    filtrosMapas = [];
    filtrosSolicitudes = [];
    filtrosMensajerias = [];

    // * * * Documentos * * *
    columnasDocumentos = [
        { prop: 'nombre',       name: 'Nombre'          , sortable: true, cellTemplate: null },
        { prop: 'descripcion',  name: 'Descripcion'     , sortable: true, cellTemplate: null },
        { prop: 'link',         name: 'Descarga'        , sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    camposDocumentos = [
        { nombre: 'Nombre'      , campo: 'nombre'       , tipo: 'texto', categorias: null },
        { nombre: 'Descripcion' , campo: 'descripcion'  , tipo: 'texto', categorias: null },
    ];
    filtrosDocumentos = [];
    urlBusquedaDocumentos = this.apiURLs.baseURL + this.apiURLs.documentosPolizasURL + '/search';

    accionesTodas = [
        { id: 'activo', nombre: 'Marcar como activo' },
        { id: 'excluido', nombre: 'Marcar como excluido' },
    ]
    acciones: any[] = [];

    idsSeleccionados: number[];
    fechaBajaClausulaGarantia: any;

    constructor(
        public service: PolizasService,
        public clientesService: ClientesService,
        public usuariosService: UsuariosService,
        public ramosService: RamosService,
        public prospectosService: ProspectosService,
        public aseguradorasService: AseguradorasService,
        public empresasService: EmpresasService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private bottomSheet: MatBottomSheet,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
        private cd: ChangeDetectorRef,
    ) { }

    // * * * * * Inicializar componente y datos * * * * *
    // Inicializar componente
    ngOnInit() {
        this.revision = null;
        this.modoRevision = false;
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        await this.obtenerUsuarios();
        this.obtenerRuta(params);
        this.obtenerTipos();
        this.obtenerRazonesPerdidas();
        this.obtenerCaracteres();
        this.obtenerFrecuenciasPagos();
        this.obtenerFormasDeCobro();
        this.obtenerPagadores();
        await this.obtenerRegistro();
        await this.obtenerMonedas();
        this.obtenerEmpresasRegionales();
        this.obtenerAseguradoras();
        this.obtenerAgentes();
        this.obtenerEjecutivos();
        this.agregarTemplates();
        this.agregarTemplatesDocumentos();
        this.mostrarTabla = true;

        this.route.queryParams.subscribe((params: Params) => {
            this.tabId = params.tab;
            if(this.tabId) {
                this.selectedIndex = this.obtenerIndexTab(this.tabId);
            }
            this.actualizarTabSeleccionada(this.selectedIndex);
        });
    }

    async obtenerUsuarios() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosUsuariosInternos();
        if(!res.error) {
            // Necesitamos meter los usuarios en un array para el filtrado
            // Se tiene que agregar de la forma { id: 1, nombre: 'nombre' }
            if(res.data.registros.length > 0) {
                this.usuarios = res.data.registros.map(usuario => {
                    return { id: usuario.id, nombre: usuario.nombreCompleto };
                });
                this.filtrosClausulasGarantia.push({ nombre: 'Usuario', campo: 'UsuarioId', tipo: 'select-unico', valor: null, mostrar: false, categorias: this.usuarios });
            } else {
                this.usuarios = [];
            }
        } else {
            this.usuarios = [];
        }
        this.cargando = false;
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo';
        this.modoEdicion = ruta === 'editar';
        this.modoDetalle = ruta === 'detalle';

        if((this.modoCreacion || this.modoEdicion) && this.auth.esAccesoACliente()) this.router.navigate(['/404']);

        if(params['id']){
            this.id = parseInt(params['id']);
            if(this.modoEdicion) this.crumbs[1].link = '/polizas/editar/' + this.id;
            if(this.modoDetalle) this.crumbs[1].link = '/polizas/detalle/' + this.id;
        }
        this.acciones = this.accionesTodas;
    }

    agregarTemplates() {
        this.columnasDocumentosCobros.forEach(columna  => {
            columna.cellTemplate = this.cellNormalCobro;
        });
        this.columnasSolicitudes.forEach(columna  => {
            columna.cellTemplate = this.cellNormalSolicitud;
        });
        this.columnasCertificados.forEach(columna  => {
            columna.cellTemplate = this.cellNormalCertificado;
        });
        this.columnasCertificados[4].cellTemplate = this.cellCheckNotNull;
        this.columnasCertificados[5].cellTemplate = this.cellMoneda;
        this.columnasCertificados[6].cellTemplate = this.cellMoneda;
    }

    agregarTemplatesDocumentos() {
        // Cobros
        this.columnasDocumentosCobros.forEach(columna => {
            columna.cellTemplate = this.cellNormalCobro;
        });
        this.columnasDocumentosCobros[7].cellTemplate = this.cellMontoCobro;
        // Créditos
        this.columnasDocumentosCreditos.forEach(columna => {
            columna.cellTemplate = this.cellNormalCredito;
        });
        this.columnasDocumentosCreditos[3].cellTemplate = this.cellMontoCredito;
        this.columnasDocumentosCreditos[4].cellTemplate = this.cellMontoCredito;
        this.columnasDocumentosCreditos[5].cellTemplate = this.cellMontoCredito;

        this.columnasDocumentos.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
        this.columnasDocumentos[this.columnasDocumentos.length - 1].cellTemplate = this.cellDescargarDocumento;

        this.columnasClausulasGarantia[this.columnasClausulasGarantia.length - 6].cellTemplate = this.cellMoneda;
        this.columnasClausulasGarantia[this.columnasClausulasGarantia.length - 3].cellTemplate = this.cellEstadosClausulas;
    }

    // * * * * * Obtener datos * * * * *
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar póliza';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            // Inicializar registro
            this.agentes = [];
            this.ejecutivos = [];
            this.vendedores = [];
            this.clientes = [];
            this.registro = new Poliza(
                -1, null, null, null, null, 'local', false, null, null, null, null, this.apiURLs.horasVigencia[0].nombre, null, null, 'mensual',
                null, true, 'emision', false, false, null, null, null, null, 'pendiente', false, false, false, null, null, null, null, null, null,
                null, null, null, null, this.auth.idUsuarioActual(), null, null, null, null, null, null,
                this.auth.idEmpresaActual(), null, null, null, null, null
            );
            let fechaActual = new Date();
            let numeroPoliza = await this.getNumeroPolizaMes(null,null) +1;
            this.registro.numero = `P-${fechaActual.getFullYear()}-${numeroPoliza}`
            this.registro.numeroEmision = `P-${fechaActual.getFullYear()}-${numeroPoliza}`
            this.crumbs[1].link = '/polizas/nuevo';
        }
        else if(this.modoEdicion || this.modoDetalle) {
            // Obtener registro
            var res = await this.service.obtenerPolizaPorId(this.id, false);
            if(!res.error) {
                this.registro = res.data.registro;

                if(this.auth.esAccesoACliente() && this.registro.AgrupadorId != this.auth.usuario.AgrupadorId) this.router.navigate(['/404']);

                if(this.registro.agente) this.agentes = [ this.registro.agente ];
                if(this.registro.ejecutivo) this.ejecutivos = [ this.registro.ejecutivo ];
                if(this.registro.vendedor) this.vendedores = [ this.registro.vendedor ];
                if(this.registro.cliente) this.clientes = [ this.registro.cliente ];
                //if(this.registro.cobrador) this.cobradores = [ this.registro.cobrador ];
                this.revision = this.registro.revision;
                this.crumbs[1].nombre = this.registro.nombrePoliza;
                // Asignar título
                if(this.modoEdicion) this.titulo = 'Editar Póliza no. ' + this.registro.numero;
                if(this.modoDetalle) this.titulo = 'Detalle de Póliza no. ' + this.registro.numero
                this.titleService.setTitle('Póliza #' + this.registro.numero + this.apiURLs.nombreSitioTitle);
                if(['historica'].includes(this.registro.estado)) this.deshabilitado = true;

                // Intervalo para recargar si está en renovación
                if(this.registro.enProcesoRenovacion) {
                    if(!this.interval) {
                        this.interval = setInterval(() => {
                            this.obtenerRegistro();
                            this.auth.buscadorReload.next();
                        }, 20000);
                    }
                }
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(finalizarRevision: boolean = false){
        this.cargando = true;

        if(this.formularioValido()) {
            // Enviar el registro para guardar
            var res;
            if(this.modoCreacion) {
                res = await this.service.crearPoliza(this.registro);
            }
            else if (this.modoEdicion) {
                var revisionIncluir: RevisionPoliza = null;
                if(this.revision) {
                    this.revision.terminada = finalizarRevision;
                    if(finalizarRevision) this.registro.estadoControlCalidad = 'revisada';
                    if(this.modoRevision && this.revision) revisionIncluir = this.revision;
                }

                res = await this.service.actualizarPoliza(this.registro, revisionIncluir);
            }

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                if(this.modoCreacion) this.router.navigate(['/polizas/editar/' + res.data.result.result.id]);
                this.auth.comprobarAutenticacion(false);
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    abrirFinalizarRevision() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Finalizar revisión',
                mensaje: '¿Está seguro de que desea finalizar la revisión de la póliza?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.guardar(true);
            }
        });
    }

    abrirMarcarPolizaCorregida() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar póliza como corregida',
                mensaje: '¿Está seguro de que desea marcar esta póliza como corregida?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.marcarPolizaCorregida();
            }
        });
    }

    async marcarPolizaCorregida(){
        this.cargando = true;

        var res = await this.service.marcarPolizaCorregida(this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }

        this.cargando = false;
    }

    // Eliminar datos
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar póliza',
                mensaje: '¿Está seguro de que desea eliminar esta póliza?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarPoliza(this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.auth.comprobarAutenticacion(false);
            this.router.navigate(['/polizas']);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * Renovar póliza * * *
    abrirRenovar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacionOpciones, {
            data: {
                titulo: 'Renovar póliza',
                mensaje: '¿Está seguro de que desea renovar esta póliza? Se creará un duplicado con los mismos datos y certificados.',
                opciones: [
                    { id: 'misma-aseguradora', nombre: 'Renovar en misma aseguradora' },
                    { id: 'cambio-aseguradora', nombre: 'Renovar cambiando de aseguradora' },
                ]
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.renovarPoliza(result == 'misma-aseguradora');
            }
        });
    }

    async renovarPoliza(mismaAseguradora: boolean){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.renovarPoliza(this.id, mismaAseguradora);
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            window.open('/polizas/editar/' + res.data.result.NuevaPolizaId, '_self');
            // this.router.navigate(['/polizas/editar/' + res.data.result.NuevaPolizaId]);
            // this.ngOnInit();
            // this.auth.buscadorReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }

        this.cargando = false;
    }

    // * * * Rehabilitar póliza * * *
    abrirRehabilitar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Rehabilitar póliza',
                mensaje: '¿Está seguro de que desea rehabilitar esta póliza? Regresará a su último estado previo a ser cancelada.',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                let cambios = this.registro.cambiosEstado.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime()).filter(x => x.estadoSiguiente == 'cancelada');
                if(cambios && cambios.length > 0) {
                    if(!cambios[0].estadoAnterior) this.registro.estado = 'nueva';
                    else this.registro.estado = cambios[0].estadoAnterior;
                }
                else {
                    if(!this.historicas || this.historicas.length <= 1) this.registro.estado = 'nueva';
                    else this.registro.estado = 'renovada'
                }
                this.guardar();
                // this.registro.estado = result;
                // this.guardar();
            }
        });
    }

    // * * * Marcar como vigente * * *
    abrirMarcarVigente() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar póliza vigente',
                mensaje: '¿Está seguro de que desea marcar esta póliza como vigente? Las otras pólizas relacionadas se marcarán como históricas.',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.marcarVigentePoliza();
            }
        });
    }

    async marcarVigentePoliza(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.marcarVigentePoliza(this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Validaciones * * * * *
    formularioValido() {
        if(!this.registro) return false;

        if(this.registro.enProcesoRenovacion) {
            this.mensajeError = 'No puede modificar una póliza que esté en proceso de renovación. Espere unos minutos.';
            return false;
        }

        if(this.deshabilitado) {
            this.mensajeError = 'No puede modificar una póliza histórica.';
            return false;
        }

        if(!this.registro.AseguradoraId) {
            this.mensajeError = 'Debe asignar una aseguradora a esta póliza.';
            return false;
        }

        if(!this.registro.RamoId) {
            this.mensajeError = 'Debe asignar un ramo a esta póliza.';
            return false;
        }

        if(!this.registro.ProductoId) {
            this.mensajeError = 'Debe asignar un producto a esta póliza.';
            return false;
        }

        if(!this.registro.ClienteId) {
            this.mensajeError = 'Debe asignar un cliente a esta póliza.';
            return false;
        }

        if(!this.registro.AgenteId) {
            this.mensajeError = 'Debe asignar un agente a esta póliza.';
        }

        if(!this.registro.EjecutivoId) {
            this.mensajeError = 'Debe asignar un ejecutivo a esta póliza.';
        }

        if((!this.registro.pagadores || this.registro.pagadores.length == 0) && !this.modoCreacion) {
            this.mensajeError = 'Debe incluir al menos un pagador para poder guardar este certificado.';
            return false;
        }
        else this.mensajeError = null;

        /*
        if(!this.validarPagadores()) {
            return false;
        }
        */

        if(!this.validarCoaseguros()) {
            this.mensajeError = 'Los porcentajes por aseguradora de las pólizas Coaseguro deben sumar el 100%.';
            return false;
        }
        else this.mensajeError = null;

        this.mensajeError = null;
        return true;
    }

    validarCoaseguros() {
        // Validar coaseguros
        if(this.registro.tipo == 'coaseguros') {
            var totalPorcentaje = 0;
            if(this.registro.porcentajesCoaseguros) {
                for (let i = 0; i < this.registro.porcentajesCoaseguros.length; i++) {
                    const element = this.registro.porcentajesCoaseguros[i];
                    totalPorcentaje += element.porcentaje;
                }
            }
            if(totalPorcentaje != 100) return false;
        }
        return true;
    }

    // * * * * * Usuarios * * * * *
    actualizarEjecutivo(items: Usuario[]) {
        var EjecutivoId = null;
        if(items && items.length > 0) {
            EjecutivoId = items[0].id;
        }
        this.registro.EjecutivoId = EjecutivoId;
    }

    actualizarAgente(items: Usuario[]) {
        var AgenteId = null;
        if(items && items.length > 0) {
            AgenteId = items[0].id;
        }
        this.registro.AgenteId = AgenteId;
    }

    actualizarVendedor(items: Usuario[]) {
        var VendedorId = null;
        if(items && items.length > 0) {
            VendedorId = items[0].id;
        }
        this.registro.VendedorId = VendedorId;
    }

    actualizarCliente(items: Usuario[]) {
        var ClienteId = null;
        if(items && items.length > 0) {
            ClienteId = items[0].id;
            if(items[0].agentesIds && items[0].agentesIds.length > 0) this.registro.AgenteId = items[0].agentesIds[0];
            if(items[0].ejecutivosIds && items[0].ejecutivosIds.length > 0) this.registro.EjecutivoId = items[0].ejecutivosIds[0];
        }
        this.registro.ClienteId = ClienteId;
    }

    actualizarCobrador(items: Cobrador[]) {
        var CobradorId = null;
        if(items && items.length > 0) {
            CobradorId = items[0].id;
        }
        this.registro.CobradorId = CobradorId;
    }

    // * * * * * Relaciones * * * * *
    async obtenerTipos() {
        this.cargando = true;
        var res = await this.service.obtenerTodosTiposPolizas();
        if(!res.error) {
            this.tipos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerCaracteres() {
        this.cargando = true;
        var res = await this.service.obtenerTodosCaracteresPolizas();
        if(!res.error) {
            this.caracteres = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerFrecuenciasPagos() {
        this.cargando = true;
        var res = await this.service.obtenerTodosFrecuenciasPagos();
        if(!res.error) {
            this.frecuenciasPagos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerFormasDeCobro() {
        this.cargando = true;
        if(this.modoCreacion) this.formasDeCobro = this.apiURLs.formasCobroCreacion;
        else this.formasDeCobro = this.apiURLs.formasCobro;
        this.cargando = false;
    }

    async obtenerRamos() {
        this.cargando = true;
        if(this.registro && this.registro.AseguradoraId && this.aseguradoras) {
            var index = this.libraryService.indexOf(this.aseguradoras, 'id', this.registro.AseguradoraId);
            var aseguradora = this.aseguradoras[index];
            this.registro.nombreAseguradora = aseguradora.nombre;
            this.ramos = aseguradora.ramos;
            // Asignar ramo por defecto
            if(this.registro.RamoId) {
                var indexRamoActual = this.libraryService.indexOf(this.ramos, 'id', this.registro.RamoId);
                if(indexRamoActual == -1 && this.ramos.length > 0) this.registro.RamoId = this.ramos[0].id;
            }
            this.ramos = this.ramos.sort((a, b) => {
                if(a.nombre < b.nombre) return -1;
                if(a.nombre > b.nombre) return 1;
                return 0;
            });
            //else if(this.ramos.length > 0 && !this.registro.RamoId) this.registro.RamoId = this.ramos[0].id;
            this.obtenerProductosRamo();
        }
        this.cargando = false;
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;

            // Filtrar ramos inactivos
            if(this.modoCreacion) {
                for (let aseguradora of this.aseguradoras) {
                    aseguradora.ramos = aseguradora.ramos.filter(x => x.activo);
                }
            }

            this.obtenerRamos();
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerEmpresasRegionales() {
        this.cargando = true;
        var res = await this.empresasService.obtenerTodasEmpresasRegionales();
        if(!res.error) {
            this.empresasRegionales = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
            if(!this.registro.MonedaId) this.registro.MonedaId = this.auth.idMonedaPorDefecto();
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerProductosRamo() {
        this.cargando = true;
        if(this.registro && this.registro.RamoId) {
            var index = this.libraryService.indexOf(this.ramos, 'id', this.registro.RamoId);
            if(index != -1) {
                var ramo = this.ramos[index];
                this.registro.nombreRamo = ramo.nombre;
                this.registro.ramo = ramo;
                this.actualizarHorasVigencia();
            }
            var res = await this.ramosService.obtenerTodosProductosRamo(this.registro.RamoId, this.registro.AseguradoraId);
            if(!res.error) {
                this.productos = res.data.registros;
                this.productos = this.productos.sort((a, b) => {
                    if(a.nombre < b.nombre) return -1;
                    if(a.nombre > b.nombre) return 1;
                    return 0;
                });
                /*
                if(this.productos && this.productos.length > 0) {
                    if(this.modoCreacion || this.libraryService.indexOf(this.productos, 'id', this.registro.ProductoId) == -1) {
                        this.registro.ProductoId = this.productos[0].id;
                    }
                }
                */
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async actualizarProducto() {
        this.cargando = true;
        if(this.registro && this.registro.ProductoId) {
            var index = this.libraryService.indexOf(this.productos, 'id', this.registro.ProductoId);
            if(index != -1) {
                var producto = this.productos[index];
                this.registro.nombreProducto = producto.nombre;
            }
        }
        this.cargando = false;
    }

    // * * * Fechas * * *
    actualizarFechaResguardo($event){
        if($event && $event.value) {
            this.registro.fechaResguardo = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaFinTerminos($event){
        if($event && $event.value) {
            this.registro.fechaFinTerminos = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaVigenciaFin($event){
        if($event && $event.value) {
            this.registro.fechaVigenciaFin = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    actualizarFechaVigenciaInicio($event){
        if($event && $event.value) {
            this.registro.fechaVigenciaInicio = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');

            if(this.modoCreacion) {
                // Asignar automáticamente fecha de vigencia fin
                let fechaEnUnAnio = moment(this.registro.fechaVigenciaInicio).add(1, 'year');
                if(this.registro.horasVigencia == '0-23') fechaEnUnAnio.subtract(1, 'day');
                this.registro.fechaVigenciaFin = fechaEnUnAnio.format('YYYY-MM-DD');
                this.registro.fechaVigenciaFinDate = fechaEnUnAnio.toDate();
            }
        }
    }

    actualizarFechaBajaClausulaGarantia(fecha){
        this.fechaBajaClausulaGarantia = this.libraryService.convertirFecha(fecha.toDate(), 'date', 'YYYY-MM-DD');
    }

    // * * * * * Porcentajes * * * * *
    agregarPorcentaje(){
        if(!this.registro.porcentajesCoaseguros) this.registro.porcentajesCoaseguros = [];
        var porcentaje = new PorcentajeCoaseguros((this.registro.porcentajesCoaseguros.length + 1) * -1, null, null, null, this.id);
        if(this.aseguradoras && this.aseguradoras.length > 0) {
            porcentaje.AseguradoraId = this.aseguradoras[0].id;
        }

        this.registro.porcentajesCoaseguros.push(porcentaje);
        this.ordenarIdsPorcentajesCoaseguros();
    }

    eliminarPorcentaje(id){
        for (let i = 0; i < this.registro.porcentajesCoaseguros.length; i++) {
            const element = this.registro.porcentajesCoaseguros[i];
            if(element.id == id){
                this.registro.porcentajesCoaseguros.splice(i, 1);
                i = this.registro.porcentajesCoaseguros.length;
            }
        }
        this.ordenarIdsPorcentajesCoaseguros();
    }

    ordenarIdsPorcentajesCoaseguros(){
        for (let i = 0; i < this.registro.porcentajesCoaseguros.length; i++) {
            if(this.registro.porcentajesCoaseguros[i].id < 0) {
                this.registro.porcentajesCoaseguros[i].id = -1 * (i + 1);
            }
        }
    }

    // * * * Pagadores * * *
    abrirNuevoPagador() {
        var cliente = new Usuario(-1, null, null, null, null, null, null, null, null, null, null, true, false, true, false, false, false, false, false, false, null, null, null, null, null, null, null);
        cliente.informacionCliente = new InformacionCliente(-1, 'individual', null, null, true, null, null, null, false, null, null, null, null, null, null, null, null, null, null, null, null, null, null, false, true, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);

        const dialogRef = this.dialog.open(DialogNuevoCliente, {
            data: {
                cliente: cliente,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.guardarPagador(result);
            }
        });
    }

    async guardarPagador(nuevoCliente: Usuario) {
        this.cargandoPagador = true;
        if(nuevoCliente && nuevoCliente.nombre) {
            // Enviar el registro para guardar
            var res = await this.clientesService.crearCliente(nuevoCliente);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                var pagador = this.service.jsonConverters.usuarioDeJSON(res.data.result.result);
                var PagadorId = pagador.id;

                if(!this.registro.pagadores) this.registro.pagadores = [];
                var nuevoPagador = new PagadorPoliza((this.registro.pagadores.length + 1) * -1, null, null, null, this.id);
                nuevoPagador.ClienteId = PagadorId;
                nuevoPagador.clientes = [pagador];
                this.registro.pagadores.push(nuevoPagador);
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            nuevoCliente = null;
        }
        this.cargandoPagador = false;
    }

    actualizarClientePagador(items: Usuario[], pagador: PagadorPoliza, indexPagador: number) {
        var ClienteId = null, cliente = null;
        if(items && items.length > 0) {
            ClienteId = items[0].id;
            cliente = items[0];
        }

        // Validar que no exista este pagador en otra línea
        if(this.registro.pagadores) {
            let indexAux = 0;
            for (const el of this.registro.pagadores) {
                if(el.ClienteId == ClienteId && indexAux != indexPagador) {
                    this.libraryService.crearNotificacion('El cliente ya está agregado como pagador', 'danger');
                    this.registro.pagadores.splice(indexPagador, 1);
                    return;
                }
                indexAux++;
            }
        }

        pagador.ClienteId = ClienteId;
        pagador.cliente = cliente;
    }

    validarPagadores() {
        var totalPorcentaje = 0;
        if(this.registro.pagadores) {
            for (let i = 0; i < this.registro.pagadores.length; i++) {
                const element = this.registro.pagadores[i];
                if(element.porcentaje == null) return false;
                totalPorcentaje += element.porcentaje;
            }
        }
        if(totalPorcentaje != 100) return false;

        return true;
    }

    agregarPagador(){
        if(!this.registro.pagadores) this.registro.pagadores = [];
        var pagador = new PagadorPoliza((this.registro.pagadores.length + 1) * -1, null, null, null, this.id);
        if(this.registro.ClienteId) {
            pagador.ClienteId = this.registro.ClienteId;
        }

        this.registro.pagadores.push(pagador);
        this.ordenarIdsPagadores();
    }



    eliminarPagador(id){
        for (let i = 0; i < this.registro.pagadores.length; i++) {
            const element = this.registro.pagadores[i];
            if(element.id == id){
                this.registro.pagadores.splice(i, 1);
                i = this.registro.pagadores.length;
            }
        }
        this.ordenarIdsPagadores();
    }

    ordenarIdsPagadores(){
        for (let i = 0; i < this.registro.pagadores.length; i++) {
            if(this.registro.pagadores[i].id < 0) {
                this.registro.pagadores[i].id = -1 * (i + 1);
            }
        }
    }

    // * * * * * Certificados * * * * *
    calcularParamsEspecialesCertificados(): string {
        var paramsEspeciales = '';
        if(this.id) paramsEspeciales += '&PolizaId=' + this.id;
        if(this.auth.esAccesoACliente()) paramsEspeciales += '&esAccesoACliente=1';

        return paramsEspeciales;
    }

    calcularParamsEspecialesPolizas(): string {
        var paramsEspeciales = '';
        if(this.registro.PolizaVigenteId) paramsEspeciales += '&polizaVigente=' + this.registro.PolizaVigenteId;
        else paramsEspeciales += '&polizaVigente=' + this.id;
        return paramsEspeciales;
    }

    calcularParamsEspecialesCobros(): string {
        var paramsEspeciales = '';

        paramsEspeciales += '&PolizaId=' + this.id;
        paramsEspeciales += '&orderBy=poliza'

        return paramsEspeciales;
    }

    calcularParamsEspecialesCreditos(): string {
        var paramsEspeciales = '';

        paramsEspeciales += '&PolizaId=' + this.id;

        return paramsEspeciales;
    }

    // * * * Mapas de proceso * * *
    calcularParamsEspecialesMapasCobros(): string {
        var paramsEspeciales = '';

        paramsEspeciales += '&PolizaId=' + this.id;

        return paramsEspeciales;
    }

    calcularParamsEspecialesMensajerias(): string {
        var paramsEspeciales = '';

        paramsEspeciales += '&PolizaId=' + this.id;

        return paramsEspeciales;
    }

    abrirNuevoMapaProceso() {
        const dialogRef = this.dialog.open(DialogNuevoMapaProceso, {
            data: {
                PolizaId: this.id,
                poliza: this.registro,
            }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    // * * * * * Documentos * * * * *
    abrirSubirMultiplesDocumento() {
        const dialogRef = this.dialog.open(DialogAgregarMultiplesDocumentosPolizaComponent, {
            data: {
                modo: 'creacion'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result && result.length > 0) {
                for (const el of result) {
                    let doc = new DocumentoPoliza(null, el.nombre, null, el.extension, el.link, null, this.id, null, null, this.auth.idEmpresaActual());
                    await this.guardarDocumentoPoliza({registro: doc});
                }
            }
        });
    }

    abrirSubirDocumento() {
        var document = new DocumentoPoliza(-1, null, null, null, null, null, this.id, null, null, this.auth.idEmpresaActual());
        const dialogRef = this.dialog.open(DialogAgregarDocumentoPolizaComponent, {
            data: {
                registro: document,
                modo: 'creacion'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.guardarDocumentoPoliza(result);
            }
        });
    }

    async guardarDocumentoPoliza(documento: any) {
        this.cargando = true;
        var res = await this.service.guardarDocumentoPoliza(documento);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.auth.buscadorReload.next();
        this.cargando = false;
    }

    async editarDocumentoPoliza(documento: DocumentoPoliza) {
        this.cargando = true;
        var res = await this.service.editarDocumentoPoliza(documento);
        if (!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.auth.buscadorReload.next();
        this.cargando = false;
    }

    abrirEditarDocumento($event) {
        const dialogRef = this.dialog.open(DialogAgregarDocumentoPolizaComponent, {
            data: {
                registro: $event,
                modo: 'detalle'
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.editarDocumentoPoliza(result);
            }
        });
    }

    async descargarDocumento(url: string) {
        this.cargando = true;
        if (url) {
            await window.open(url, "_blank");
        }
        this.cargando = false;
    }

    paramsEspecialesDocumentos() {
        var params = '';
        if(this.registro.id) params += '&PolizaId=' + this.registro.id;
        return params;
    }

    // * * * Control de calidad * * *
    iniciarRevision() {
        if(this.modoEdicion && this.auth.tienePermisoPara(this.apiURLs.modulos.CONTROL_CALIDAD.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
            // Revisar si la revisión ya está iniciada, si no crear una nueva
            if(!this.revision) {
                this.revision = new RevisionPoliza(null, null, null, null, null, null, false, false, null, this.id, this.auth.idUsuarioActual(), this.auth.idEmpresaActual());
                // Crear campos de revisión
                this.revision.correcciones = [];
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'numero', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreAseguradora', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreRamo', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreProducto', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreCliente', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreCobrador', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreAgente', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreEjecutivo', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreVendedor', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreTipo', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreEstado', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreCaracter', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreMoneda', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'sumaAsegurada', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'primaNeta', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreFormaCobro', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'numeroPagos', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'nombreFrecuenciaPago', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'fechaResguardo', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'fechaVigenciaInicio', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'fechaVigenciaFin', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
                this.revision.correcciones.push(new CorreccionControlCalidad(null, 'horasVigencia', null, null, null, false, this.id, null, null, this.auth.idEmpresaActual()));
            }
            this.modoRevision = true;
        }
    }

    actualizarCampo(data) {
        if(data && this.revision) {
            var campo = data.campo;
            var index = this.libraryService.indexOf(this.revision.correcciones, 'campo', campo);
            if(index != -1) {
                this.revision.correcciones[index].correcto = data.correcto;
                this.revision.correcciones[index].valorAnterior = data.valorInicial;
                this.revision.correcciones[index].valorCorregido = data.valorActual;
            }
        }
    }

    correctoDeCampo(campo: string) {
        var index = this.libraryService.indexOf(this.revision.correcciones, 'campo', campo);
        if(index != -1) {
            return this.revision.correcciones[index].correcto;
        }
        return false;
    }

    // * * * Solicitudes * * *
    abrirDialogNuevaSolicitud(tipoUso: string) {
        var solicitud = new SolicitudPoliza(-1, null, null, null, null, null, null, null, null, null, null, null, false, null, null, false, null, this.auth.idUsuarioActual(), this.id, null, null, null, null, this.auth.idEmpresaActual(), null);
        solicitud.estado = 'activo';

        const dialogRef = this.dialog.open(DialogNuevaSolicitud, {
            data: {
                solicitud: solicitud,
                tipoUso: tipoUso,
                modulo: 'polizas',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.guardarDescargarSolicitud(result);
            }
        });
    }

    abrirDialogAplicarPlantilla() {
        const dialogRef = this.dialog.open(DialogAplicarPlantillaCertificados, {
            data: {
                poliza: this.registro,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.auth.buscadorReload.next();
            }
        });
    }

    abrirDialogDescargarFacturaciones() {
        const dialogRef = this.dialog.open(DialogDescargaFacturacionesComponent, {
            data: {
                poliza: this.registro,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {

            }
        });
    }

    abrirDialogDescargarPlantillaCertificados() {
        const dialogRef = this.dialog.open(DialogDescargarPlantillaCertificados, {
            data: {
                poliza: this.registro,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {

            }
        });
    }

    abrirDialogEditarSolicitud(solicitud: SolicitudPoliza) {
        const dialogRef = this.dialog.open(DialogNuevaSolicitud, {
            data: {
                solicitud: solicitud,
                modulo: 'polizas',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.guardarDescargarSolicitud(result);
            }
        });
    }

    async guardarDescargarSolicitud(solicitud: SolicitudPoliza) {
        this.cargando = true;
        if (solicitud) {
            // Enviar el registro para guardar
            var res = await this.service.crearSolicitudPoliza(this.id, solicitud);

            if (!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
                solicitud.id = res.data.result.solicitud.id;
                this.cargando = false;
                //this.descargarPdfSolicitud(solicitud);
            } else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
                this.cargando = false;
            }
        }
        else this.cargando = false;
    }

    async descargarPdfSolicitud(solicitud: SolicitudPoliza) {
        this.cargando = true;
        if (solicitud) {
            const nombreArchivo = `Solicitud #${solicitud.id} - ${solicitud.plantilla.nombre}`;
            var resArchivo = await this.service.descargarPdfSolicitud(solicitud.id, nombreArchivo);
            if (resArchivo.error) {
                this.libraryService.crearNotificacion(resArchivo.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    calcularParamsEspecialesSolicitudes(): string {
        var paramsEspeciales = '';
        if(this.id) paramsEspeciales += '&PolizaId=' + this.id;
        paramsEspeciales += '&tipoUso=solicitud'
        return paramsEspeciales;
    }

    calcularParamsEspecialesMemos(): string {
        var paramsEspeciales = '';
        if(this.id) paramsEspeciales += '&PolizaId=' + this.id;
        paramsEspeciales += '&tipoUso=memo'
        return paramsEspeciales;
    }

    tabTieneErrores(tab: string) {
        switch(tab) {
            case 'Datos de póliza': {
                if(
                    !this.registro.AseguradoraId ||
                    !this.registro.RamoId ||
                    !this.registro.ProductoId
                ) return true;
                break;
            }
        }
        return false;
    }

    abrirCertificado(CertificadoId: number) {
        if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
            this.router.navigate(['/polizas/editar-certificado/' + CertificadoId]);
        }
        else {
            this.router.navigate(['/polizas/detalle-certificado/' + CertificadoId]);
        }
    }

    getColorEstado(){
        let etapas = {
            'emision': 'blue',
            'nueva' : 'green',
            'traslado' : 'blue',
            'renovada' : 'green',
            'historica' : 'gray',
            'cancelada': 'red',
        }
        return etapas[this.registro.estado] ? etapas[this.registro.estado] : '#fcdf03';
    }

    async obtenerAgentes() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosAgentes();
        if(!res.error) {
            this.agentes = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerEjecutivos() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosEjecutivos();
        if(!res.error) {
            this.ejecutivos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    actualizarHorasVigencia() {
        if(this.modoCreacion) {
            if(this.service.esPersonas(this.registro)) {
                this.registro.horasVigencia = '0-23';
            }
            else {
                this.registro.horasVigencia = '11-12';
            }
            this.cd.detectChanges();
        }
    }

    async obtenerRazonesPerdidas() {
        this.cargando = true;
        var res = await this.prospectosService.obtenerTodasRazonesPerdidas();
        if(!res.error) {
            this.razonesPerdidas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    abrirCancelar() {
        const dialogRef = this.dialog.open(DialogCancelarPolizaComponent, {
            data: {
                registro: this.registro,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.cancelarPoliza(result.fechaVigenciaFin, result.RazonPerdidaId);
            }
        });
    }

    async cancelarPoliza(fechaVigenciaFin: string, RazonPerdidaId: number){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.cancelarPoliza(this.id, fechaVigenciaFin, RazonPerdidaId);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async getNumeroPolizaMes(mes, anio){
        this.cargando = true;
        var res = await this.service.obtenerNumeroPolizaMes(mes, anio);
        if(!res.error) {
            return res.data.result;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    abrirDialogCertificadosPagador(pagador: PagadorCertificado) {
        const dialogRef = this.dialog.open(DialogCertificadosPagador, {
            data: {
                poliza: this.registro,
                pagador: pagador.cliente,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {

            }
        });
    }

    abrirDuplicarCertificados(ids: number[]) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Duplicar certificados',
                mensaje: '¿Está seguro de que desea duplicar el o los certificados seleccionados?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.duplicarCertificados(ids);
            }
        });
    }

    abrirDuplicar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Duplicar póliza',
                mensaje: '¿Está seguro de que desea duplicar esta póliza? Se copiarán todos sus datos a una nueva póliza.',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.duplicar();
            }
        });
    }

    async duplicar(){
        this.cargando = true;

        // Enviar el registro para guardar
        let res = await this.service.duplicarPoliza(this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/polizas/editar/' + res.data.result.result.id]);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }

        this.cargando = false;
    }

    // * * * Carga masiva beneficiarios y dependientes * * *
    async descargarPlantillaBeneficiariosDependientes(tipo: string){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlantillaBeneficiariosDependientes('Plantilla de ' + tipo + '.xlsx', tipo, this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    seleccionarArchivoBeneficiarios(event: any, tipo: string){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoBeneficiarios = file.name;
            this.archivoBeneficiarios = file;
            this.analizarArchivoExcelBeneficiarios(tipo);
        }
    }

    async analizarArchivoExcelBeneficiarios(tipo: string) {
        if(this.archivoBeneficiarios) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionBeneficiariosPlantilla(this.archivoBeneficiarios, tipo, this.id, null, this.auth.idEmpresaActual());
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                this.cargando = false;
            }
            else {
                let registrosBeneficiarios = resLectura.resultados;
                this.subirRegistrosBeneficiarios(registrosBeneficiarios, tipo);
            }
        }
    }

    async subirRegistrosBeneficiarios(registros: BeneficiarioDependienteCertificado[], tipo: string){
        try {
            this.cargando = true;
            if(!registros || registros.length == 0) throw new Error('No hay datos para subir.');

            // Enviar el registro para guardar
            var res = await this.service.subirImportacionBeneficiariosPlantilla(registros, null, tipo, this.auth.idEmpresaActual(), this.id, true);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.obtenerRegistro();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacion(error.message, 'danger');
        }
    }

    activarSeleccionArchivoBeneficiarios(tipo: string){
        this.limpiarArchivoBeneficiarios();
        document.getElementById('file-input-archivo-' + tipo).click();
    }

    limpiarArchivoBeneficiarios(){
        this.rutaArchivoBeneficiarios = null;
        this.archivoBeneficiarios = null;
    }

    // * * * Carga masiva ubicaciones de riesgo * * *
    async descargarPlantillaUbicaciones(){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlantillaUbicaciones('Plantilla de ubicaciones.xlsx', this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    seleccionarArchivoUbicaciones(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoUbicaciones = file.name;
            this.archivoUbicaciones = file;
            this.analizarArchivoExcelUbicaciones();
        }
    }

    async analizarArchivoExcelUbicaciones() {
        if(this.archivoUbicaciones) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionUbicacionesPlantilla(this.archivoUbicaciones, this.id, null, this.auth.idEmpresaActual());
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                this.cargando = false;
            }
            else {
                let registrosUbicaciones = resLectura.resultados;
                this.subirRegistrosUbicaciones(registrosUbicaciones);
            }
        }
    }

    async subirRegistrosUbicaciones(registros: DireccionCertificado[], ){
        try {
            this.cargando = true;
            if(!registros || registros.length == 0) throw new Error('No hay datos para subir.');

            // Enviar el registro para guardar
            var res = await this.service.subirImportacionUbicacionesPlantilla(registros, null, this.auth.idEmpresaActual(), this.id, true);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.obtenerRegistro();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacion(error.message, 'danger');
        }
    }

    activarSeleccionArchivoUbicaciones(){
        this.limpiarArchivoUbicaciones();
        document.getElementById('file-input-archivo-ubicaciones').click();
    }

    limpiarArchivoUbicaciones(){
        this.rutaArchivoUbicaciones = null;
        this.archivoUbicaciones = null;
    }

    // * * * Carga masiva declaraciones * * *
    async descargarPlantillaDeclaraciones(){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlantillaDeclaraciones('Plantilla de declaraciones.xlsx', this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    seleccionarArchivoDeclaraciones(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoDeclaraciones = file.name;
            this.archivoDeclaraciones = file;
            this.analizarArchivoExcelDeclaraciones();
        }
    }

    async analizarArchivoExcelDeclaraciones() {
        if(this.archivoDeclaraciones) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionDeclaracionesPlantilla(this.archivoDeclaraciones, this.id, this.auth.idEmpresaActual());
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                this.cargando = false;
            }
            else {
                let registros = resLectura.resultados;
                this.subirRegistrosDeclaraciones(registros);
            }
        }
    }

    async subirRegistrosDeclaraciones(registros: Declaracion[]){
        try {
            this.cargando = true;
            if(!registros || registros.length == 0) throw new Error('No hay datos para subir.');

            // Enviar el registro para guardar
            var res = await this.service.subirImportacionDeclaracionesPlantilla(registros, this.auth.idEmpresaActual(), this.id);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.limpiarArchivoDeclaraciones();
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacion(error.message, 'danger');
        }
    }

    activarSeleccionArchivoDeclaraciones(){
        this.limpiarArchivoDeclaraciones();
        document.getElementById('file-input-archivo-declaraciones').click();
    }

    limpiarArchivoDeclaraciones(){
        this.rutaArchivoDeclaraciones = null;
        this.archivoDeclaraciones = null;
    }

    // * * * Exclusión masiva * * *
    async descargarPlantillaExclusionMasiva(){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlantillaExclusionMasiva('Plantilla de exclusión masiva.xlsx', this.id);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    seleccionarArchivoExclusionMasiva(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoExclusionMasiva = file.name;
            this.archivoExclusionMasiva = file;
            this.analizarArchivoExcelExclusionMasiva();
        }
    }

    async analizarArchivoExcelExclusionMasiva() {
        if(this.archivoExclusionMasiva) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionExclusionMasivaPlantilla(this.archivoExclusionMasiva, this.id, this.auth.idEmpresaActual());
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                this.cargando = false;
            }
            else {
                let registros = resLectura.resultados;
                this.subirRegistrosExclusionMasiva(registros);
            }
        }
    }

    async subirRegistrosExclusionMasiva(registros: any[]){
        try {
            this.cargando = true;
            if(!registros || registros.length == 0) throw new Error('No hay datos para subir.');

            // Enviar el registro para guardar
            var res = await this.service.subirImportacionExclusionMasivaPlantilla(registros, this.auth.idEmpresaActual(), this.id);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
            this.limpiarArchivoExclusionMasiva();
            this.cargando = false;
        } catch(error) {
            this.cargando = false;
            this.libraryService.crearNotificacion(error.message, 'danger');
        }
    }

    activarSeleccionArchivoExclusionMasiva(){
        this.limpiarArchivoExclusionMasiva();
        document.getElementById('file-input-archivo-exclusion-masiva').click();
    }

    limpiarArchivoExclusionMasiva(){
        this.rutaArchivoExclusionMasiva = null;
        this.archivoExclusionMasiva = null;
    }

    openBottomSheet(): void {
        this.bottomSheet.open(BottomSheetPolizaComponent).afterDismissed().subscribe(($event) => {
            switch($event) {
                case 'plantilla-certificados': {
                    this.abrirDialogDescargarPlantillaCertificados();
                    break;
                }
                case 'importar-certificados': {
                    this.router.navigate(['/polizas/importacion-certificados-plantilla/' + this.id]);
                    break;
                }
                case 'plantilla-ubicaciones': {
                    this.descargarPlantillaUbicaciones();
                    break;
                }
                case 'importar-ubicaciones': {
                    this.activarSeleccionArchivoUbicaciones();
                    break;
                }
                case 'plantilla-beneficiarios': {
                    this.descargarPlantillaBeneficiariosDependientes('beneficiario');
                    break;
                }
                case 'importar-beneficiarios': {
                    this.activarSeleccionArchivoBeneficiarios('beneficiario');
                    break;
                }
                case 'plantilla-dependientes': {
                    this.descargarPlantillaBeneficiariosDependientes('dependiente');
                    break;
                }
                case 'importar-dependientes': {
                    this.activarSeleccionArchivoBeneficiarios('dependiente');
                    break;
                }
                case 'plantilla-declaraciones': {
                    this.descargarPlantillaDeclaraciones();
                    break;
                }
                case 'importar-declaraciones': {
                    this.activarSeleccionArchivoDeclaraciones();
                    break;
                }
                case 'plantilla-exclusion-masiva': {
                    this.descargarPlantillaExclusionMasiva();
                    break;
                }
                case 'importar-exclusion-masiva': {
                    this.activarSeleccionArchivoExclusionMasiva();
                    break;
                }
                case 'descargar-facturaciones': {
                    this.abrirDialogDescargarFacturaciones();
                    break;
                }
            }
        });;
    }

    // Eliminar datos
    abrirEliminarCertificado(ids: number[]) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar certificados',
                mensaje: '¿Está seguro de que desea eliminar el o los certificados seleccionados?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminarCertificados(ids);
            }
        });
    }

    async eliminarCertificados(ids: number[]){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarCertificadosMultiples(ids);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.auth.buscadorReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async duplicarCertificados(ids: number[]){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.duplicarCertificadosMultiples(ids);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.auth.buscadorReload.next();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    aplicarAccionCertificados($event) {
        if($event && !this.cargando) {
            var accion = $event.accion;
            var seleccionados = $event.seleccionados;
            var ids = $event.ids;
            switch(accion.id) {
                case 'eliminar': {
                    if(!this.estaEnEmision()) {
                        this.libraryService.crearNotificacion('No se pueden eliminar certificados de una póliza que no está emisión.', 'danger');
                        return;
                    }
                    else {
                        this.abrirEliminarCertificado(ids);
                    }
                    break;
                }
                case 'duplicar': {
                    this.abrirDuplicarCertificados(ids);
                    break;
                }
            }
        }
    }

    estaEnEmision() {
        if(this.apiURLs.LOCAL_DEV) return true;
        if(!this.registro) return false;
        if(this.modoCreacion || ['emision'].includes(this.registro.estado)) return true;
        return false;
    }

    // * Tabs *
    obtenerTabs() {
        let tabs = [];
        tabs.push('datos-poliza');
        if(!this.modoCreacion) tabs.push('certificados');
        if(!this.modoCreacion) tabs.push('cobros');
        if(!this.modoCreacion && !this.service.esPersonas(this.registro)) tabs.push('aclaraciones');
        if(!this.modoCreacion && this.service.esBienes(this.registro)) tabs.push('clausulas-garantia');
        if(!this.modoCreacion && this.service.esPersonas(this.registro)) tabs.push('pago-directo');
        if(!this.modoCreacion) tabs.push('creditos');
        if(this.registro && this.registro.tipo == 'coaseguros') tabs.push('coaseguros');
        if(!this.modoCreacion && !this.auth.esAccesoACliente()) tabs.push('solicitudes');
        if(!this.modoCreacion && !this.auth.esAccesoACliente()) tabs.push('memos');
        if(!this.modoCreacion && !this.auth.esAccesoACliente()) tabs.push('mensajeria');
        if(!this.modoCreacion && !this.auth.esAccesoACliente()) tabs.push('documentos');
        if(!this.auth.esAccesoACliente()) tabs.push('pagadores');
        if(!this.modoCreacion && !this.auth.esAccesoACliente()) tabs.push('historico');

        return tabs;
    }

    obtenerIndexTab(id: string) {
        if(!id) return null;
        let tabs = this.obtenerTabs();
        let index = tabs.indexOf(id);
        if(index == -1) index = null;
        return index;
    }

    actualizarTabSeleccionada(index: number) {
        let tabs = this.obtenerTabs();
        if(index != null && index < tabs.length) {
            let idTab = tabs[index];
            let url = this.router.createUrlTree([], { relativeTo: this.route, skipLocationChange: true, queryParams: { tab: idTab }}).toString();
            this.location.go(url);
        }
    }

    clickCertificado(certificado: Certificado) {
        let CertificadoId = certificado.id;
        if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA) && !this.deshabilitado) {
            this.router.navigate(['/polizas/editar-certificado/' + CertificadoId]);
        }
        else if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
            this.router.navigate(['/polizas/detalle-certificado/' + CertificadoId]);
        }
    }

    async obtenerPagadores() {
        this.cargando = true;
        if(!this.modoCreacion && this.id) {
            var res = await this.service.obtenerTodosPagadoresPoliza(this.id);
            if(!res.error) {
                this.pagadores = res.data.registros;
                this.filtrosDocumentosCobros[0].categorias = this.pagadores;
            }
            else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    actualizarDataCobros($event) {
        if($event) {
            this.totalPrimaTotal = $event.totalPrimaTotal;
            this.totalPrimaNeta = $event.totalPrimaNeta;
            this.totalEmision = $event.totalEmision;
            this.totalGastos = $event.totalGastos;
            this.totalImpuestos = $event.totalImpuestos;
            this.totalFraccionamiento = $event.totalFraccionamiento;
        }
    }

    actualizarDataHistorico($event) {
        if($event) {
            this.historicas = $event.records;
        }
    }

    // * * * Aclaraciones * * *
    abrirNuevoAclaracion() {
        var aclaracion = new AclaracionPoliza(null, null, null, null, this.id, this.auth.idUsuarioActual(), this.auth.idEmpresaActual())

        const dialogRef = this.dialog.open(DialogNuevoAclaracionPoliza, {
            data: {
                aclaracion: aclaracion,
                poliza: this.registro,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.accion == 'confirmar') {
                this.guardarAclaracion(result.aclaracion);
            }
            else if(result && result.accion == 'eliminar') {
                this.eliminarAclaracion(result.aclaracion.id);
            }
        });
    }

    async guardarAclaracion(aclaracion: AclaracionPoliza) {
        this.cargandoAclaracion = true;
        if(aclaracion) {
            // Enviar el registro para guardar
            var res = null;
            if(aclaracion.id == null || aclaracion.id < 0) res = await this.service.crearAclaracionPoliza(aclaracion);
            else res = await this.service.actualizarAclaracionPoliza(aclaracion);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargandoAclaracion = false;
    }

    async eliminarAclaracion(AclaracionPolizaId: number) {
        this.cargandoAclaracion = true;
        if(AclaracionPolizaId) {
            // Enviar el registro para guardar
            var res = await this.service.eliminarAclaracionPoliza(AclaracionPolizaId);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargandoAclaracion = false;
    }

    abrirAclaracionExistente(aclaracion: AclaracionPoliza) {
      const dialogRef = this.dialog.open(DialogNuevoAclaracionPoliza, {
          data: {
              aclaracion: aclaracion,
              poliza: this.registro,
          }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result && result.accion == 'confirmar') {
              this.guardarAclaracion(result.aclaracion);
          }
          else if(result && result.accion == 'eliminar') {
              this.eliminarAclaracion(result.aclaracion.id);
          }
      });
    }

    paramsEspecialesAclaraciones() {
        var params = '';
        if(this.registro.id) params += '&PolizaId=' + this.registro.id;
        return params;
    }

    // * * * Cláusulas de garantía * * *
    abrirNuevoClausulaGarantia() {
        var clausula = new ClausulaGarantiaPoliza(null, null, null, null, null, null, null, null, null, 'activo', this.id, this.registro.MonedaId, this.auth.idUsuarioActual(), this.auth.idEmpresaActual(), null)

        const dialogRef = this.dialog.open(DialogNuevoClausulaGarantiaPoliza, {
            data: {
                clausula: clausula,
                poliza: this.registro,
                monedas: this.monedas,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.accion == 'confirmar') {
                this.guardarClausulaGarantia(result.clausula);
            }
            else if(result && result.accion == 'eliminar') {
                this.eliminarClausulaGarantia(result.clausula.id);
            }
        });
    }

    async guardarClausulaGarantia(clausula: ClausulaGarantiaPoliza) {
        this.cargandoClausulaGarantia = true;
        if(clausula) {
            // Enviar el registro para guardar
            var res = null;
            if(clausula.id == null || clausula.id < 0) res = await this.service.crearClausulaGarantiaPoliza(clausula);
            else res = await this.service.actualizarClausulaGarantiaPoliza(clausula);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargandoClausulaGarantia = false;
    }

    async eliminarClausulaGarantia(ClausulaGarantiaPolizaId: number) {
        this.cargandoClausulaGarantia = true;
        if(ClausulaGarantiaPolizaId) {
            // Enviar el registro para guardar
            var res = await this.service.eliminarClausulaGarantiaPoliza(ClausulaGarantiaPolizaId);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargandoClausulaGarantia = false;
    }

    abrirClausulaGarantiaExistente(clausula: ClausulaGarantiaPoliza) {
        if(clausula){
            if(!clausula.MonedaId) clausula.MonedaId = this.registro.MonedaId;
            const dialogRef = this.dialog.open(DialogNuevoClausulaGarantiaPoliza, {
                data: {
                    clausula: clausula,
                    poliza: this.registro,
                    modoEdicion: this.modoEdicion,
                    monedas: this.monedas,
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if(result && result.accion == 'confirmar') {
                    this.guardarClausulaGarantia(result.clausula);
                }
                else if(result && result.accion == 'eliminar') {
                    this.eliminarClausulaGarantia(result.clausula.id);
                }
            });
        }
    }

    paramsEspecialesClausulasGarantias() {
        var params = '';
        if(this.registro.id) params += '&PolizaId=' + this.registro.id;
        return params;
    }

    paramsEspecialesCambiosEstado() {
        var params = '';
        if(this.registro.id) params += '&PolizaId=' + this.registro.id;
        return params;
    }

    // * * * Descargar * * *
    async descargarExcelCertificados() {
        this.cargando = true;
        var res = await this.service.descargarExcelCertificadosDePoliza(
            this.id, this.registro.numero
        );

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }

        this.cargando = false;
    }

    async descargarExcelClausulas() {
        // Descargar el excel
        this.cargando = true;
        var params = this.paramsEspecialesClausulasGarantias();
        var res = await this.service.descargarExcelClausulaGarantiaPoliza('Cláusulas Garantia', params);
        if (!res.error) {
            this.libraryService.crearNotificacion('Se descargó el Excel correctamente', 'success');
        } else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async descargarExcelPlantillaClausula(){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlantillaClausula('Plantilla de claúsulas');

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    irImportacionClausulas() {
        this.router.navigate(['/polizas/importacion-clausulas/' + this.id]);
    }

    // * * * Acciones * * *
    aplicarAccion($event) {
        if($event && !this.cargando) {
            var accion = $event.accion;
            var seleccionados = $event.seleccionados;
            var ids = $event.ids;
            switch(accion.id) {
                case 'activo': {
                    this.abrirEnviarActivo(ids);
                    break;
                }
                case 'excluido': {
                    this.abrirEnviarExcluidoConDatepicker(ids);
                    break;
                }
            }
        }
    }

    // * Activo *
    abrirEnviarActivo(ids: number[]) {
        this.idsSeleccionados = ids;
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Pasar a activo',
                mensaje: '¿Está seguro de que desea modificar la etapa de las cláusulas seleccionadas?',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.enviarActivo();
            }
        });
    }

    async enviarActivo() {
        this.cargando = true;
        if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.cambiarEstadoClausulaGarantia(this.idsSeleccionados, 'activo', null);
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
                this.auth.totalesProspectosReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    abrirEnviarExcluidoConDatepicker(ids: number[]) {
        this.idsSeleccionados = ids;
        const dialogRef = this.dialog.open(GenericDialogDatepicker, {
            data: {
                titulo: 'Pasar a excluido',
                mensaje: '¿Está seguro de que desea modificar la etapa de las cláusulas seleccionadas?',
                nombrePlaceholder: 'Fecha de baja',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.actualizarFechaBajaClausulaGarantia(result.fechaDatePicker);
                this.enviarExcluido();
            }
        });

    }

    async enviarExcluido() {
        this.cargando = true;
        if(this.idsSeleccionados && this.idsSeleccionados.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.cambiarEstadoClausulaGarantia(this.idsSeleccionados, 'excluido', this.fechaBajaClausulaGarantia);
            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.auth.buscadorReload.next();
                this.auth.totalesProspectosReload.next();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    obtenerDeDiccionario(id, nombreArray) {
        let obj = this.apiURLs[nombreArray].find(element => element.id == id);
        return obj ? obj.nombre : id;
    }

    estadoDisabled(estadoId: any) {
        let estadoObjActual = this.apiURLs.estadosPolizas.find(element => element.id == this.registro.estado);
        if(estadoObjActual.cambiosPermitidos.includes(estadoId)) return false;
        return true;
    }
}

@Component({
    selector: 'bottom-sheet-poliza',
    templateUrl: 'bottom-sheet-poliza.html',
})
export class BottomSheetPolizaComponent {
    constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetPolizaComponent>) {}

    abrirAccion(accion): void {
        this._bottomSheetRef.dismiss(accion);
    }
}
