import { Ramo } from './ramo';
import { Aseguradora } from './aseguradora';
import { ImpuestoEmpresa } from './impuestoEmpresa';
import { Inciso } from './inciso';
import { ComisionAseguradoraProducto } from './comisionAseguradoraProducto';
import { GastoProducto } from './gastoProducto';

export class Producto {
    constructor(
        public id: number,
        public nombre: string,
        public codigoAnterior: string,
        public tipoOtrosGastos: string,
        public valorOtrosGastos: number,
        public porcentajeOtrosGastos: number,
        public tipoGastosEmision: string,
        public valorGastosEmision: number,
        public porcentajeGastosEmision: number,
        public edadLimiteDependientesAsegurados: string,
        public edadLimiteReduccionBeneficios1: string,
        public edadLimiteReduccionBeneficios2: string,
        public usaAnioCalendario: boolean,
        public activo: boolean,
        public isDeleted: boolean,
        public RamoId: number,
        //public AseguradoraId: number,
        public EmpresaId: number,
    ){}

    ramo: Ramo;
    nombreAseguradoras: string = '';
    idsAseguradoras: number[]
    aseguradoras: Aseguradora[];
    impuestos: ImpuestoEmpresa[];
    idsImpuestos: number[];
    incisos: Inciso[];
    idsIncisos: number[];
    comisiones: ComisionAseguradoraProducto[];
    gastos: GastoProducto[];
}
