import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APIUrls } from '../../api/apiUrls';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { JSONConverters } from 'src/app/models/JSONConverters';
import iconEdit from '@iconify/icons-fa-solid/edit';
import { PolizasService } from '../polizas.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Poliza } from 'src/app/models/poliza';
import { SolicitudesService } from 'src/app/solicitudes/solicitudes.service';
import { LibraryService } from 'src/app/library/library.service';
import { SolicitudPoliza } from 'src/app/models/solicitudPoliza';

@Component({
    selector: 'app-solicitudes-polizas',
    templateUrl: './solicitudes-polizas.component.html',
    styleUrls: ['./solicitudes-polizas.component.scss']
})
export class SolicitudesPolizasComponent implements OnInit {
    crumbs = [
        { nombre: 'Pólizas', link: '/polizas' },
    ];
    // Templates de celdas
    @ViewChild('cellNormal', { static: true }) cellNormal: TemplateRef<any>;

    apiURLs = new APIUrls();
    jsonConverters = new JSONConverters();

    // Columnas de tabla
    columnas = [
        { prop: 'id', name: 'No. Prosis', sortable: true, cellTemplate: null },
        { prop: 'fechaFormato', name: 'Fecha', sortable: true, cellTemplate: null },
        { prop: 'poliza.numero', name: 'Póliza', sortable: true, cellTemplate: null },
        { prop: 'reclamo.numeroAseguradora', name: 'Reclamo', sortable: true, cellTemplate: null },
        { prop: 'cliente.nombreCompleto', name: 'Cliente', sortable: true, cellTemplate: null },
        { prop: 'nombrePlantilla', name: 'Plantilla', sortable: true, cellTemplate: null },
        { prop: 'solicitudIdAnterior', name: 'Solicitud migrada', sortable: true, cellTemplate: null },
        { prop: 'MapaProcesoId', name: 'No. mapa', sortable: true, cellTemplate: null },
        { prop: 'puntuacion', name: 'Puntuación', sortable: true, cellTemplate: null },
    ];

    // Campos para buscador
    campos = [
        { nombre: 'Número', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'Solicitud migrada', campo: 'numero', tipo: 'texto', categorias: null },
        { nombre: 'No. mapa', campo: 'MapaProcesoId', tipo: 'texto', categorias: null },
        { nombre: 'Contenido', campo: 'contenido', tipo: 'texto', categorias: null },
    ];

    // Filtros
    filtros = [
        // Plantilla
        {
            nombre: 'Plantilla', campo: 'Plantilla', tipo: 'select-multiple',
            mostrar: false,
            categorias: null,
        },
    ];

    posiblesEstadosSolicitudes = [
      { nombre: 'Activo', color: '#138708' },
      { nombre: 'Finalizado', color: 'blue' },
      { nombre: 'Cancelado', color: 'red' },
      { nombre: 'En espera', color: 'black' },
    ]

    urlBusqueda = this.apiURLs.baseURL + this.apiURLs.solicitudesPolizasURL + '/search';
    titulo: string = 'Pólizas';
    mostrarTabla: boolean = false;
    iconEdit = iconEdit;
    modo: string = 'polizas';

    constructor(
        public service: PolizasService,
        public solicitudesService: SolicitudesService,
        public libraryService: LibraryService,
        public auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
    }

    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.agregarFiltros();
        this.obtenerPlantillasSolicitudes();
        this.agregarTemplates();
        this.mostrarTabla = true;
    }

    obtenerRuta(params: Params) {
        if(this.route.snapshot.url.length > 0) {
            var ruta = this.route.snapshot.url[0].path;
            this.modo = ruta;
            switch(this.modo) {
                case 'solicitudes-pendientes-calidad': {
                    this.titulo = 'Solicitudes pendientes';
                    this.columnas = this.columnas;
                    this.crumbs[0].nombre = this.titulo;
                    this.crumbs[0].link = '/polizas/solicitudes-pendientes-calidad';
                    break;
                }
                case 'solicitudes-revisadas-calidad': {
                    this.titulo = 'Solicitudes revisadas';
                    this.columnas = this.columnas;
                    this.crumbs[0].nombre = this.titulo;
                    this.crumbs[0].link = '/polizas/solicitudes-revisadas-calidad';
                    break;
                }
            }
        }
        this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
    }

    agregarTemplates() {
        this.columnas.forEach(columna => {
            columna.cellTemplate = this.cellNormal;
        });
    }

    async agregarFiltros() {

    }

    async obtenerPlantillasSolicitudes() {
        var res = await this.solicitudesService.obtenerTodasPlantillasSolicitudes(null, null);
        if (!res.error) {
            this.filtros[0].categorias = res.data.registros;
        } else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
    }

    clickPoliza(solicitud: SolicitudPoliza) {
        let PolizaId = solicitud.PolizaId;
        let ClienteId = solicitud.ClienteId;
        let ReclamoId = solicitud.ReclamoId;
        if(PolizaId) {
            if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                this.router.navigate(['/polizas/editar/' + PolizaId]);
            }
            else if(this.auth.tienePermisoPara(this.apiURLs.modulos.POLIZAS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
                this.router.navigate(['/polizas/detalle/' + PolizaId]);
            }
        }
        else if(ClienteId) {
            if(this.auth.tienePermisoPara(this.apiURLs.modulos.CLIENTES.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                this.router.navigate(['/clientes/editar/' + ClienteId]);
            }
            else if(this.auth.tienePermisoPara(this.apiURLs.modulos.CLIENTES.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
                this.router.navigate(['/clientes/detalle/' + ClienteId]);
            }
        }
        else if(ReclamoId) {
            if(this.auth.tienePermisoPara(this.apiURLs.modulos.RECLAMOS.id, this.apiURLs.nivelesAutorizacion.ESCRITURA)) {
                this.router.navigate(['/reclamos/editar/' + ReclamoId]);
            }
            else if(this.auth.tienePermisoPara(this.apiURLs.modulos.RECLAMOS.id, this.apiURLs.nivelesAutorizacion.LECTURA)) {
                this.router.navigate(['/reclamos/detalle/' + ReclamoId]);
            }
        }
    }

    calcularParamsEspeciales(): string {
        var paramsEspeciales = '';

        if(this.modo == 'solicitudes-pendientes-calidad') {
            paramsEspeciales += '&estadoControlCalidad=pendiente';
        }
        else if(this.modo == 'solicitudes-revisadas-calidad') {
            paramsEspeciales += '&estadoControlCalidad=revisada';
        }

        return paramsEspeciales;
    }
}
