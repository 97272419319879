import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { LibraryService } from '../../library/library.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { APIUrls } from '../../api/apiUrls';
import iconSave from '@iconify/icons-fa-solid/save';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconSearch from '@iconify/icons-fa-solid/search';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, PageEvent } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { CobrosService } from '../cobros.service';
import { PlanillaCobro } from 'src/app/models/planillaCobro';
import { Aseguradora } from 'src/app/models/aseguradora';
import { Moneda } from 'src/app/models/moneda';
import { EmpresasService } from 'src/app/empresas/empresas.service';
import { AseguradorasService } from 'src/app/aseguradoras/aseguradoras.service';
import { DocumentoCobro } from 'src/app/models/documentoCobro';
import { JSONConverters } from 'src/app/models/JSONConverters';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { PlanillaLiquidacion } from 'src/app/models/planillaLiquidacion';
import { GenericDialogErrores } from 'src/app/library/generic-dialog-errores/generic-dialog-errores.component';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

@Component({
    selector: 'app-nuevo-planilla-liquidacion',
    templateUrl: './nuevo-planilla-liquidacion.component.html',
    styleUrls: ['./nuevo-planilla-liquidacion.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class NuevoPlanillaLiquidacionComponent implements OnInit {
    @ViewChild('cellMoneda', { static: true }) cellMoneda: TemplateRef<any>;

    // Íconos
    iconSave = iconSave;
    iconEdit = iconEdit;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconClose = iconClose;
    iconCheck = iconCheck;
    iconPlus = iconPlus;
    iconSearch = iconSearch;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    
    // Banderas y generales
    cargando: boolean = false;
    modoEdicion: boolean;
    modoDetalle: boolean;
    modoCreacion: boolean;
    titulo: string;
    apiURLs: APIUrls = new APIUrls();
    fechaHoy = new Date();

    tamanoPagina: number = 25;
    pagina: number = 0;
    selectedIndex: number = 0;
    todosSeleccionados: boolean = false;
    
    // Datos
    id: number;
    ids: number[];
    registro: PlanillaLiquidacion;
    aseguradoras: Aseguradora[];
    monedas: Moneda[];
    documentosCobro: DocumentoCobro[];
    idsDocumentos: number[];
    comisionesCalculadas: any[];

    // Generales importación
    rutaArchivo: string;
    archivo: File;
    erroresLectura: any[];
    analisis = false;
    registrosASubir: any[];

    crumbs = [
        { nombre: 'Requirimientos cobrados', link: '/cobros/cobrado' },
        { nombre: 'Planillas de liquidación', link: '/cobros/planillas-liquidacion' },
        { nombre: 'Nueva planilla de liquidación', link: '/cobros/nuevo-planilla-liquidacion' },
    ];

    constructor(
        public service: CobrosService,
        public empresasService: EmpresasService,
        public aseguradorasService: AseguradorasService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public auth: AuthService,
        private libraryService: LibraryService,
        private titleService: Title,
        public dialog: MatDialog,
    ) { }

    // * * * * * Inicializar componente y datos * * * * * 
    // Inicializar componente
    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.inicializarDatos(params);
        });
        this.route.queryParams.subscribe((params: Params) => {
            if(params['idsDocumentos']) {
                this.ids = params['idsDocumentos'].split(',');
                this.obtenerDocumentosCobrados();
            }
        });
    }

    // Inicializar datos
    async inicializarDatos(params: Params){
        this.obtenerRuta(params);
        await this.obtenerRegistro();
        this.obtenerMonedas();
        this.obtenerAseguradoras();
    }

    // Marcar banderas según tipo de acción
    obtenerRuta(params: Params) {
        var ruta = this.route.snapshot.url[0].path;
        this.modoCreacion = ruta === 'nuevo-planilla-liquidacion';
        this.modoEdicion = ruta === 'editar-planilla-liquidacion';
        this.modoDetalle = ruta === 'detalle-planilla-liquidacion';
        if(params['id']){
            this.id = parseInt(params['id']);
        }
    }

    // * * * * * Obtener datos * * * * * 
    // Obtener el registro o inicializar uno nuevo
    async obtenerRegistro() {
        this.cargando = true;
        if(this.modoCreacion) {
            // Asignar título
            this.titulo = 'Agregar planilla de liquidación';
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            this.registro = new PlanillaLiquidacion(null, null, null, null, null, null, null, false, this.auth.idUsuarioActual(), this.auth.idEmpresaActual());
            this.registro.fechaDate = new Date();
            this.actualizarFecha({ value: { _d: new Date() } }, 'fecha');
            this.registro.fechaHastaDate = new Date();
            this.actualizarFecha({ value: { _d: new Date() } }, 'fechaHasta');
        }
        else if(this.modoEdicion || this.modoDetalle) {
            this.titulo = (this.modoEdicion ? 'Editar' : 'Detalle de') + ' planilla de liquidación ' + this.id;
            this.titleService.setTitle(this.titulo + this.apiURLs.nombreSitioTitle);
            // Obtener registro
            var res = await this.service.obtenerPlanillaLiquidacionPorId(this.id);
            if(!res.error) {
                this.registro = res.data.registro;
                this.documentosCobro = this.registro.documentos;
                this.comisionesCalculadas = this.registro.comisionesCalculadas;
                this.asignarComisionesSistema();
                this.crumbs[2].link = '/cobros/detalle-planilla-liquidacion/' + this.id;
            }
            else {
                this.router.navigate(['/404']);
            }
        }
        this.cargando = false;
    }

    seleccionarAutomatico() {
        if(this.documentosCobro) {
            for (let el of this.documentosCobro) {
                if(el.etapa == 'cobrado') {
                    el.selected = !this.errorEnDiferencia(el.diferenciaComisionAuxiliar, el.comisionSistema);
                }
            }
        }
    }

    asignarComisionesSistema() {
        if(this.documentosCobro && this.comisionesCalculadas) {
            for (let el of this.documentosCobro) {
                let comision = this.comisionesCalculadas.find(x => x.DocumentoCobroId == el.id);
                if(comision) {
                    el.comisionSistema = comision.comision;
                }
                else el.comisionSistema = 0;

                el.diferenciaComisionAuxiliar = el.comisionEmisorAuxiliar - el.comisionSistema;
            }
        }
        this.seleccionarAutomatico();
    }

    // * * * * * Editar datos * * * * *
    // Guardar datos
    async guardar(){
        this.cargando = true;

        this.registro.documentos = this.documentosCobro;
        this.registro.idsDocumentos = this.idsDocumentos;

        // Enviar el registro para guardar
        var res;
        if(this.modoCreacion) {
            res = await this.service.crearPlanillaLiquidascion(this.registro);
            this.erroresLectura = res.data.result.errores;
        }
        else if(this.modoEdicion) {
            res = await this.service.actualizaPlanillaLiquidascion(this.registro);
            this.erroresLectura = res.data.result.errores;
        }
        
        if(!res.error) {
            if(this.erroresLectura && this.erroresLectura.length > 0) {
                this.abrirDialogErrores();
            }
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            if(this.modoCreacion) this.router.navigate(['/cobros/detalle-planilla-liquidacion/' + res.data.result.result.id]);
            this.limpiarArchivo();
            this.ngOnInit();
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Documentos * * * * *
    async obtenerDocumentosCobrados() {
        this.cargando = true;
        if(this.modoCreacion && this.ids) {
            var res = await this.service.obtenerDocumentosIds(this.ids);
            if(!res.error) {
                this.documentosCobro = res.data.registros;
            }
            else {
                this.documentosCobro = [];
            }
        }
        this.cargando = false;
    }

    eliminarDocumento(indexDocumento: number) {
        if(indexDocumento != null && this.documentosCobro && this.documentosCobro.length > indexDocumento) {
            this.documentosCobro.splice(indexDocumento, 1);
        }
    }

    actualizarFecha($event, campo){
        if($event && $event.value) {
            this.registro[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    // * * * Eliminar datos * * *
    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar planilla de liquidación',
                mensaje: '¿Está seguro de que desea eliminar esta planilla de liquidación?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    async eliminar(){
        this.cargando = true;

        // Enviar el registro para guardar
        var res = await this.service.eliminarPlanillaLiquidacion(this.id);
        
        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
            this.router.navigate(['/cobros/planillas-liquidacion/']);
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    // * * * * * Monedas * * * * *
    async obtenerMonedas() {
        this.cargando = true;
        let EmpresaId = await this.auth.idEmpresaActual();
        var res = await this.empresasService.obtenerTodasMonedasEmpresa(EmpresaId);
        if(!res.error) {
            this.monedas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerAseguradoras() {
        this.cargando = true;
        var res = await this.aseguradorasService.obtenerTodasAseguradoras();
        if(!res.error) {
            this.aseguradoras = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }
 
    // * * * * * Validaciones * * * * * 
    formularioValido() {
        if(!this.registro) return false;
        if(!this.registro.fecha) return false;
        // if(!this.documentosCobro || this.documentosCobro.length == 0) return false;
        if(this.modoCreacion && (!this.registro.registrosAuxiliar || this.registro.registrosAuxiliar.length == 0)) return false;

        return true;
    }

    liquidarValido() {
        if(!this.documentosCobro || this.documentosCobro.length == 0) return false;
        let selected = this.documentosCobro.filter(x => x.selected && x.etapa == 'cobrado');
        if(!selected || selected.length == 0) return false;

        for (let el of selected) {
            if(this.errorEnDiferencia(el.diferenciaComisionAuxiliar, el.comisionSistema)) return false;
        }
        return true;
    }

    eliminarValido() {
        if(!this.documentosCobro || this.documentosCobro.length == 0) return false;
        let selected = this.documentosCobro.filter(x => x.selected);
        if(!selected || selected.length == 0) return false;
        return true;
    }

    async liquidarSeleccionados() {
        let selected = this.documentosCobro.filter(x => x.selected && x.etapa == 'cobrado');
        this.abrirEnviarALiquidados(selected.map(x => x.id), selected.map(x => { return { id: x.id, observacionesLiquidacion: x.observacionesLiquidacion } }));
    }

    async eliminarSeleccionados() {
        let selected = this.documentosCobro.filter(x => x.selected);
        this.abrirEliminarDePlanilla(selected.map(x => x.id));
    }

    seleccionarArchivo(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivo = file.name;
            this.archivo = file;
            this.analisis = false;
            this.analizarArchivoExcel();
        }
    }

    activarSeleccionArchivo(){
        document.getElementById('file-input-archivo').click();
    }

    limpiarArchivo(){
        this.rutaArchivo = null;
        this.archivo = null;
        this.analisis = false;
        this.erroresLectura = null;
    }

    // * * * * * Análisis de archivo * * * * *
    async analizarArchivoExcel() {
        if(this.archivo) {
            this.cargando = true;
            var resLectura = await this.service.lecturaImportacionPlanillaLiquidacion(this.archivo, this.auth.idEmpresaActual());
            if(resLectura.error) {
                // Error al leer archivo
                this.libraryService.crearNotificacion(resLectura.mensaje, 'danger');
                throw new Error();
            }
            else {
                this.erroresLectura = resLectura.errores;
                this.registro.registrosAuxiliar = resLectura.resultados;
                this.libraryService.crearNotificacion(resLectura.mensaje, 'success');
            }
            this.cargando = false;
        }
    }

    abrirDialogErrores() {
        const dialogRef = this.dialog.open(GenericDialogErrores, {
            data: {
                titulo: 'Errores de lectura de archivo CSV',
                errores: this.erroresLectura,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            
        });
    }

    async descargarPlantilla(){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlantillaPlanillaLiquidacion('Plantilla de planilla de liquidación');

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    async descargarExcelPlanilla(soloDiferencias: boolean){
        this.cargando = true;
        // Enviar el registro para guardar
        var res = await this.service.descargarExcelPlanillaLiquidacion('Planilla de liquidación ' + this.id, this.id, soloDiferencias);

        if(!res.error) {
            this.libraryService.crearNotificacion(res.data.mensaje, 'success');
        }
        else {
            this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        }
        this.cargando = false;
    }

    errorEnDiferencia(diferencia, total) {
        if(this.registro.diferenciaAceptable) {
            if(Math.abs(diferencia) > this.registro.diferenciaAceptable) return true;
        }

        if(this.registro.diferenciaPorcentajeAceptable) {
            let porcentaje = total ? ((Math.abs(diferencia) / total) * 100) : 0;
            if(porcentaje > this.registro.diferenciaPorcentajeAceptable) return true;
        }

        return false;
    }

    abrirEnviarALiquidados(ids: number[], observacionesLiquidacion: any[]) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Marcar como liquidados',
                mensaje: '¿Está seguro de que desea liquidar los requerimientos seleccionados?',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.enviarALiquidados(ids, observacionesLiquidacion);
            }
        });
    }

    async enviarALiquidados(ids: number[], observacionesLiquidacion: any[]) {
        this.cargando = true;
        if(ids && ids.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.pasarALiquidados(ids, null, null, this.auth.idUsuarioActual(), observacionesLiquidacion);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    abrirEliminarDePlanilla(ids: number[]) {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar de planilla',
                mensaje: '¿Está seguro de que desea eliminar de la planilla los requerimientos seleccionados?',
                payload: {
                    ids: ids,
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminarDePlanilla(ids);
            }
        });
    }

    async eliminarDePlanilla(ids: number[]) {
        this.cargando = true;
        if(ids && ids.length > 0) {
            // Enviar el registro para guardar
            var res = await this.service.eliminarDePlanillaLiquidacion(ids, this.auth.idUsuarioActual(), this.id);

            if(!res.error) {
                this.libraryService.crearNotificacion(res.data.mensaje, 'success');
                this.ngOnInit();
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
            }
        }
        this.cargando = false;
    }

    pageUpdate($event: PageEvent) {
        this.pagina = $event.pageIndex;
        this.tamanoPagina = $event.pageSize;
    }

    seleccionarTodos() {
        if(this.documentosCobro) {
            for (let el of this.documentosCobro) {
                if(el.etapa == 'cobrado') {
                    el.selected = this.todosSeleccionados;
                }
            }
        }
    }
}