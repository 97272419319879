import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { Usuario } from 'src/app/models/usuario';
import { APIUrls } from 'src/app/api/apiUrls';
import { Agrupador } from 'src/app/models/agrupador';
import * as _moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { LibraryService } from 'src/app/library/library.service';
import iconSave from '@iconify/icons-fa-solid/save';
import iconBack from '@iconify/icons-fa-solid/arrow-left';
import iconDownload from '@iconify/icons-fa-solid/download';
import iconUpload from '@iconify/icons-fa-solid/upload';
import iconDelete from '@iconify/icons-fa-solid/trash';
import iconView from '@iconify/icons-fa-solid/info-circle';
import iconClose from '@iconify/icons-fa-solid/times';
import iconCheck from '@iconify/icons-fa-solid/check';
import iconPlus from '@iconify/icons-fa-solid/plus';
import iconEdit from '@iconify/icons-fa-solid/edit';
import iconList from '@iconify/icons-fa-solid/list';
import iconDetalle from '@iconify/icons-fa-solid/search';
import iconError from '@iconify/icons-fa-solid/exclamation-circle';
import iconCandadoDetalle from '@iconify/icons-fa-solid/lock';
import iconCandadoEditar from '@iconify/icons-fa-solid/lock-open';
import iconAdd from '@iconify/icons-fa-solid/plus';
import { ContactoUsuario } from 'src/app/models/contactoUsuario';
import { FormularioCliente } from 'src/app/models/formularioCliente';
import { ClientesService } from 'src/app/clientes/clientes.service';
import { UsuariosService } from 'src/app/usuarios/usuarios.service';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    cliente: Usuario,
}

@Component({
    selector: 'dialog-nuevo-cliente',
    templateUrl: './dialog-nuevo-cliente.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevoCliente {
    apiURLs = new APIUrls();
    urlAutocompleteAgrupadores = this.apiURLs.baseURL + this.apiURLs.agrupadoresURL + '/search';
    agrupadores: Agrupador[] = [];

    columnasAgrupadores = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'descripcion', name: 'Descripción', sortable: true, cellTemplate: null },
    ];
    camposAgrupadores = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];
    
    // URLs
    urlAutocompleteAgentes = this.apiURLs.baseURL + this.apiURLs.usuariosURL + '/autocompleteAgentes';
    urlAutocompleteEjecutivos = this.apiURLs.baseURL + this.apiURLs.usuariosURL + '/autocompleteEjecutivos';
    columnasEjecutivos = [
        { prop: 'nombre', name: 'Nombre', sortable: true, cellTemplate: null },
        { prop: 'apellido', name: 'Apellido', sortable: true, cellTemplate: null },
        { prop: 'correo1', name: 'Correo', sortable: true, cellTemplate: null },
        { prop: 'nombreArea', name: 'Área', sortable: true, cellTemplate: null },
    ];
    camposEjecutivos = [
        { nombre: 'Nombre', campo: 'general', tipo: 'texto', categorias: null },
    ];

    agentes: Usuario[] = [];
    ejecutivos: Usuario[] = [];
    tiposClientes: any[] = this.apiURLs.tiposClientes;
    tiposDocumentos: any[] = this.apiURLs.tiposDocumentos;
    clases: any[] = this.apiURLs.clases;
    clasificaciones: any[] = this.apiURLs.clasificaciones;
    paises: any[] = this.apiURLs.paises;
    modoCreacion: boolean = true;
    modoDetalle: boolean = false;

    // Íconos
    iconSave = iconSave;
    iconEdit = iconEdit;
    iconBack = iconBack;
    iconDownload = iconDownload;
    iconUpload = iconUpload;
    iconDelete = iconDelete;
    iconView = iconView;
    iconCheck = iconCheck;
    iconClose = iconClose;
    iconPlus = iconPlus;
    iconDetalle = iconDetalle;
    iconError = iconError;
    iconCandadoDetalle = iconCandadoDetalle;
    iconCandadoEditar = iconCandadoEditar;
    iconAdd = iconAdd;
    iconList = iconList;

    // Archivos 
    cargando: boolean = false;
    rutaArchivoDocumento: string;
    archivoDocumento: File;
    modoSubirDocumento: boolean = true;
    rutaArchivoRecibo: string;
    archivoRecibo: File;
    modoSubirRecibo: boolean = true;
    rutaArchivoImagen: string;
    archivoImagen: File;
    modoSubirImagen: boolean = true;
    rutaArchivoPortada: string;
    archivoPortada: File;
    modoSubirPortada: boolean = true;
    formularios: FormularioCliente[];
    registro: Usuario;

    nitValido: boolean = false;
    numeroDocumentoValido: boolean = false;
    pasaporteValido: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DialogNuevoCliente>,
        public service: AuthService,
        public clientesService: ClientesService,
        public usuariosService: UsuariosService,
        public libraryService: LibraryService,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() {
        // Arreglar estilo
        var dialog = document.getElementById("dialogOverflow");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.registro = this.data.cliente;
        this.obtenerAgentes();
        this.obtenerEjecutivos();
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    async confirmar() {
        try {
            this.cargando = true;
            // Subir imagen
            if(this.modoSubirImagen && this.archivoImagen) {
                var resUpload = await this.clientesService.guardarArchivoEnServidorClientes(this.archivoImagen);
                if(resUpload.error){
                    this.libraryService.crearNotificacion(resUpload.error.mensajeError, 'danger');
                    throw new Error();
                }
                else {
                    var url = resUpload.data.url;
                    this.registro.imagen = url;
                }
            }
    
            // Subir portada
            if(this.modoSubirPortada && this.archivoPortada) {
                var resUpload = await this.clientesService.guardarArchivoEnServidorClientes(this.archivoPortada);
                if(resUpload.error){
                    this.libraryService.crearNotificacion(resUpload.error.mensajeError, 'danger');
                    throw new Error();
                }
                else {
                    var url = resUpload.data.url;
                    this.registro.portada = url;
                }
            }
            
            // Subir documento
            if(this.modoSubirDocumento && this.archivoDocumento) {
                var resUpload = await this.clientesService.guardarArchivoEnServidorClientes(this.archivoDocumento);
                if(resUpload.error){
                    this.libraryService.crearNotificacion(resUpload.error.mensajeError, 'danger');
                    throw new Error();
                }
                else {
                    var url = resUpload.data.url;
                    this.registro.informacionCliente.archivoDocumento = url;
                }
            }
    
            // Subir recibo
            if(this.modoSubirRecibo && this.archivoRecibo) {
                var resUpload = await this.clientesService.guardarArchivoEnServidorClientes(this.archivoRecibo);
                if(resUpload.error){
                    this.libraryService.crearNotificacion(resUpload.error.mensajeError, 'danger');
                    throw new Error();
                }
                else {
                    var url = resUpload.data.url;
                    this.registro.informacionCliente.archivoRecibo = url;
                }
            }
    
            this.cargando = false;
            this.dialogRef.close(this.registro);
        }
        catch(error) {
            this.cargando = false;
        }
    }

    registroValido(): boolean {
        if(!this.registro || !this.registro.informacionCliente) return false;
        if(this.registro.informacionCliente.tipo == 'corporativo' && !this.registro.nombre) return false;
        if(this.registro.informacionCliente.tipo == 'individual' && !this.registro.nombre && !this.registro.apellido) return false;
        if(!this.registro.direccion || !this.registro.telefono1) return false;
        // if(
        //     !this.registro.informacionCliente.lugarTrabajo ||
        //     !this.registro.informacionCliente.direccionTrabajo ||
        //     !this.registro.informacionCliente.lugarCobro ||
        //     !this.registro.informacionCliente.lugarCorrespondencia ||
        //     !this.registro.informacionCliente.direccionFiscal
        // ) return false;
        if(this.registro.informacionCliente.tipo == 'corporativo') {
            if(
                !this.registro.informacionCliente.razonSocial ||
                !this.registro.informacionCliente.nit ||
                !this.registro.informacionCliente.direccionFiscal
            ) return false;
        }
        return true;
    }

    // * * * * * Actualizaciones de controles * * * * *
    // Actualiza el id del Agente
    actualizarAgentes(items: Usuario[]) {
        var idsUsuarios: number[] = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            idsUsuarios.push(item.id);
        }
        this.registro.agentesIds = idsUsuarios;
    }
    
    // Actualiza el id del Ejecutivo
    actualizarEjecutivos(items: Usuario[]) {
        var idsUsuarios: number[] = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            idsUsuarios.push(item.id);
        }
        this.registro.ejecutivosIds = idsUsuarios;
    }

    async obtenerAgentes() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosAgentes();
        if(!res.error) {
            this.agentes = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async obtenerEjecutivos() {
        this.cargando = true;
        var res = await this.usuariosService.obtenerTodosEjecutivos();
        if(!res.error) {
            this.ejecutivos = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    actualizarAgrupador(items: Agrupador[]) {
        var AgrupadorId = null;
        if(items && items.length > 0) {
            AgrupadorId = items[0].id;
        }
        this.registro.AgrupadorId = AgrupadorId;
    }

    // Actualizar fecha de nacimiento
    actualizarFechaNacimiento($event){
        if($event && $event.value) {
            this.registro.fechaNacimiento = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }
    actualizarFechaClienteDesde($event){
        if($event && $event.value) {
            this.registro.informacionCliente.clienteDesde = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }

    // * * * * * Contactos * * * * *
    agregarContacto() {
        if(!this.registro.contactos) this.registro.contactos = [];
        var nuevoContacto = new ContactoUsuario(null, 'Nuevo contacto', null, null, null, null, null, null, null, null, null, this.auth.idEmpresaActual());
        this.registro.contactos.push(nuevoContacto);
    }

    eliminarContacto(index) {
        if(this.registro.contactos && this.registro.contactos.length > index) {
            this.registro.contactos.splice(index, 1);
        }
    }

    // * * * * * Archivos * * * * *
    // Documento
    seleccionarDocumento(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoDocumento = file.name;
            this.archivoDocumento = file;
        }
    }

    activarSeleccionDocumento(){
        document.getElementById('file-input-documento').click();
    }

    limpiarArchivoDocumento(){
        this.rutaArchivoDocumento = null;
        this.archivoDocumento = null;
    }

    descargarArchivoDocumento(){
        if(this.registro && this.registro.informacionCliente && this.registro.informacionCliente.archivoDocumento) {
            window.open(this.registro.informacionCliente.archivoDocumento, "_blank");
        }
    }

    // Recibo
    seleccionarRecibo(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoRecibo = file.name;
            this.archivoRecibo = file;
        }
    }

    activarSeleccionRecibo(){
        document.getElementById('file-input-recibo').click();
    }

    limpiarArchivoRecibo(){
        this.rutaArchivoRecibo = null;
        this.archivoRecibo = null;
    }

    descargarArchivoRecibo(){
        if(this.registro && this.registro.informacionCliente && this.registro.informacionCliente.archivoRecibo) {
            window.open(this.registro.informacionCliente.archivoRecibo, "_blank");
        }
    }

    // Imagen
    seleccionarImagen(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoImagen = file.name;
            this.archivoImagen = file;
        }
    }

    activarSeleccionImagen(){
        document.getElementById('file-input-imagen').click();
    }

    limpiarArchivoImagen(){
        this.rutaArchivoImagen = null;
        this.archivoImagen = null;
    }

    descargarArchivoImagen(){
        if(this.registro && this.registro.imagen) {
            window.open(this.registro.imagen, "_blank");
        }
    }

    // Portada
    seleccionarPortada(event: any){
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
            let file: File = fileList[0];
            this.rutaArchivoPortada = file.name;
            this.archivoPortada = file;
        }
    }

    activarSeleccionPortada(){
        document.getElementById('file-input-portada').click();
    }

    limpiarArchivoPortada(){
        this.rutaArchivoPortada = null;
        this.archivoPortada = null;
    }

    descargarArchivoPortada(){
        if(this.registro && this.registro.portada) {
            window.open(this.registro.portada, "_blank");
        }
    }

    tabTieneErrores(tab: string) {
        switch(tab) {
            case 'Datos generales': {
                if(
                    !this.registro.informacionCliente.tipo ||
                    !this.registro.nombre ||
                    !this.registro.AgrupadorId
                ) return true;
                // Validaciones individual
                if(this.registro.informacionCliente.tipo == 'individual') {
                    if(!this.registro.apellido) return true;
                }
                break;
            }
            case 'Matriz de riesgo': {
                if(
                    !this.registro.informacionCliente.tipoClienteRiesgo ||
                    !this.registro.informacionCliente.ciudadania ||
                    !this.registro.informacionCliente.paisNacimiento ||
                    !this.registro.informacionCliente.actividadGeneradoraRecursos ||
                    !this.registro.informacionCliente.paisActividadEconomica ||
                    !this.registro.informacionCliente.tipoActivosPropiosActividadCliente ||
                    !this.registro.informacionCliente.productosServiciosUtilizados ||
                    !this.registro.informacionCliente.caracter ||
                    !this.registro.informacionCliente.valorMontoAsegurado ||
                    !this.registro.informacionCliente.medioPago ||
                    !this.registro.informacionCliente.tipoIngresosOrigenRecursos
                ) return true;
                break;
            }
            case 'Datos de contacto': {
                if(
                    !this.registro.telefono1 ||
                    (!this.registro.informacionCliente.nombreContacto && this.registro.informacionCliente.tipo == 'corporativo')
                    // !this.registro.correo1
                ) return true;

                break;
            }
            case 'Datos de facturación': {
                if(
                    !this.registro.informacionCliente.nit
                ) return true;

                if(this.errorFormatoNITValido()) return true;

                break;
            }
            case 'Datos de documentación': {
                if(this.registro.informacionCliente.tipo == 'corporativo') return false;
                if(
                    (!this.registro.informacionCliente.numeroDocumento && !this.registro.informacionCliente.pasaporte)
                ) return true;
                break;
            }
            case 'Documentos':
            case 'Contactos adicionales': {
                break;
            }
            case 'Tarjetas': {
                if(this.hayErrorTarjetas()) return true;
                break;
            }
        }
        return false;
    }

    errorFormatoNITValido() {
        if(!this.registro.informacionCliente.nit) return 'Debe ingresar un número de NIT';
        var nit = this.registro.informacionCliente.nit;
        if(nit.length < 5) return 'El NIT debe contener al menos 5 dígitos';
        if(nit.includes('C') && nit.includes('F')) return 'El NIT no puede ser Consumidor Final';
        
        // Validar que el NIT solo pueda contener números, letras y guiones
        var regex = /^[a-zA-Z0-9\-]+$/;
        if(!regex.test(nit)) return 'El NIT solo puede contener números, letras y guiones';

        return null;
    }

    hayErrorTarjetas() {
        if(this.registro.tarjetas && this.registro.tarjetas.length > 0) {
            let fila = 1;
            for (const el of this.registro.tarjetas) {
                if(!el.numero || !el.vencimiento) {
                    return 'La tarjeta #' + fila + ' tiene campos incompletos.';
                }
                fila++;
            }
        }
        return null;
    }

    calcularParamsEspecialesAgrupadores() {
        let params = '';
        params += '&soloActivos=1';
        return params;
    }

    calcularParamsEspecialesCreditos(): string {
        var paramsEspeciales = '';

        paramsEspeciales += '&ClienteId=' + this.registro.id;
        paramsEspeciales += '&pagado=0';

        return paramsEspeciales;
    }

    triggerValidarDocumentos(tipo) {
        let nitSearch = this.registro.informacionCliente.nit;
        let numeroDocumentoSearch = this.registro.informacionCliente.numeroDocumento;
        let pasaporteSearch = this.registro.informacionCliente.pasaporte;
        setTimeout(() => {
            if (
                (tipo == 'nit' && nitSearch == this.registro.informacionCliente.nit) ||
                (tipo == 'numeroDocumento' && numeroDocumentoSearch == this.registro.informacionCliente.numeroDocumento) ||
                (tipo == 'pasaporte' && pasaporteSearch == this.registro.informacionCliente.pasaporte)
            ) {
                this.validarDocumentos();
            }
        }, 500);
    }

    async validarDocumentos(){
        try {
            let res = await this.clientesService.validarDocumentosCliente(this.registro.informacionCliente.nit, this.registro.informacionCliente.numeroDocumento, this.registro.informacionCliente.pasaporte, this.registro.InformacionClienteId);
            if(!res.error) {
                this.nitValido = res.data.result.nitValido;
                this.numeroDocumentoValido = res.data.result.numeroDocumentoValido;
                this.pasaporteValido = res.data.result.pasaporteValido;
            }
            else {
                this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
                this.nitValido = false;
                this.numeroDocumentoValido = false;
                this.pasaporteValido = false;
            }
        } catch(error) {

        }
    }
}