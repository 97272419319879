import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { CobrosService } from '../cobros.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as _moment from 'moment';
import { PlanillaCobro } from 'src/app/models/planillaCobro';
import { DocumentoCobro, FormaCobroMultiple } from 'src/app/models/documentoCobro';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { DocumentoCredito } from 'src/app/models/documentoCredito';
import { AplicacionDocumentoCredito } from 'src/app/models/aplicacionDocumentoCredito';
import { Banco } from 'src/app/models/Banco';
const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    ids: number[],
    documentos: DocumentoCobro[],
    creditosDisponibles: DocumentoCredito[],
    aplicacionCredito: AplicacionDocumentoCredito,
}

@Component({
    selector: 'dialog-pasar-cobrado',
    templateUrl: './dialog-pasar-cobrado.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogPasarCobrado {
    iconDelete = iconDelete;

    apiURLs = new APIUrls();
    cargando: boolean = false;
    validado: boolean = false;
    fechaPagoDate: Date;
    fechaPago: string;
    bancos: Banco[];
    banco: string;
    numeroCheque: string;
    formaCobro: string;
    PlanillaCobroId: number;
    planillas: PlanillaCobro[];
    erroresSecuencia: string[];
    usaFormaPagoMultiple: boolean = false;
    multiplesFormasPagoArray: FormaCobroMultiple[];
    creditoSeleccionado: DocumentoCredito;
    errorAplicacion: string;
    
    constructor(
        public dialogRef: MatDialogRef<DialogPasarCobrado>,
        public service: CobrosService,
        public auth: AuthService,
        public libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() { 
        // Arreglar estilo
        var dialog = document.getElementById("dialogInscripcionTorneo");
        var dialogContainer = null;
        if(dialog) dialogContainer = dialog.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer = dialogContainer.parentElement;
        if(dialogContainer) dialogContainer.className += ' dialogOverflow';

        this.fechaPagoDate = new Date();
        this.fechaPago = this.actualizarFecha({ value: { _d: this.fechaPagoDate } });
        this.inicializar();
    }

    async inicializar() {
        await this.validarErroresSecuenciaPagos();
        this.obtenerPlanillasDia();
        this.obtenerCreditosDisponibles();
        this.obtenerBancos();
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        let multiplesFormasPago = null;
        if(this.multiplesFormasPagoArray) multiplesFormasPago = JSON.stringify(this.multiplesFormasPagoArray);

        if(this.usaFormaPagoMultiple) {
            this.formaCobro = this.multiplesFormasPagoArray[0].formaCobro;
        }

        this.dialogRef.close({
            estado: 'confirmar',
            fechaPago: this.fechaPago,
            banco: this.banco,
            numeroCheque: this.numeroCheque,
            formaCobro: this.formaCobro,
            multiplesFormasPago: multiplesFormasPago,
            usaFormaPagoMultiple: this.usaFormaPagoMultiple,
            PlanillaCobroId: this.PlanillaCobroId,
            aplicacionCredito: this.data.aplicacionCredito,
        });
    }

    async obtenerPlanillasDia() {
        this.cargando = true;
        var res = await this.service.obtenerTodosPlanillasFecha(this.fechaPago);
        if(!res.error) {
            this.planillas = res.data.registros;
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    async validarErroresSecuenciaPagos() {
        this.cargando = true;
        var res = await this.service.validarErroresSecuenciaPagos(this.data.ids);
        if(!res.error) {
            this.erroresSecuencia = res.data.errores;
            if(this.erroresSecuencia.length > 0) {
                let mensajeFinal = 'Los siguientes documentos no se pueden aplicar: ' + this.erroresSecuencia.join('\n');
                this.libraryService.crearNotificacionGrande(mensajeFinal, 'danger');
                this.cerrar();
            }
            else {
                this.validado = true;
            }
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    }

    registroValido(): boolean {
        // if(!this.formaCobro) return false;

        if(['nota-credito', 'nota-devolucion', 'prima-deposito'].includes(this.formaCobro) && !this.usaFormaPagoMultiple && this.data.aplicacionCredito) {
            var aplicacion = this.data.aplicacionCredito;
            if(!aplicacion || !this.creditoSeleccionado) return false;
            
            if(!aplicacion.monto || !aplicacion.DocumentoCreditoId || !aplicacion.DocumentoCobroId) return false;
    
            if(aplicacion.monto <= 0) return false;
            if(aplicacion.monto > this.creditoSeleccionado.creditoRestante) {
                this.errorAplicacion = 'El monto a aplicar es mayor que el crédito restante.';
                return false;
            }
            if(aplicacion.monto > this.data.documentos[0].primaTotal) {
                this.errorAplicacion = 'El monto a aplicar es mayor que la prima total.';
                return false;
            }
    
            this.errorAplicacion = null;
        }

        if(this.usaFormaPagoMultiple && this.multiplesFormasPagoArray) {
            for (const el of this.multiplesFormasPagoArray) {
                if(['nota-credito', 'nota-devolucion', 'prima-deposito'].includes(el.formaCobro) && el.aplicacionCredito) {
                    if(el.creditoSeleccionado && el.monto > el.creditoSeleccionado.creditoRestante) return false;
                }
            }
        }

        if(this.usaFormaPagoMultiple && this.totalMultiplesFormas(this.data.documentos) != this.primaTotalDocumentos()) return false;

        return true;
    }

    async obtenerCreditosDisponibles() {
        this.cargando = true;
        if(this.data.ids && this.data.ids.length == 1) {
            // Obtener registro
            var res = await this.service.obtenerDocumentosCreditosDisponiblesCobros(this.data.ids[0]);
            if(!res.error) {
                this.data.creditosDisponibles = res.data.documentosCreditos;
                let aplicacionCredito = new AplicacionDocumentoCredito(null, 'credito', null, null, null, null, null, this.data.ids[0], null, this.auth.idUsuarioActual(), this.data.documentos[0].MonedaId, this.auth.idEmpresaActual());
                aplicacionCredito.moneda  = this.data.documentos[0].moneda;
                this.data.aplicacionCredito = aplicacionCredito;
            }
        }
        this.cargando = false;
    }

    actualizarFecha($event){
        if($event && $event.value) {
            this.fechaPago = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
            this.obtenerPlanillasDia();
            return this.fechaPago;
        }
        return null;
    }

    agregarFormaCobroMultiple(formaCobro, numeroCheque) {
        if(!this.multiplesFormasPagoArray) this.multiplesFormasPagoArray = [];
        let formaCobroMultiple = new FormaCobroMultiple(null, null, null, null);
        let aplicacionCredito = new AplicacionDocumentoCredito(null, 'credito', null, null, null, null, null, this.data.documentos[0].id, null, this.auth.idUsuarioActual(), this.data.documentos[0].MonedaId, this.auth.idEmpresaActual());
        aplicacionCredito.moneda  = this.data.documentos[0].moneda;
        formaCobroMultiple.aplicacionCredito = aplicacionCredito;
        if(formaCobro) formaCobroMultiple.formaCobro = formaCobro;
        if(numeroCheque) formaCobroMultiple.numeroDocumento = numeroCheque;
        this.multiplesFormasPagoArray.push(formaCobroMultiple);
    }

    eliminarFormaCobroMultiple(index: number) {
        if(this.multiplesFormasPagoArray) this.multiplesFormasPagoArray.splice(index, 1);
    }

    agregarPrimeraLineaMultiplesFormasPago() {
        if(this.usaFormaPagoMultiple && this.formaCobro && (!this.multiplesFormasPagoArray || this.multiplesFormasPagoArray.length == 0)) {
            this.multiplesFormasPagoArray = [];
            this.agregarFormaCobroMultiple(this.formaCobro, this.numeroCheque);
        }
    }

    totalMultiplesFormas(documentos: DocumentoCobro[]) {
        let total = 0;
        if(this.multiplesFormasPagoArray) {
            for (const el of this.multiplesFormasPagoArray) total += el.monto;
        }
        return this.libraryService.aproximarDecimal(total, 2);
    }
    
    restanteMultiplesFormas(documentos: DocumentoCobro[]) {
        let restante = this.primaTotalDocumentos() - this.totalMultiplesFormas(documentos);
        return restante;
    }

    primaTotalDocumentos() {
        let total = 0;
        if(this.data.documentos) {
            for (const el of this.data.documentos) {
                total += el.primaTotal;
            }
        }
        return this.libraryService.aproximarDecimal(total, 2);
    }

    actualizarCreditoSeleccionado(id: number) {
        var index = this.libraryService.indexOf(this.data.creditosDisponibles, 'id', id);
        if(index >= 0) {
            this.creditoSeleccionado = this.data.creditosDisponibles[index];
        }
    }

    actualizarCreditoSeleccionadoFormaCobro(id: number, formaCobro: FormaCobroMultiple) {
        var index = this.libraryService.indexOf(this.data.creditosDisponibles, 'id', id);
        if(index >= 0) {
            formaCobro.creditoSeleccionado = this.data.creditosDisponibles[index];
        }
    }

    async obtenerBancos() {
        this.cargando = true;
        var res = await this.service.obtenerBancos();
        if(!res.error) {
            this.bancos = res.data.registros;

            // Ordenamos alfabéticamente los bancos
            this.bancos.sort((a, b) => a.nombre.localeCompare(b.nombre));
        }
        else this.libraryService.crearNotificacion(res.error.mensajeError, 'danger');
        this.cargando = false;
    
    }
}