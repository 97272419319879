import { Component, Inject } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef, MAT_DATE_LOCALE, DateAdapter, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { APIUrls } from 'src/app/api/apiUrls';
import { LibraryService } from 'src/app/library/library.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import iconDelete from '@iconify/icons-fa-solid/trash';
import { GenericDialogConfirmacion } from 'src/app/library/generic-dialog-confirmacion/generic-dialog-confirmacion.component';
import { AclaracionPoliza } from 'src/app/models/aclaracionPoliza';
import { Poliza } from 'src/app/models/poliza';
export const MY_FORMATS = {
    parse: {
        dateInput: 'D/M/YYYY',
    },
    display: {
        dateInput: 'D/M/YYYY',
        dateA11yLabel: 'D/M/YYYY',
        monthYearLabel: 'M/YYYY',
        monthYearA11yLabel: 'M/YYYY',
    },
};

export interface DialogData {
    aclaracion: AclaracionPoliza,
    poliza: Poliza,
}

@Component({
    selector: 'dialog-nuevo-aclaracion-poliza',
    templateUrl: './dialog-nuevo-aclaracion-poliza.component.html',
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-GT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class DialogNuevoAclaracionPoliza {
    apiURLs = new APIUrls();
    modoCreacion: boolean = true;
    modoDetalle: boolean = false;
    modoEdicion: boolean = false;
    aclaracion: AclaracionPoliza;
    iconDelete = iconDelete;

    constructor(
        public dialogRef: MatDialogRef<DialogNuevoAclaracionPoliza>,
        public service: AuthService,
        public auth: AuthService,
        public dialog: MatDialog,
        private libraryService: LibraryService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit() {
        this.aclaracion = this.data.aclaracion;
        this.modoCreacion = this.aclaracion.id == null;
        this.modoDetalle = false;
        this.modoEdicion = !this.modoCreacion;
    }

    cerrar() {
        this.dialogRef.close(null);
    }

    confirmar() {
        this.dialogRef.close({
            accion: 'confirmar',
            aclaracion: this.aclaracion,
        });
    }

    abrirEliminar() {
        const dialogRef = this.dialog.open(GenericDialogConfirmacion, {
            data: {
                titulo: 'Eliminar aclaración',
                mensaje: '¿Está seguro de que desea eliminar esta aclaración?',
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 'confirmar') {
                this.eliminar();
            }
        });
    }

    eliminar() {
        this.dialogRef.close({
            accion: 'eliminar',
            aclaracion: this.aclaracion,
        });
    }

    registroValido(): boolean {
        var aclaracion = this.aclaracion;
        if(!aclaracion) return false;
        if(!aclaracion.observaciones || aclaracion.observaciones.trim() == '') return false;

        return true;
    }

    actualizarFecha($event, campo){
        if($event && $event.value) {
            this.aclaracion[campo] = this.libraryService.convertirFecha($event.value._d, 'date', 'YYYY-MM-DD');
        }
    }
}
