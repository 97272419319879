const LOCAL_DEV = false;
const PROD_DB = true;
const VERSION = '1.0.59' + (PROD_DB ?  '-PROD' : '-DEV');

export class APIUrls {
    public readUsername = 'api-read';
    public readPassword = 'ASD324k!123';
    public writeUsername = 'api-write';
    public writePassword = '12jklSAFDqo1o#';
    public deleteUsername = 'api-delete';
    public deletePassword = 'a9sfASFDqmcmx!2';
    public version = VERSION;
    public defaultTimezone = 'America/Guatemala';

    public LOCAL_DEV = LOCAL_DEV;
    public baseURL = LOCAL_DEV ?
        'http://localhost:9018/api/' :
        PROD_DB ?
            'https://api.prointelsis.com/api/' :
            'https://api-sandbox.prointelsis.com/api/'
    ;
    public pdfURL = 'https://html-to-pdf.azurewebsites.net/api/HttpTriggerCSharp1?code=HecijiGy7QhVfAiW6T13UhGpzrXE3p7q9rY0XSLso1Q1IMgYBYr1DA==';
    public logoURL = 'https://apollo-virginia.akamaized.net/v1/files/4eh1u7nnsei41-GT/image%3Bp%3Dfull';

    public nombreSitio = 'PROSIS';
    public nombreSitioTitle = ' - ' + this.nombreSitio;

    public usuariosURL = 'usuarios';
    public controlCalidadURL = 'controlCalidad';
    public areasURL = 'areas';
    public avisosURL = 'avisos';
    public empresasURL = 'empresas';
    public informacionClientesURL = 'informacionClientes';
    public formulariosClientesURL = 'formulariosClientes';
    public notificacionesURL = 'notificaciones';
    public monedasURL = 'monedas';
    public impuestosEmpresaURL = 'impuestosEmpresas';
    public agrupadoresURL = 'agrupadores';
    public aseguradorasURL = 'aseguradoras';
    public ramosURL = 'ramos';
    public coberturasURL = 'coberturas';
    public razonesRamosURL = 'razonesRamos';
    public tiposSiniestrosURL = 'tiposSiniestros';
    public incisosURL = 'incisos';
    public tiposCertificadoURL = 'tiposCertificados';
    public tiposReclamosURL = 'tiposReclamos';
    public productosURL = 'productos';
    public plantillasRamosURL = 'plantillasRamos';
    public areasRamosURL = 'areasRamos';
    public deduciblesURL = 'deducibles';
    public polizasURL = 'polizas';
    public certificadosURL = 'certificados';
    public declaracionesURL = 'declaraciones';
    public documentosCobrosURL = 'documentosCobros';
    public documentosCreditosURL = 'documentosCreditos';
    public planillasCobrosURL = 'planillasCobros';
    public planillasLiquidacionURL = 'planillasLiquidaciones';
    public prospectosURL = 'prospectos';
    public razonesPerdidasURL = 'razonesPerdidas';
    public ocupacionesURL = 'ocupaciones';
    public cobradoresURL = 'cobradores';
    public plantillasMapasProcesosURL = 'plantillasMapasProcesos';
    public plantillasSolicitudesURL = 'plantillasSolicitudes';
    public mapasProcesosURL = 'mapasProcesos';
    public mensajeriaURL = 'mensajeria';
    public reclamosURL = 'reclamos';
    public facturasReclamosURL = 'facturasReclamos';
    public solicitudesPolizasURL = 'solicitudesPolizas';
    public cambiosEstadosReclamosURL = 'cambiosEstadosReclamos';
    public cambiosEstadosMensajeriasURL = 'cambiosEstadosMensajerias';
    public cambiosEstadosPolizasURL = 'cambiosEstadosPolizas';
    public cambiosEstadosCertificadosURL = 'cambiosEstadosCertificados';
    public documentosMensajeriasURL = 'documentosMensajerias';
    public documentosReclamosURL = 'documentosReclamos';
    public documentosEnviosReclamosURL = 'documentosEnviosReclamos';
    public documentosPolizasURL = 'documentosPolizas';
    public enviosSolicitudesURL = 'enviosSolicitudes';
    public solicitudesClientesURL = 'solicitudesClientes';
    public seguimientosProspectosURL = 'seguimientosProspectos';
    public misAnalyticsURL = 'misAnalytics';
    public plantillasGestionesCobrosURL = 'plantillasGestionesCobros';
    public gestionesCobrosURL = 'gestionesCobros';
    public comisionesAseguradorasProductosURL = 'comisionesAseguradorasProductos';
    public paisesURL = 'paises';
    public departamentosURL = 'departamentos';
    public municipiosURL = 'municipios';
    public zonasURL = 'zonas';
    public bancosURL = 'bancos';
    public tipoVehiculosURL = 'tiposVehiculos';
    public direccionesClientesURL = 'direccionesClientes';
    public direccionesCertificadosURL = 'direccionesCertificados';
    public marcasVehiculosURL = 'marcasVehiculos';
    public lineasVehiculosURL = 'lineasVehiculos';

    public equivalenciasProspectos = [
        { pais: 'gt', EmpresaId: 1, urlGracias: 'https://prointelseguros.com/guatemala/gracias/' },
        { pais: 'sv', EmpresaId: 2, urlGracias: 'https://prointelseguros.com/elsalvador/gracias/' },
        { pais: 'hn', EmpresaId: 1, urlGracias: 'https://prointelseguros.com/honduras/gracias/' },
        { pais: 'cr', EmpresaId: 1, urlGracias: 'https://prointelseguros.com/costarica/gracias/' },
        { pais: 'latam', EmpresaId: 1, urlGracias: 'https://prointelseguros.com/gracias/' },
    ];

    quillModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['clean'],
            ['link']
        ]
    };

    noRedirectRoutes = [
      '/contacto/formulario-contacto',
    ]

    public meses = [
        { id: 1, nombre: 'Enero' },
        { id: 2, nombre: 'Febrero' },
        { id: 3, nombre: 'Marzo' },
        { id: 4, nombre: 'Abril' },
        { id: 5, nombre: 'Mayo' },
        { id: 6, nombre: 'Junio' },
        { id: 7, nombre: 'Julio' },
        { id: 8, nombre: 'Agosto' },
        { id: 9, nombre: 'Septiembre' },
        { id: 10, nombre: 'Octubre' },
        { id: 11, nombre: 'Noviembre' },
        { id: 12, nombre: 'Diciembre' },
    ];

    public tiposReclamos = [
        { id: 'perdida-total', nombre: 'Pérdida total' },
        { id: 'robo-total', nombre: 'Robo total' },
        { id: 'perdida-parcial', nombre: 'Pérdida parcial' },
    ];

    public tiposDispositivosSeguridad = [
        { id: null, nombre: '' },
        { id: 'GPS', nombre: 'GPS' },
        { id: 'Lo Jack', nombre: 'Lo Jack' },
    ];

    public condicionesDispositivosSeguridad = [
        { id: null, nombre: '' },
        { id: 'COM', nombre: 'Comodato' },
        { id: 'MAN', nombre: 'Mancomunado' },
        { id: 'PAR', nombre: 'Particular' },
        { id: 'DEF', nombre: 'Deferido' },
    ];

    public reglasPaises = [
        { id: 'GT', nombre: 'Guatemala' },
        { id: 'SV', nombre: 'El Salvador' },
        { id: 'CR', nombre: 'Costa Rica' },
        { id: 'HN', nombre: 'Honduras' },
    ];

    public fechasRapidas = [
        { id: 'mes-actual', nombre: 'Mes actual' },
        { id: 'mes-anterior', nombre: 'Mes anterior' },
        { id: 'ano-actual', nombre: 'Año actual' },
        { id: 'ano-anterior', nombre: 'Año anterior' },
        { id: 'rango-fechas', nombre: 'Rango de fechas' },
    ];

    public rangosFechas = [
        { id: 'mes-actual', nombre: 'Mes actual' },
        { id: 'mes-pasado', nombre: 'Mes pasado' },
        { id: 'ano-actual', nombre: 'Año actual' },
        { id: 'ano-pasado', nombre: 'Año pasado' },
        { id: 'esta-semana', nombre: 'Esta semana' },
        { id: 'ultima-semana', nombre: 'Semana pasada' },
        { id: 'personalizado', nombre: 'Personalizado' },
    ];

    public clasificaciones = [
        { id: 'pequeno', nombre: 'Pequeño' },
        { id: 'mediano', nombre: 'Mediano' },
        { id: 'grande', nombre: 'Grande' },
        { id: 'sin-clasificar', nombre: 'Sin clasificar' }
    ];

    public puntuacionesControlCalidad = [
        { id: 1, nombre: 1 },
        { id: 2, nombre: 2 },
        { id: 3, nombre: 3 },
        { id: 4, nombre: 4 },
        { id: 5, nombre: 5 },
        { id: 6, nombre: 6 },
        { id: 7, nombre: 7 },
        { id: 8, nombre: 8 },
        { id: 9, nombre: 9 },
        { id: 10, nombre: 10 },
    ];

    public lineasAgrupadores = [
        { id: 'corporativo', nombre: 'Corporativo' },
        { id: 'personal', nombre: 'Personal' }
    ];

    public tiposPermisosAccesoClientes = [
        { id: 'agrupador', nombre: 'Todo el agrupador' },
        { id: 'cliente', nombre: 'Cliente específico' },
        { id: 'poliza', nombre: 'Póliza específica' },
        { id: 'certificado', nombre: 'Certificado específico' },
    ];

    public tiposDirecciones = [
        { id: 'principal', nombre: 'Principal' },
        { id: 'trabajo', nombre: 'Trabajo' },
        { id: 'otro', nombre: 'Otro' },
    ];

    public clases = [
        { id: 'A', nombre: 'A' },
        { id: 'B', nombre: 'B' },
        { id: 'C', nombre: 'C' },
    ];

    public tiposClientes = [
        { id: 'individual', nombre: 'Individual' },
        { id: 'corporativo', nombre: 'Corporativo' },
    ];

    public tiposDocumentos = [
        { id: 'DPI', nombre: 'DPI' },
        { id: 'CEDULA', nombre: 'Cédula' },
        { id: 'PASAPORTE', nombre: 'Pasaporte' },
    ];

    public tiposEnviosMensajeria = [
        { id: 'digital', nombre: 'Digital' },
        { id: 'fisico', nombre: 'Físico' },
    ];

    public tiposBeneficiarios = [
        { id: 'beneficiario', nombre: 'Beneficiario' },
        { id: 'dependiente', nombre: 'Dependiente' },
        { id: 'beneficiario-dependiente', nombre: 'Beneficiario y dependiente' },
        { id: 'no-aplica', nombre: 'No aplica' },
    ];

    public estadosSolicitudesClientes = [
        { id: 'pendiente', nombre: 'Pendiente' },
        { id: 'en-proceso', nombre: 'En proceso' },
        { id: 'finalizado', nombre: 'Finalizado' },
    ];

    public tiposSolicitudesPolizas = [
        { id: 'poliza-nueva', nombre: 'Nueva póliza' },
        { id: 'renovacion', nombre: 'Renovación' },
        { id: 'modificacion', nombre: 'Modificación' },
        { id: 'exclusion', nombre: 'Exclusión' },
        { id: 'inclusion', nombre: 'Inclusión' },
        { id: 'declaraciones', nombre: 'Declaraciones' },
        { id: 'cancelacion', nombre: 'Cancelación' },
        { id: 'reproceso', nombre: 'Reproceso' },
    ];

    public parentescosBeneficiario = [
        { id: 'hijo', nombre: 'Hijo(a)', abreviatura: 'HI' },
        { id: 'padre', nombre: 'Padre', abreviatura: 'P' },
        { id: 'madre', nombre: 'Madre', abreviatura: 'M' },
        { id: 'hermano', nombre: 'Hermano(a)', abreviatura: 'HE' },
        { id: 'conyuge', nombre: 'Cónyuge', abreviatura: 'C' },
        { id: 'otro', nombre: 'Otro', abreviatura: 'HI' },
    ];

    public parentescosDependiente = [
        { id: 'padre', nombre: 'Padre', abreviatura: 'P' },
        { id: 'madre', nombre: 'Madre', abreviatura: 'M' },
        { id: 'hermano', nombre: 'Hermano(a)', abreviatura: 'HE' },
        { id: 'hijo', nombre: 'Hijo(a)', abreviatura: 'HI' },
        { id: 'conyuge', nombre: 'Cónyuge', abreviatura: 'C' },
        { id: 'suegro', nombre: 'Suegro(a)', abreviatura: 'C' },
    ];

    public tiposPolizas = [
        { id: 'local', nombre: 'Local' },
        { id: 'facultativo', nombre: 'Facultativo' },
        { id: 'fronting', nombre: 'Fronting' },
        { id: 'coaseguros', nombre: 'Coaseguro' },
        { id: 'contributivo', nombre: 'Contributivo' },
        { id: 'no-contributivo', nombre: 'No contributivo' },
        { id: 'nombramiento', nombre: 'Nombramiento' },
    ];

    public caracteresPolizas = [
        { id: 'individual', nombre: 'Individual', riesgo: 1 },
        { id: 'colectiva', nombre: 'Colectiva', riesgo: 1 },
        // { id: 'colectiva-declarativa', nombre: 'Colectiva declarativa', riesgo: 1 },
        { id: 'declarativa', nombre: 'Declarativa', riesgo: 1 },
        { id: 'eventual', nombre: 'Eventual', riesgo: 1 },
        { id: 'leasing', nombre: 'Leasing', riesgo: 2 },
    ];

    public estadosPolizas = [
        { id: 'emision', nombre: 'Emisión', mostrarPorDefecto: true, cambiosPermitidos: ['nueva', 'traslado'] },
        { id: 'nueva', nombre: 'Nueva', mostrarPorDefecto: true, cambiosPermitidos: ['traslado', 'renovada', 'historica'] },
        { id: 'traslado', nombre: 'Traslado', mostrarPorDefecto: true, cambiosPermitidos: ['renovada', 'historica'] },
        { id: 'renovada', nombre: 'Renovada', mostrarPorDefecto: true, cambiosPermitidos: ['historica'] },
        { id: 'historica', nombre: 'Histórica', mostrarPorDefecto: false, cambiosPermitidos: [] },
        { id: 'cancelada', nombre: 'Cancelada', mostrarPorDefecto: false, cambiosPermitidos: ['nueva', 'traslado', 'renovada'] },
    ];

    public tiposGestionesCobros = [
        { id: 'primas-pendientes', nombre: 'Detalle de primas pendientes' },
        { id: 'cheque-rechazado', nombre: 'Notificación de cheque rechazado' },
        { id: 'aviso-precaducidad', nombre: 'Aviso de precaducidad' },
        { id: 'aviso-caducidad', nombre: 'Aviso de caducidad' },
        { id: 'cobro-tarjeta-denegado', nombre: 'Aviso de cobro de tarjeta denegado' },
        { id: 'aviso-fecha-vencimiento-tarjeta', nombre: 'Aviso de fecha de vencimiento de tarjeta próxima a vencer' },
    ];

    public tiposAdjuntosGestiones = [
        { id: 'excel', nombre: 'Excel' },
        { id: 'pdf', nombre: 'PDF' },
    ];

    public tiposBasesDeducibles = [
        { id: '', nombre: 'No definido', tipoRamo: 'todos', campos: [] },
        { id: 'AAC', nombre: 'AAC - Año Calendario', tipoRamo: 'personas', campos: ['monto-fijo'] },
        { id: 'AAP', nombre: 'AAP - Año Póliza', tipoRamo: 'personas', campos: ['monto-fijo'] },
        { id: 'FXE', nombre: 'FXE - Fijo por evento', tipoRamo: 'danios', campos: ['minimo', 'maximo', 'monto-fijo'] },
        { id: 'MXU', nombre: 'MXU - Montos por ubicación', tipoRamo: 'danios', campos: ['minimo', 'maximo', 'monto-fijo'] },
        { id: 'PAP', nombre: 'PAP - Perdida final ajustada', tipoRamo: 'danios', campos: ['porcentaje', 'minimo', 'maximo', 'monto-fijo'] },
        { id: 'PEO', nombre: 'PEO - Pendiente', tipoRamo: 'todos', campos: ['monto-fijo'] },
        { id: 'PER', nombre: 'PER - Pérdida', tipoRamo: 'todos', campos: ['dias', 'monto-fijo'] },
        { id: 'SUA', nombre: 'SUA - Suma Asegurada', tipoRamo: 'danios', campos: ['porcentaje', 'minimo', 'maximo', 'monto-fijo'] },
        { id: 'VCO', nombre: 'VCO - Pendiente', tipoRamo: 'danios', campos: ['monto-fijo'] },
        { id: 'VEM', nombre: 'VEM - Valor de Embarque', tipoRamo: 'danios', campos: ['porcentaje', 'minimo', 'monto-fijo'] },
        { id: 'VEQ', nombre: 'VEQ - Valor de Equipo', tipoRamo: 'danios', campos: ['porcentaje', 'monto-fijo', 'minimo', 'maximo'] },
        { id: 'VIN', nombre: 'VIN - Valor Indemnizable', tipoRamo: 'danios', campos: ['porcentaje', 'minimo', 'maximo', 'monto-fijo'] },

        // { id: null, nombre: 'Días' },
        // { id: 'anio-calendario', nombre: 'Año calendario' },
        // { id: 'anio-poliza', nombre: 'Año póliza' },
        // { id: 'sobre-suma-asegurada', nombre: 'Sobre suma asegurada' },
        // { id: 'sobre-suma-asegurada-ubicacion-afectada', nombre: 'Sobre suma asegurada de la ubicación afectada' },
        // { id: 'perdida-final-indemnizable', nombre: 'Pérdida final indemnizable' },
        // { id: 'perdida-final-ajustada', nombre: 'Pérdida final ajustada' },
        // { id: 'fijo-por-evento', nombre: 'Fijo por evento' },
        // { id: 'sobre-valor-embarque', nombre: 'Sobre valor del embarque' },
    ];

    public personasAfectadasReclamos = [
        { id: 'titular', nombre: 'El titular es afectado' },
        { id: 'dependiente', nombre: 'Un dependiente es afectado' },
        { id: 'terceros', nombre: 'Un tercero es afectado' },
    ];

    public tiposAutorizaciones = [
        { id: 'medicamentos', nombre: 'Medicamentos' },
        { id: 'examenes', nombre: 'Exámenes' },
        { id: 'procedimiento-programado', nombre: 'Procedimiento programado' },
    ];

    public estadosReclamos = [
        { id: 'activo', nombre: 'Activo', emergencias: true, cambiosPermitidos: ['en-espera', 'abono-a-deducible', 'pagado', 'denegado', 'sin-efecto', 'cerrado'] },
        { id: 'en-espera', nombre: 'En espera', emergencias: false, cambiosPermitidos: ['activo', 'en-espera', 'abono-a-deducible', 'pagado', 'denegado', 'sin-efecto', 'cerrado'] },
        { id: 'abono-a-deducible', nombre: 'Abono a deducible', emergencias: false, cambiosPermitidos: [] },
        { id: 'pagado', nombre: 'Pagado', emergencias: false, cambiosPermitidos: [] },
        { id: 'denegado', nombre: 'Denegado', emergencias: false, cambiosPermitidos: ['activo'] },
        { id: 'sin-efecto', nombre: 'Sin efecto', emergencias: false, cambiosPermitidos: ['activo'] },
        { id: 'cerrado', nombre: 'Cerrado', emergencias: true, cambiosPermitidos: [] },
    ];

    public estadosControlCalidad = [
        { id: 'pendiente', nombre: 'Pendiente' },
        { id: 'revisada', nombre: 'Revisada' },
        { id: 'corregida', nombre: 'Corregida' },
    ];

    public estadosMapasProcesos = [
        { id: 'en-proceso', nombre: 'En proceso' },
        { id: 'completado', nombre: 'Completado' },
        { id: 'cancelado', nombre: 'Cancelado' },
    ];

    public estadosMensajeria = [
        { id: 'area-encargada', nombre: 'Área encargada' },
        { id: 'recepcion', nombre: 'Recepción' },
        { id: 'mensajero', nombre: 'Mensajero' },
        { id: 'cliente', nombre: 'Cliente' },
        { id: 'proveedor', nombre: 'Proveedor' },
    ];

    public etapasProspectos = [
        { id: 'nuevo', nombre: 'Nuevo' },
        //{ id: 'cotizacion', nombre: 'Cotización enviada' },
        { id: 'negociacion', nombre: 'En negociación' },
        { id: 'pospuesto', nombre: 'Pospuesto' },
        { id: 'perdido', nombre: 'Perdido' },
        { id: 'ganado', nombre: 'Ganado' },
    ];

    public frecuenciasPagos = [
        { id: 'mensual', nombre: 'Mensual' },
        { id: 'bimestral', nombre: 'Bimestral' },
        { id: 'trimestral', nombre: 'Trimestral' },
        { id: 'semestral', nombre: 'Semestral' },
        { id: 'anual', nombre: 'Anual' },
    ];

    public tiposPasosMapaProceso = [
        { id: 'obligatorio', nombre: 'Obligatorio' },
        { id: 'opcional', nombre: 'Opcional' },
    ];

    public tiposMensajeria = [
        { id: 'envio', nombre: 'Envío' },
        { id: 'recepcion', nombre: 'Recepción' },
    ];

    public tiposMensajeriaEmision = [
        { id: 'cliente', nombre: 'Cliente existente' },
        { id: 'aseguradora', nombre: 'Aseguradora' },
        { id: 'otro', nombre: 'Otro' },
    ];

    public tiposPagosReclamos = [
        { id: 'cheque',                     nombre: 'Cheque', ocultarCreacion: false },
        { id: 'transferencia',              nombre: 'Transferencia', ocultarCreacion: false },
        { id: 'deposito',                   nombre: 'Depósito', ocultarCreacion: false },
    ]

    public formasCobro = [
        { id: 'nota-credito',               nombre: 'Nota de crédito', ocultarCreacion: false },
        { id: 'deposito-bancario',          nombre: 'Depósito bancario', ocultarCreacion: false },
        { id: 'efectivo',                   nombre: 'Efectivo', ocultarCreacion: false },
        { id: 'cheque',                     nombre: 'Cheque', ocultarCreacion: false },
        { id: 'paga-directo-aseguradora',   nombre: 'Paga directo en aseguradora', ocultarCreacion: false },
        { id: 'tarjeta-credito',            nombre: 'Tarjeta de crédito', ocultarCreacion: false },
        { id: 'cobra-aseguradora',          nombre: 'Cobra aseguradora', ocultarCreacion: true },
        { id: 'cobra-corredor-seguros',     nombre: 'Cobra corredor de seguros', ocultarCreacion: true },
        { id: 'efectivo-cheque',            nombre: 'Efectivo o cheque', ocultarCreacion: true },
        { id: 'transferencia',              nombre: 'Transferencia', ocultarCreacion: false },
        { id: 'cargo-cuenta',               nombre: 'Cargo a cuenta', ocultarCreacion: false },
        { id: 'cargo-automatico',           nombre: 'Cargo automático', ocultarCreacion: true },
        { id: 'nota-devolucion',            nombre: 'Nota de devolución', ocultarCreacion: true },
        { id: 'prima-deposito',             nombre: 'Prima en depósito', ocultarCreacion: false },
    ];

    public formasCobroCreacion = this.formasCobro.filter(x => !x.ocultarCreacion);

    public tiposCuentasBancarias = [
        { id: 'monetarios', nombre: 'Monetarios' },
        { id: 'ahorros', nombre: 'Ahorros' },
        { id: 'tarjeta-credito', nombre: 'Tarjeta de crédito' },
    ];

    public numerosPagos = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    public horasVigencia = [
        { id: '0-23', nombre: '00:00 a 24:00hrs.' },
        { id: '11-12', nombre: 'de 12:00 a 12:00hrs.' },
    ];

    public tiposCamposCertificados = [
        { id: 'texto', nombre: 'Texto' },
        { id: 'fecha', nombre: 'Fecha' },
        { id: 'porcentaje', nombre: 'Porcentaje' },
        { id: 'numerico', nombre: 'Numérico' },
        { id: 'moneda', nombre: 'Moneda' },
    ];

    public estadosCertificados = [
        { id: 'activo', nombre: 'Activo', abreviatura: 'ACT' },
        { id: 'inactivo', nombre: 'Inactivo', abreviatura: 'INA' },
        { id: 'incluido', nombre: 'Incluido', abreviatura: 'INC' },
        { id: 'excluido', nombre: 'Excluido', abreviatura: 'EXC' },
    ];

    public estadosIncisosCertificados = [
        { id: 'activo', nombre: 'Activo', abreviatura: 'A' },
        { id: 'incluido', nombre: 'Incluido', abreviatura: 'I' },
        { id: 'excluido', nombre: 'Excluido', abreviatura: 'E' },
    ];

    public estadosSolicitudesPolizas = [
        { id: 'activo', nombre: 'Activo', color: '#138708', cambiosPermitidos: ['cancelado', 'en-espera', 'finalizado'] },
        { id: 'finalizado', nombre: 'Finalizado', color: 'blue', cambiosPermitidos: [] },
        { id: 'cancelado', nombre: 'Cancelado', color: 'red', cambiosPermitidos: ['activo'] },
        { id: 'en-espera', nombre: 'En espera', color: 'black', cambiosPermitidos: ['activo'] },
    ];

    public estadosFacturasReclamos = [
        { id: 'pendiente', nombre: 'Pendiente' },
        { id: 'pagado', nombre: 'Pagado' },
        { id: 'denegado', nombre: 'Denegado' },
    ];

    public porcentajesFraccionamiento = [
        { cuotas: 1, porcentaje: 0 },
        { cuotas: 2, porcentaje: 2 },
        { cuotas: 3, porcentaje: 3 },
        { cuotas: 4, porcentaje: 4 },
        { cuotas: 5, porcentaje: 5 },
        { cuotas: 6, porcentaje: 6 },
        { cuotas: 7, porcentaje: 7 },
        { cuotas: 8, porcentaje: 8 },
        { cuotas: 9, porcentaje: 9 },
        { cuotas: 10, porcentaje: 10 },
        { cuotas: 11, porcentaje: 11 },
        { cuotas: 12, porcentaje: 13.77 },
    ];

    public etapasCobros = [
        { id: 'cobro', nombre: 'Por cobrar', color: 'green' },
        { id: 'cobrado', nombre: 'Cobrado', color: 'blue' },
        { id: 'liquidado', nombre: 'Liquidado', color: 'gray' },
        { id: 'anulado', nombre: 'Anulado', color: 'red' },
    ];

    public tiposCobros = [
        { id: 'requerimiento-pago', nombre: 'Requerimiento de pago' },
        { id: 'factura', nombre: 'Factura' },
        { id: 'nota-credito', nombre: 'Nota de crédito' },
        { id: 'prima-deposito', nombre: 'Prima en depósito' },
        { id: 'recibo', nombre: 'Recibo' },
    ];

    public etapasCreditos = [
        { id: 'nota-devolucion', nombre: 'Nota de devolución' },
        { id: 'prima-deposito', nombre: 'Prima en depósito' },
    ];

    public estadosCreditos = [
        { id: 'pendiente', nombre: 'Pendiente' },
        { id: 'aplicado', nombre: 'Aplicado' },
    ];

    public lugaresCobro = [
        { id: 'principal', nombre: 'Principal' },
        { id: 'trabajo', nombre: 'Trabajo' },
        { id: 'adicional', nombre: 'Adicional' },
    ];

    public camposEstadoDeCuenta = [
        { id: 'aseguradora', nombre: 'Aseguradora' },
        { id: 'ramo', nombre: 'Ramo' },
        { id: 'poliza', nombre: 'Póliza' },
        { id: 'pagador', nombre: 'Pagador' },
        { id: 'fechaRequerimiento', nombre: 'Fecha de requerimiento' },
        { id: 'diasAtraso', nombre: 'Días de atraso' },
        { id: 'noRequerimiento', nombre: 'No. de requerimiento' },
        { id: 'noPago', nombre: 'No. de pago' },
        { id: 'moneda', nombre: 'Moneda' },
        { id: 'primaAPagar', nombre: 'Prima a pagar' },
        { id: 'pagado', nombre: 'Pagado' },
        { id: 'fechaPagado', nombre: 'Fecha pagado' },
        { id: 'formaPago', nombre: 'Forma de pago' },
        { id: 'noFactura', nombre: 'No. de factura ' },
        { id: 'endoso', nombre: 'Endoso' },
    ];

    // * * * Plantillas de solicitudes * * *
    public tiposSeccionesPlantillasSolicitudes = [
        { id: 'texto', nombre: 'Texto' },
        { id: 'datos-poliza', nombre: 'Datos de póliza' },
        { id: 'datos-poliza-resumidos', nombre: 'Datos de póliza resumidos' },
        { id: 'datos-cliente-poliza', nombre: 'Datos de cliente de póliza' },
        { id: 'datos-pagadores-poliza', nombre: 'Datos de pagadores de póliza' },
        { id: 'datos-certificados', nombre: 'Datos de certificados' },
        { id: 'datos-certificados-inclusion', nombre: 'Datos de certificados sólo inclusiones' },
        { id: 'datos-certificados-exclusion', nombre: 'Datos de certificados sólo exclusiones' },
        { id: 'datos-certificados-activos', nombre: 'Datos de certificados sólo activos' },
        { id: 'clausulas-garantia', nombre: 'Cláusulas de garantía' },
        { id: 'cobros-atrasados', nombre: 'Cobros atrasados' },
        { id: 'cobros-programados', nombre: 'Cobros programados' },
        { id: 'datos-declaraciones', nombre: 'Datos de declaraciones' },
        { id: 'datos-certificado-reclamo', nombre: 'Datos de certificado de reclamo' },
        { id: 'datos-facturas-reclamo', nombre: 'Datos de facturas de reclamo' },
        { id: 'datos-facturas-reclamo-segmentadas', nombre: 'Datos de facturas de reclamo segmentadas' },
        { id: 'facturas-reclamo-liquidacion', nombre: 'Facturas de reclamo para liquidación' },
        { id: 'datos-reclamo', nombre: 'Datos de reclamo' },
        { id: 'datos-pago-reclamo', nombre: 'Datos de pago de reclamo' },
        { id: 'deducible-poliza-reclamo', nombre: 'Deducible de póliza para reclamo' },
        { id: 'observaciones-reclamo', nombre: 'Observaciones de reclamo' },
        { id: 'documentos-reclamo', nombre: 'Documentos de reclamo' },
    ];

    public tiposPlantillasSolicitudes = [
        { id: 'hoja-solicitud', nombre: 'Hoja de solicitud' },
        { id: 'carta', nombre: 'Carta' },
    ];

    public tiposUsosPlantillasSolicitudes = [
        { id: 'solicitud', nombre: 'Solicitud' },
        { id: 'memo', nombre: 'Memo' },
    ];

    // * * * Matriz de riesgo * * *
    public tiposMatrizRiesgo = [
        { id: 'no-aplica', nombre: 'No aplica' },
        { id: 'costa-rica', nombre: 'Costa Rica' },
    ];

    public tiposClientesMatrizRiesgo = [
        { id: 'fisico-nacional', nombre: 'Físico nacional', riesgo: 1 },
        { id: 'juridico-nacional', nombre: 'Jurídico nacional', riesgo: 1 },
        { id: 'pep-periodos-anteriores', nombre: 'PEP de periodos anteriores', riesgo: 1 },
        { id: 'pep-relacionado', nombre: 'PEP Relacionado', riesgo: 2 },
        { id: 'articulo-15-inscrito', nombre: 'Artículo 15 o 15 bis inscrito / con declaración', riesgo: 2 },
        { id: 'articulo-15-no-inscrito', nombre: 'Artículo 15 o 15 bis no inscrito /se desconoce', riesgo: 3 },
        { id: 'asociacion-religiosa', nombre: 'Asociación Religiosa', riesgo: 3 },
        { id: 'asociacion-sin-fines-lucro', nombre: 'Asociación sin fines de lucro', riesgo: 3 },
        { id: 'ong', nombre: 'ONG', riesgo: 3 },
        { id: 'pep-directo', nombre: 'PEP Directo', riesgo: 3 },
        { id: 'otro-tipo-asociaciones', nombre: 'Otro tipo de asociaciones', riesgo: 3 },
    ];

    paisesRiesgo1 = [
        'Costa Rica',
    ];

    paisesRiesgo3 = [
        'Cuba',
        'Corea del Norte',
        'Iraq',
        'Serbia',
        'Montenegro',
        'República Democrática del Congo',
        'Siria',
        'Líbano',
        'Burma (Myanmar)',
        'Angola',
        'Irán',
        'Sierra Leona',
        'Sudán',
        'Zimbabue',
        'Bielorrusia',
        'Balcanes',
    ];

    public actividadesGeneradorasRecursos = [
        { id: 'actividad-1', nombre: 'Actividad riesgo 1', riesgo: 1 },
        { id: 'actividad-2', nombre: 'Actividad riesgo 2', riesgo: 2 },
        { id: 'actividad-3', nombre: 'Actividad riesgo 3', riesgo: 3 },
    ];

    public tiposActivosPropiosActividadCliente = [
        { id: 'activos-costa-rica', nombre: 'Todos los activos se encuentran dentro de Costa Rica', riesgo: 1 },
        { id: 'activos-costa-rica-extranjero', nombre: 'Tiene activos tanto en Costa Rica como en el Extranjero', riesgo: 2 },
        { id: 'activos-fuera-costa-rica', nombre: 'Todos sus activos o los más importantes por lo menos, están fuera de Costa Rica', riesgo: 3 },
        { id: 'se-desconoce', nombre: 'Se desconoce', riesgo: 3 },
    ];

    public productosServiciosUtilizados = [
        { id: 'Colectivos de Vida', nombre: 'Colectivos de Vida', riesgo: 1 },
        { id: 'Gastos médicos', nombre: 'Gastos médicos', riesgo: 1 },
        { id: 'Riesgos del trabajo', nombre: 'Riesgos del trabajo', riesgo: 1 },
        { id: 'Seguro obligatorio de automóviles SOA', nombre: 'Seguro obligatorio de automóviles SOA', riesgo: 1 },
        { id: 'Viajero', nombre: 'Viajero', riesgo: 1 },
        { id: 'Responsabilidad civil', nombre: 'Responsabilidad civil', riesgo: 1 },
        { id: 'Riesgos varios', nombre: 'Riesgos varios', riesgo: 1 },
        { id: 'Rotura de maquinaria', nombre: 'Rotura de maquinaria', riesgo: 1 },
        { id: 'Todo riesgo montaje', nombre: 'Todo riesgo montaje', riesgo: 1 },
        { id: 'Caución vigencia definida', nombre: 'Caución vigencia definida', riesgo: 1 },
        { id: 'Caución vigencia indefinida', nombre: 'Caución vigencia indefinida', riesgo: 1 },
        { id: 'Caución vigencia indirecta o abierta', nombre: 'Caución vigencia indirecta o abierta', riesgo: 1 },
        { id: 'Equipo de contratistas', nombre: 'Equipo de contratistas', riesgo: 1 },
        { id: 'Equipo electrónico', nombre: 'Equipo electrónico', riesgo: 1 },
        { id: 'Fidelidad', nombre: 'Fidelidad', riesgo: 1 },
        { id: 'Carga', nombre: 'Carga', riesgo: 1 },
        { id: 'Dinero y valores', nombre: 'Dinero y valores', riesgo: 1 },
        { id: 'Incendio todo riesgo', nombre: 'Incendio todo riesgo', riesgo: 1 },
        { id: 'Incendio y líneas aliadas', nombre: 'Incendio y líneas aliadas', riesgo: 1 },
        { id: 'Mercancías transportadas', nombre: 'Mercancías transportadas', riesgo: 1 },
        { id: 'Todo riesgo construcción', nombre: 'Todo riesgo construcción', riesgo: 1 },
        { id: 'Valores', nombre: 'Valores', riesgo: 1 },
        { id: 'Gastos médicos mayores', nombre: 'Gastos médicos mayores', riesgo: 1 },
        { id: 'Vida', nombre: 'Vida', riesgo: 1 },
        { id: 'Automóviles', nombre: 'Automóviles', riesgo: 2 },
        { id: 'Vida con fondos de inversión y ahorro', nombre: 'Vida con fondos de inversión y ahorro', riesgo: 2 },
        { id: 'Aviación', nombre: 'Aviación', riesgo: 3 },
        { id: 'Casco marítimo', nombre: 'Casco marítimo', riesgo: 3 },
    ];

    public valoresMontoAseguradosMatrizRiesgo = [
        { id: 'valores-riesgo-bajo', nombre: 'Valores riesgo bajo', riesgo: 1 },
        { id: 'supera-arancel', nombre: 'Cuando se supera el arancel supere el monto se la suma asegurada', riesgo: 2 },
        { id: 'ramo-credito-defensa-juridica', nombre: 'Ramo de crédito y defensa jurídica', riesgo: 2 },
        { id: 'vida-termino-colectivos', nombre: 'Vida Termino // Colectivos', riesgo: 2 },
        { id: 'producto-vida-inversion', nombre: 'Para el producto de vida con componente de inversión aplica umbral cuando el pago de prima mensual supere dos salarios base equivalente a ¢892 400.00', riesgo: 2 },
        { id: 'producto-vida-universal', nombre: 'Producto: Vida Universal', riesgo: 3 },
    ];

    public mediosPagoMatrizRiesgo = [
        { id: 'deduccion-salarial', nombre: 'Deducción salarial por parte de convenio con empresa', riesgo: 1 },
        { id: 'deposito-cuentas', nombre: 'Deposito cuentas corrientes de Aseguradora', riesgo: 1 },
        { id: 'transferencia-sinpe', nombre: 'Transferencia vía SINPE de cuenta del mismo cliente o beneficiario', riesgo: 2 },
        { id: 'cargo-automatico-tarjeta', nombre: 'Vía cargo automático tarjeta de débito o crédito nacional del mismo cliente o beneficiario', riesgo: 2 },
        { id: 'tarjeta-credito', nombre: 'Tarjeta de crédito', riesgo: 2 },
        { id: 'cheque', nombre: 'Cheque', riesgo: 2 },
        { id: 'efectivo', nombre: 'Efectivo', riesgo: 3 },
        { id: 'transferencia-internacional', nombre: 'Transferencia Internacional', riesgo: 3 },
        { id: 'combinacion-efectivo-transferencia', nombre: 'Combinación con efectivo o transferencia internacional', riesgo: 3 },
    ];

    public tiposIngresosOrigenRecursos = [
        { id: 'asalariado', nombre: 'Asalariado sector público o privado', riesgo: 1 },
        { id: 'pension', nombre: 'Pensión', riesgo: 1 },
        { id: 'ingresos-propios-respaldo', nombre: 'Ingresos propios con respaldo', riesgo: 1 },
        { id: 'ingresos-familiares', nombre: 'Ingresos familiares', riesgo: 2 },
        { id: 'ingresos-propios-sin-justificacion', nombre: 'Ingresos propios sin justificación de actividad productiva', riesgo: 3 },
    ];

    public avisos = [
        { id: 1, nombre: 'Pólizas proximas a vencer' },
        { id: 2, nombre: 'Dependientes próximos a cumplir limite de edad como dependiente' },
        { id: 3, nombre: 'Exclusión de cobertura ITP, cuando se llegue a la edad indicada en póliza 6O AÑOS' },
        { id: 4, nombre: 'Exclusión de cobertura ITP, cuando se llegue a la edad indicada en póliza 65 AÑOS' },
        { id: 5, nombre: 'Exclusión de cobertura MYDA, cuando se llegue a la edad indicada en póliza 65 años' },
        { id: 6, nombre: 'Exclusión de cobertura MYDA, cuando se llegue a la edad indicada en póliza 70 años' },
        { id: 7, nombre: 'Reducción Maximo 50% Vitalicio 65 años' },
        { id: 8, nombre: 'Reducción Suma asegurada Vida 50% 65 años' },
        { id: 9, nombre: 'Exclusiones temporales' },
        { id: 10, nombre: 'Reclamos abiertos a más de 15 días' },
        { id: 11, nombre: 'Seguimientos de reclamos gastos médicos' },
        { id: 12, nombre: 'Reclamos en estado espera' },
        { id: 13, nombre: 'Reclamos en estado espera con más de 60 días' },
        { id: 14, nombre: 'Reclamos con aviso de maternidad' },
        { id: 15, nombre: 'Gestiones y procesos pendientes a más de 10 días' },
        { id: 16, nombre: 'Declaraciones atrasadas con más de 15 días' },
        { id: 18, nombre: 'Pólizas vencidas' },
        { id: 19, nombre: 'Cumpleañeros' },
        { id: 21, nombre: 'Pólizas con primas atrasadas a más de 45 días' },
        { id: 22, nombre: 'Requerimientos vencidos' },
        { id: 23, nombre: 'Requerimientos pendientes de pago en los próximos 15 días' },
        { id: 24, nombre: 'Reclamos abiertos daños a más de 30 días' },
        { id: 28, nombre: 'Fianzas próximas a vencer' },
        { id: 29, nombre: 'Reclamos activos y en espara en dólares con más de 5 días' },
        { id: 31, nombre: 'Gestiones y procesos pendientes de revisar' },
        { id: 32, nombre: 'Cobro semanal' },
        { id: 35, nombre: 'Vencimiento de seguro de vida a término' },
        { id: 36, nombre: 'Recordatorios de prospectos' },
    ]

    // * * * Analytics * * *
    public tiposGraficasProspectos = {
        xAxis: [
            { id: 'mes', nombre: 'Por mes' },
            { id: 'ano', nombre: 'Por año' },
            { id: 'todo', nombre: 'Todo' },
        ],
        series: [
            { id: 'etapa', nombre: 'Por etapa' },
            { id: 'agente', nombre: 'Por agente' },
            { id: 'ramo', nombre: 'Por ramo' },
            { id: 'no-desglose', nombre: 'Sin desglosar' },
        ],
        yAxis: [
            { id: 'cantidad', nombre: 'Cantidad', formato: '{point.y}' },
            { id: 'prima-neta', nombre: 'Prima neta', formato: 'moneda' },
        ],
    }

    public tiposGraficasComisiones = {
        xAxis: [
            { id: 'mes', nombre: 'Por mes' },
            { id: 'ano', nombre: 'Por año' },
            { id: 'todo', nombre: 'Todo' },
        ],
        series: [
            { id: 'aseguradora', nombre: 'Por aseguradora' },
            { id: 'agente', nombre: 'Por agente' },
            { id: 'ramo', nombre: 'Por ramo' },
            { id: 'no-desglose', nombre: 'Sin desglosar' },
        ],
        yAxis: [
            { id: 'comision-agente', nombre: 'Comisión de agente' },
            { id: 'comision-empresa', nombre: 'Comisión de empresa' },
            { id: 'comision-productor-externo', nombre: 'Comisión de productor externo' },
            { id: 'prima-neta', nombre: 'Prima neta' },
        ],
    }

    public modulos = {
        CLIENTES: { id: 'clientes', nombre: 'Clientes', tieneHomepage: true, tipo: 'frontend' },
        POLIZAS: { id: 'polizas', nombre: 'Pólizas', tieneHomepage: true, tipo: 'frontend' },
        PROSPECTOS: { id: 'prospectos', nombre: 'Prospectos', tieneHomepage: true, tipo: 'frontend' },
        COMISIONES: { id: 'comisiones', nombre: 'Comisiones', tieneHomepage: true, tipo: 'frontend' },
        RECLAMOS: { id: 'reclamos', nombre: 'Reclamos', tieneHomepage: true, tipo: 'frontend' },
        EMERGENCIAS: { id: 'emergencias', nombre: 'Emergencias', tieneHomepage: true, tipo: 'frontend' },
        COBROS: { id: 'cobros', nombre: 'Cobros', tieneHomepage: true, tipo: 'frontend' },
        CONTROL_CALIDAD: { id: 'controlCalidad', nombre: 'Control de calidad', tieneHomepage: true, tipo: 'frontend' },
        MENSAJERIA: { id: 'mensajeria', nombre: 'Mensajería', tieneHomepage: true, tipo: 'frontend' },
        MAPAS: { id: 'mapas', nombre: 'Mapas', tieneHomepage: true, tipo: 'frontend' },
        PLANTILLAS_RAMOS: { id: 'plantillasRamos', nombre: 'Plantillas Ramos', tieneHomepage: true, tipo: 'frontend' },
        CLIENTES_ANALYTICS: { id: 'clientesAnalytics', nombre: 'Clientes', tieneHomepage: true, tipo: 'analytics' },
        POLIZAS_ANALYTICS: { id: 'polizasAnalytics', nombre: 'Pólizas', tieneHomepage: true, tipo: 'analytics' },
        PROSPECTOS_ANALYTICS: { id: 'prospectosAnalytics', nombre: 'Prospectos', tieneHomepage: true, tipo: 'analytics' },
        COMISIONES_ANALYTICS: { id: 'comisionesAnalytics', nombre: 'Comisiones', tieneHomepage: true, tipo: 'analytics' },
        RECLAMOS_ANALYTICS: { id: 'reclamosAnalytics', nombre: 'Reclamos', tieneHomepage: true, tipo: 'analytics' },
        EMERGENCIAS_ANALYTICS: { id: 'emergenciasAnalytics', nombre: 'Emergencias', tieneHomepage: true, tipo: 'analytics' },
        COBROS_ANALYTICS: { id: 'cobrosAnalytics', nombre: 'Cobros', tieneHomepage: true, tipo: 'analytics' },
        CONTROL_CALIDAD_ANALYTICS: { id: 'controlCalidadAnalytics', nombre: 'Control de calidad', tieneHomepage: true, tipo: 'analytics' },
        MENSAJERIA_ANALYTICS: { id: 'mensajeriaAnalytics', nombre: 'Mensajería', tieneHomepage: true, tipo: 'analytics' },
        MAPAS_ANALYTICS: { id: 'mapasAnalytics', nombre: 'Mapas', tieneHomepage: true, tipo: 'analytics' },
        ANALYTICS: { id: 'analytics', nombre: 'Analytics', tieneHomepage: true, tipo: 'ninguno' },
        EMPRESAS: { id: 'empresas', nombre: 'Empresas', tieneHomepage: false, tipo: 'backend' },
        DATOS_GENERALES: { id: 'datosGenerales', nombre: 'Datos generales', tieneHomepage: false, tipo: 'backend' },
        PAISES_MONEDAS: { id: 'paisesMonedas', nombre: 'Países y monedas', tieneHomepage: false, tipo: 'backend' },
        AREAS_ACCESOS: { id: 'areasAccesos', nombre: 'Áreas y accesos', tieneHomepage: false, tipo: 'backend' },
        USUARIOS: { id: 'usuarios', nombre: 'Usuarios', tieneHomepage: false, tipo: 'backend' },
        ASEGURADORAS: { id: 'aseguradoras', nombre: 'Aseguradoras', tieneHomepage: false, tipo: 'backend' },
        RAMOS: { id: 'ramos', nombre: 'Ramos', tieneHomepage: false, tipo: 'backend' },
        FORMAS_COBRO: { id: 'formasCobro', nombre: 'Formas de cobro', tieneHomepage: false, tipo: 'backend' },
        ESTATUS_COBRO: { id: 'estatusCobro', nombre: 'Estatus de cobro', tieneHomepage: false, tipo: 'backend' },
        TIPOS_GESTION: { id: 'tiposGestion', nombre: 'Tipos de gestión', tieneHomepage: false, tipo: 'backend' },
        PLANTILLAS_MAPAS: { id: 'plantillasMapas', nombre: 'Plantillas de mapas', tieneHomepage: false, tipo: 'backend' },
        MATRIZ_RIESGO: { id: 'matrizRiesgo', nombre: 'Matriz de riesgo', tieneHomepage: false, tipo: 'backend' },
        USUARIOS_EXTERNOS: { id: 'usuariosExternos', nombre: 'Usuarios externos', tieneHomepage: false, tipo: 'backend' },
        BITACORA: { id: 'bitacora', nombre: 'Bitácora', tieneHomepage: false, tipo: 'backend' },
        BANCOS: { id: 'bancos', nombre: 'Bancos', tieneHomepage: false, tipo: 'backend' },
    }
    public nivelesAutorizacion = {
        LECTURA: 'lectura',
        ESCRITURA: 'escritura',
        NINGUNO: 'ninguno',
        LECTURA_PROPIA: 'lectura-propia',
        ESCRITURA_PROPIA: 'escritura-propia',
        PROPIO: 'propio',
        ADMINISTRADOR: 'administrador',
    }
    public paises = [
        'Guatemala',
        'El Salvador',
        'Honduras',
        'Nicaragua',
        'Costa Rica',
        'Panamá',
        'Belice',
        'Afganistán',
        'Albania',
        'Alemania',
        'Andorra',
        'Angola',
        'Antigua y Barbuda',
        'Arabia Saudita',
        'Argelia',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaiyán',
        'Bahamas',
        'Balcanes',
        'Bangladés',
        'Barbados',
        'Baréin',
        'Bélgica',
        'Belice',
        'Benín',
        'Bielorrusia',
        'Birmania',
        'Bolivia',
        'Bosnia y Herzegovina',
        'Botsuana',
        'Brasil',
        'Brunéi',
        'Bulgaria',
        'Burkina Faso',
        'Burma (Myanmar)',
        'Burundi',
        'Bután',
        'Cabo Verde',
        'Camboya',
        'Camerún',
        'Canadá',
        'Catar',
        'Chad',
        'Chile',
        'China',
        'Chipre',
        'Ciudad del Vaticano',
        'Colombia',
        'Comoras',
        'Corea del Norte',
        'Corea del Sur',
        'Costa de Marfil',
        'Costa Rica',
        'Croacia',
        'Cuba',
        'Dinamarca',
        'Dominica',
        'Ecuador',
        'Egipto',
        'El Salvador',
        'Emiratos Árabes Unidos',
        'Eritrea',
        'Eslovaquia',
        'Eslovenia',
        'España',
        'Estados Unidos',
        'Estonia',
        'Etiopía',
        'Filipinas',
        'Finlandia',
        'Fiyi',
        'Francia',
        'Gabón',
        'Gambia',
        'Georgia',
        'Ghana',
        'Granada',
        'Grecia',
        'Guatemala',
        'Guyana',
        'Guinea',
        'Guinea ecuatorial',
        'Guinea-Bisáu',
        'Haití',
        'Honduras',
        'Hungría',
        'India',
        'Indonesia',
        'Irak',
        'Irán',
        'Irlanda',
        'Islandia',
        'Islas Marshall',
        'Islas Salomón',
        'Israel',
        'Italia',
        'Jamaica',
        'Japón',
        'Jordania',
        'Kazajistán',
        'Kenia',
        'Kirguistán',
        'Kiribati',
        'Kuwait',
        'Laos',
        'Lesoto',
        'Letonia',
        'Líbano',
        'Liberia',
        'Libia',
        'Liechtenstein',
        'Lituania',
        'Luxemburgo',
        'Macedonia del Norte',
        'Madagascar',
        'Malasia',
        'Malaui',
        'Maldivas',
        'Malí',
        'Malta',
        'Marruecos',
        'Mauricio',
        'Mauritania',
        'México',
        'Micronesia',
        'Moldavia',
        'Mónaco',
        'Mongolia',
        'Montenegro',
        'Mozambique',
        'Namibia',
        'Nauru',
        'Nepal',
        'Nicaragua',
        'Níger',
        'Nigeria',
        'Noruega',
        'Nueva Zelanda',
        'Omán',
        'Países Bajos',
        'Pakistán',
        'Palaos',
        'Panamá',
        'Papúa Nueva Guinea',
        'Paraguay',
        'Perú',
        'Polonia',
        'Portugal',
        'Reino Unido',
        'República Centroafricana',
        'República Checa',
        'República del Congo',
        'República Democrática del Congo',
        'República Dominicana',
        'República Sudafricana',
        'Ruanda',
        'Rumanía',
        'Rusia',
        'Samoa',
        'San Cristóbal y Nieves',
        'San Marino',
        'San Vicente y las Granadinas',
        'Santa Lucía',
        'Santo Tomé y Príncipe',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leona',
        'Singapur',
        'Siria',
        'Somalia',
        'Sri Lanka',
        'Suazilandia',
        'Sudán',
        'Sudán del Sur',
        'Suecia',
        'Suiza',
        'Surinam',
        'Tailandia',
        'Tanzania',
        'Tayikistán',
        'Timor Oriental',
        'Togo',
        'Tonga',
        'Trinidad y Tobago',
        'Túnez',
        'Turkmenistán',
        'Turquía',
        'Tuvalu',
        'Ucrania',
        'Uganda',
        'Uruguay',
        'Uzbekistán',
        'Vanuatu',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Yibuti',
        'Zambia',
        'Zimbabue',
    ];

    rangoFechasCobros = [
        { id: '0-30', nombre: '0 a 30 días' },
        { id: '31-45', nombre: '31 a 45 días' },
        { id: '46-60', nombre: '46 a 60 días' },
        { id: '61-75', nombre: '61 a 75 días' },
        { id: '76-90', nombre: '76 a 90 días' },
        { id: '91', nombre: 'más de 91' },
    ];

    estadosClausulasGarantiasPolizas = [
        { id: 'activo', nombre: 'Activo' },
        { id: 'excluido', nombre: 'Excluido' },
    ];

    equivalenciasTextos = {
        'Zona': {
            'GT': 'Zona',
            'SV': 'Distrito',
            'CR': 'Zona',
            'HN': 'Zona',
        }
    }
}
